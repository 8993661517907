import omit from "lodash/omit";
import { trimSpecialChars } from "library/utils/formatter";

export default function service(serviceName, params, authzParams) {
  const service = {
    login: {
      method: "post",
      url: "e/p/ep-authn-service/mercuryos/login",
      body: params,
    },
    logout: {
      method: "get",
      url: "e/p/ep-authn-service/mercuryos/logout",
    },
    oneLogin: {
      method: "get",
      url: "e/p/ep-authn-service/mhq/saml/login",
    },
    "extend-login": {
      method: "post",
      url: "server/refreshToken",
      body: params,
    },
    "get-mac-address": {
      method: "get",
      url: `server/getAddress`,
    },
    "get-build-info": {
      method: "get",
      url: `server/appBuildInfo?platform=${params.platform}`,
    },
    feedback: {
      method: "post",
      url: "server/feedback",
      body: params,
    },
    log: {
      method: "post",
      url: "server/log",
      body: params,
    },
    "generate-qr-code": {
      method: "post",
      url: "server/generateQRCode",
      body: params,
    },
    "get-user-profile": {
      method: "get",
      url: `e/p/ep-user-profile/api/mercuryos/-/my/profile`,
    },
    "edit-user-profile": {
      method: "put",
      url: `e/p/ep-user-profile/api/mercuryos/-/my/profile`,
      body: params,
    },
    "initiate-reset-password": {
      method: "post",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/admin/initiatereset?emailId=${params.email}&type=${params.type}`,
    },
    "change-password": {
      method: "post",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/password/change`,
      body: params,
    },
    "update-password": {
      method: "post",
      url: `e/p/ep-user-profile/user/mercuryos/password/type/reset`,
      body: params,
    },
    "set-password": {
      method: "post",
      url: `e/p/ep-user-profile/user/mercuryos/password/set`,
      body: params,
    },
    "reset-password": {
      method: "post",
      url: `e/p/ep-user-profile/user/mercuryos/password/reset`,
      body: params,
    },
    "forgot-password": {
      method: "post",
      url: `e/p/ep-user-profile/user/mercuryos/password/forgot`,
      body: params,
    },
    "unlock-password": {
      method: "post",
      url: `e/p/ep-user-profile/user/mercuryos/password/unlock`,
      body: params,
    },

    "get-staff-list": {
      method: "get",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/admin/users`,
    },
    "get-staff-list-by-access": {
      method: "get",
      // Here possible values for status are ACTIVE (Default), IN-ACTIVE or ALL
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/admin/usersByAccess?status=${params.status}&shopGroups=${params.shopGroupName}&memberCodes=${params.selectedMemberCodes}`,
    },
    "search-users-by-email": {
      method: "get",
      url: `e/p/ep-user-profile/api/mercuryos/-/admin/v2/user/search/${params.emailId}`,
    },
    "create-staff-profile": {
      method: "post",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/admin/v2/user/${params.emailId}`,
      body: omit(params, ["emailId"]),
    },
    "get-staff-profile": {
      method: "get",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/admin/v2/user/${params.emailId}`,
    },
    "edit-staff-profile": {
      method: "put",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/admin/v2/user/${params.emailId}`,
      body: omit(params, ["emailId"]),
    },
    "delete-staff-profile": {
      method: "delete",
      url: `e/p/ep-user-profile/api/mercuryos/${params.memberCode}/admin/v2/user/${params.emailId}`,
    },
    "get-audible-notification": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/notifications${
        params.startTime ? `?startTime=${params.startTime}` : ``
      }`,
    },
    "subscribe-customer-attentive": {
      method: "post",
      url: `create-subscriber`,
      body: params,
    },
    "order-listing": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders?startDate=${
        params.startDate
      }&status=${params.fetchStatuses}&endDate=${
        params.endDate || ""
      }&deltaOrders=${params.deltaOrders || false}&lastSyncTime=${
        params.lastSyncTime || ""
      }&listingFilter=${params.dateType || "DELIVERY_DATE"}`,
    },
    "get-auto-print-orders-last-sync-time": {
      method: "get",
      url: `e/p/mercury/${
        authzParams.mosMemberCode
      }/florist/auto-print/preferences?memberCodes=${params.memberCodes || ""}`,
    },
    "get-auto-print-orders": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/auto/print`,
      body: {
        ...params,
      },
    },
    "get-auto-print-inbound-orders": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/auto/print`,
      body: {
        ...params,
        direction: "INBOUND",
      },
    },
    "get-auto-print-outbound-orders": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/auto/print`,
      body: {
        ...params,
        direction: "OUTBOUND",
      },
    },
    "update-auto-print-orders": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/auto-print${
        params?.remotePrintRequest
          ? `?remotePrintRequest=${params?.remotePrintRequest}`
          : ""
      }`,
      body: params.payload,
    },
    "order-actions": {
      method: "post",
      url: `e/p/mercury/${params.sourceMemberCode}/orders/${params.recordId}${
        params.isMessage || params.askId ? `/messages/${params.askId}` : ``
      }/action/${params.action}?deliveryMethod=${params.deliveryMethod}${
        params?.remotePrintRequest
          ? `&remotePrintRequest=${params?.remotePrintRequest}`
          : ""
      }`,
      body: omit(params, ["remotePrintRequest"]),
    },
    "order-requests": {
      method: "post",
      url: `e/p/mercury/${params.sourceMemberCode}/orders/${params.recordId}/request/${params.requestType}?deliveryMethod=${params.deliveryMethod}`,
      body: params,
    },
    "order-details": {
      method: "get",
      url: `e/p/mercury/${params.sourceMemberCode}/orders/${params.recordId}?deliveryMethod=${params.deliveryMethod}`,
    },
    "verify-address": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/address/verify`,
      body: params,
    },
    "verify-zip": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/address/zipcode/${params.zip}`,
    },
    "get-address-suggestions": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/address/suggestions`,
      body: params,
    },
    "get-florists": {
      method: "post",
      url: "e/p/florist-selection/ftd/api/fss/getAllAvailableFlorists",
      body: params,
    },
    "get-user-permission-groups": {
      method: "get",
      url: `e/p/ep-user-profile/api/mercuryos/${
        authzParams.memberCode
      }/permissionsList?cache-control=${params.cacheControl || "use"}`,
    },
    "reload-member-entitlements": {
      method: "delete",
      url: `e/p/ep-user-profile/api/mercuryos/${params.shopCode}/private/flushRedis?memberCodes=${params.shopCode}`,
    },
    "get-menus": {
      method: "get",
      //url: `e/p/admin-navigation/admin-menu-hierarchy/v2`,
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/private/admin-menu-hierarchy`,
    },
    "get-florist": {
      method: "post",
      url: `e/p/florist-selection/ftd/api/fss/selectFillingFlorist`,
      body: params,
    },

    "get-products": {
      method: "get",
      url: `e/p/mercury/${
        authzParams.mosMemberCode
      }/products/search/autofill?query=${trimSpecialChars(
        params.query
      )}&includeAll=${params.includeAll ?? true}&siteId=${
        params.siteId ?? ""
      }&includeOverrides=${params.includeOverrides ?? false}`,
    },
    "get-local-addons": {
      method: "get",
      url: `e/p/mercury/${
        params.memberCode || authzParams.mosMemberCode
      }/products/addons?query=${trimSpecialChars(params.query)}&siteId=${
        params.siteId ?? ""
      }&includeOverrides=${params.includeOverrides ?? false}`,
    },
    "get-customers": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/customer/search?offset=${params.offset}&limit=${params.limit}&search=${
        params.searchText
      }&includeTotalCount=${params.includeTotalCount}`,
    },
    "get-customer-activities": {
      method: "get",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/customer/${
        params.customerId
      }/activities?offset=${params.offset}&limit=10&activityType=${
        params.feedType
      }&startDate=${params.startDate}&endDate=${params.endDate}&timezone=${
        params.userTimeZone
      }`,
    },
    "get-open-item-unpaid-orders": {
      method: "get",
      url: `e/p/mercury/${
        params.storeOrigin || authzParams.memberCode
      }/house-account/open-item/unpaid-orders/${
        params.houseAccountId
      }?shopCode=${params.shopCode}`,
    },
    "get-customer-orders": {
      method: "get",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/customer/${
        params.customerId
      }/orders?storeOrigin=${params.storeOrigin}`,
    },
    "get-customer-details": {
      method: "get",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/customer/${
        params.customerId
      }?storeOrigin=${params.storeOrigin}&includePreferences=true`,
    },
    "upsert-customer": {
      method: params.customerId ? "put" : "post",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/customer${
        params.customerId ? `/${params.customerId}` : ""
      }`,
      body: omit(params, ["customerId"]),
    },
    "merge-customer": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/customer/merge?storeOrigin=${params[0]?.storeOrigin}`,
      body: params,
    },
    "save-customer-credit-card": {
      method: "post",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/customer/${
        params.customerId
      }/credit-cards`,
      body: omit(params, ["customerId"]),
    },
    "delete-customer-credit-card": {
      method: "delete",
      url: `e/p/mercury/${authzParams.mosMemberCode}/customer/${params.customerId}/credit-cards/${params.creditCardId}?storeOrigin=${params.storeOrigin}`,
    },
    "create-house-account": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/house-account?storeOrigin=${params.storeOrigin}`,
      body: omit(params, ["storeOrigin"]),
    },
    "update-house-account": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/house-account`,
      body: omit(params, ["storeOrigin"]),
    },
    "save-house-account-credit-debit-memo": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/house-account/credit-debit-memo/payment?storeOrigin=${
        params.storeOrigin
      }`,
      body: params,
    },
    "save-customer-receive-payment": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/house-account/payment?storeOrigin=${params.storeOrigin}`,
      body: params,
    },
    "get-customer-receive-payments": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/house-account/payment/${params.houseAccountId}?storeOrigin=${
        params.storeOrigin
      }`,
    },
    "get-quickPicks": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/products/quick-picks`,
    },
    "get-addons": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/products/addons`,
    },
    "create-order": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders?multiOrder=true`,
      body: params.createOrderReqObj,
    },
    "order-action-widget": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/ordersActionWidget`,
    },
    "order-today-widget": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/ordersTodayWidget`,
    },
    "calendar-widget": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/calendarWidget`,
      body: params,
    },
    "suspend-member": {
      method: "post",
      url: `e/p/mercury/${params.sourceMemberCode}/florist/settings/suspend`,
      body: params.suspendMemberObj,
    },
    "resume-member": {
      method: "post",
      url: `e/p/mercury/${params.memberCode}/florist/settings/resume`,
      body: params,
    },
    "get-member-settings": {
      method: "get",
      url: `e/p/mercury/${
        authzParams.mosMemberCode
      }/florist/settings/suspend?startDate=${params.startDate}${
        params.suspendType ? `&suspendType=${params.suspendType}` : ``
      }`,
    },
    "get-shop-settings": {
      method: "get",
      url: `e/p/mercury/${
        authzParams.mosMemberCode
      }/florist/settings?ids=${params.ids?.join()}`,
    },
    "save-shop-settings": {
      method: "post",
      url: `e/p/mercury/${
        authzParams.mosMemberCode
      }/florist/settings?ids=${params?.preferences
        ?.map((obj) => obj.id)
        ?.join()}`,
      body: params,
    },
    /* Addons */
    "get-florist-subscriptions": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/florist/subscriptions`,
    },
    /* Addons - settings details */
    "get-florist-subscriptions-details": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/florist/settings/subscriptions`,
    },
    "save-florist-subscriptions-details": {
      method: "put",
      url: `e/p/mercury/${params.shopCode}/florist/settings/subscriptions`,
      body: params.body,
    },
    "mark-messages-printed": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/mark-as-printed`,
      body: params,
    },
    "save-card-settings": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/settings${
        params.catalogSettings
          ? `?catalogSettings=${params.catalogSettings}`
          : ""
      }`,
      body: omit(params, ["catalogSettings"]),
    },
    "save-language-settings": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/settings?ids=languages`,
      body: params,
    },
    "get-notifications": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/notifications?startDate=${params.startDate}&endDate=${params.endDate}`,
    },
    "notification-action": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/notifications`,
      body: params,
    },
    "delete-notification": {
      method: "delete",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/notifications/${params.messageIdentifier}?messageType=${params.messageType}`,
      body: params,
    },
    "get-default-notifications": {
      method: "get",
      url: `e/p/member-notification/${authzParams.mosMemberCode}/defaultNotifications`,
    },
    "activate-occasion-reminder": {
      method: "post",
      url: `e/p/member-notification/${authzParams.mosMemberCode}/occasion-reminder`,
    },
    "get-dashboard-widgets": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/dashboard?calStartDate=${params.startDate}&calEndDate=${params.endDate}&ordersStartDate=${params.ordersStartDate}&ordersEndDate=${params.ordersEndDate}&timeZone=${params.userTimeZone}`,
    },
    "get-shop-details": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/details`,
    },
    "get-shop-suspend-details": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/suspend/details?startDate=${params.startDate}`,
    },
    "get-shop-basic-info": {
      method: "get",
      url: `e/p/mercury/${
        authzParams.mosMemberCode
      }/florist/basic-info?memberCodes=${(
        params.requestedMemberCodes || []
      ).join()}`,
    },
    "get-florist-account": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/account/${params.memberCode}`,
    },
    "order-messages": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/${params.orderItemId}/messages`,
    },
    "download-image": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/settings/card-image?imageName=${params.imageName}`,
    },
    "upload-image": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/settings/card-image?imageName=${params.imageName}`,
      body: params,
    },
    "get-florists-new": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florists/availability?productCode=${params.productCode}&postalCode=${params.zipCode}&startDeliveryDate=${params.startDeliveryDate}&endDeliveryDate=${params.endDeliveryDate}&addressLine1=${params.addressLine1}&cityLocality=${params.city}&stateProvince=${params.state}&countryRegion=${params.country}&addressLine2=${params.suite}&sendingMemberCode=${params.sendingMemberCode}&actualProductPrice=${params.actualPrice}&updatedProductPrice=${params.updatedPrice}`,
    },
    "get-preferences-count": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/preferences/count`,
    },
    "save-florist-preferences": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/preferences`, //setting correct membercode in params
      body: params,
    },
    "get-preferences-florists": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/preference/${params.preference}`,
    },
    "get-preferences-search": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/florist/preferences?${
        params.userQuery ? `genericQueryText=${params.userQuery}` : ""
      }`,
    },
    "get-card-messages": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/card/messages`,
    },
    "order-message-actions": {
      method: "post",
      url: `e/p/mercury/${params.sourceMemberCode}/orders/${
        params.recordId
      }/messages/action/read?status=${params.markAsRead}&includeStructured=${
        params.isStructured
      }&includeUnstructured=${params.isUnstructured}&deliveryMethod=${
        params.deliveryMethod
      }&includeCheckResponse=${params.isCheckResponse ?? false}`,
      body: params,
    },
    "get-city-suggestions": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/address/suggestions/cities/${params.city}`,
    },
    "get-ds-eligibilty": {
      method: "get",
      url: `e/p/mercury/${params.sourceMemberCode}/florist/deliveryServiceOpted`,
    },
    "set-ds-eligibilty": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/settings?ids=delivery_service`,
      body: params.payload,
    },
    "get-florist-search": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/search${
        params.businessName
          ? `?businessName=${params.businessName}`
          : `?memberCode=${params.shopCode}`
      }`,
    },
    "get-shop-group": {
      method: "get",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/common/shopGroup?partnerGroup=${params.partnerGroupName}&name=${params.shopGroupName}&memberCodes=${params.memberCodes}`,
    },
    "create-shop-group": {
      method: "post",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/admin/shopGroup`,
      body: params,
    },
    "edit-shop-group": {
      method: "put",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/admin/shopGroup/${params.id}`,
      body: omit(params, ["id"]),
    },
    "delete-shop-group": {
      method: "delete",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/admin/shopGroup/${params.shopGroupId}?isOrphanShopGroup=${params.isOrphanShopGroup}`,
    },
    "update-last-sync-time": {
      method: "put",
      url: `e/p/mercury/mercury/florist/settings/sync-date?memberCode=${params.memberCode}&isMHQFlorist=${params.isMHQFlorist}`,
    },
    "get-product-details": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/products/product/${params.productId}?siteId=${params.siteId}&orderSource=${params.orderSource}`,
    },
    "get-addon-details": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/products/product/${params.addonId}?type=addon&siteId=${params.siteId}&orderSource=${params.orderSource}`,
    },
    "non-loggedin-reset-password": {
      method: "post",
      url: `e/p/ep-user-profile/user/mercuryos/password/initiatereset?emailId=${params.email}&type=${params.type}`,
    },
    "order-read-action": {
      method: "post",
      url: `e/p/mercury/${
        params.memberCode || authzParams.mosMemberCode
      }/orders/${params.recordId}/action/read?status=${
        params.markAsRead
      }&deliveryMethod=${params.deliveryMethod}`,
    },
    "get-lat-long": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/address/geocode?addressLine1=${params.addressLine1}&cityLocality=${params.city}&stateProvince=${params.state}&countryRegion=${params.country}&addressLine2=${params.suite}&postalCode=${params.zipCode}`,
    },
    "get-bulk-print-orders": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders?startDate=${params.startDate}&status=BULK_PRINT`,
    },
    "bulk-print": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/action/bulk/print`,
      body: params,
    },
    "get-feedback-categories": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/feedback/categories`,
    },
    "feedback-submit": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/florist/feedback`,
      body: params,
    },
    "get-feedback-categories-noauth": {
      method: "get",
      url: `e/p/mercury/p/florist/feedback/categories`,
    },
    "get-order-status-tracking-details": {
      method: "get",
      url: `e/p/mercury/p/order/track-order?id=${params.trackingId}`,
    },
    "batch-update": {
      method: "post",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/batch/user/update`,
      body: params,
    },
    "validate-nonce": {
      method: "get",
      url: `e/p/ep-user-profile/user/mercuryos/password/validateNonce/${params.nonce}`,
    },
    "create-bulk-accounts-mhq": {
      method: "post",
      url: `e/p/mercury/FTD/admin/bulk-accounts/mhq`,
      body: params,
    },
    "create-bulk-accounts-mcloud": {
      method: "post",
      url: `e/p/mercury/FTD/admin/bulk-accounts/mcloud`,
      body: params,
    },
    "upload-migration-data": {
      method: "post",
      url: `e/p/mercury-migration/FTD/florist/migration`,
      body: params,
    },
    "down-cloud": {
      method: "post",
      url: `e/p/mercury/FTD/florist/settings/down-cloud`,
      body: params,
    },
    "get-data-migration-jobs": {
      method: "get",
      url: `e/p/mercury-migration/${authzParams.memberCode}/jobs`,
    },
    "get-data-migration-job-details": {
      method: "get",
      url: `e/p/mercury-migration/${authzParams.memberCode}/jobs/${params.jobId}`,
    },
    "get-exception-report": {
      method: "get",
      url: `e/p/mercury-migration/${authzParams.memberCode}/florist/migration/exception-report?jobId=${params.jobId}`,
    },
    "get-routes": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/delivery/routes?deliveryDates=${params.deliveryDates}`,
    },
    "create-route": {
      method: "post",
      url: `e/p/mercury/${params.shopCode}/delivery/routes`,
      body: params?.body,
    },
    "update-route": {
      method: "put",
      url: `e/p/mercury/${params.shopCode}/delivery/routes/${params.body?.routeId}`,
      body: params?.body,
    },
    "update-route-status": {
      method: "put",
      url: `e/p/mercury/${params.shopCode}/delivery/status?routeId=${params.routeId}&status=${params.newStatus}`,
    },
    "get-route-data": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.mosMemberCode
      }/delivery/routes/${params.routeId}`,
    },
    "get-drivers": {
      method: "get",
      url: `e/p/mercury/${authzParams.memberCode}/delivery/drivers?memberCodes=${params.members}&commonUsers=${params.commonUsers}`,
    },
    "get-route-name": {
      method: "get",
      url: `e/p/mercury/${params.members}/delivery/routes/name`,
    },
    "get-route-manifest": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/delivery/routes/route-manifest/${params.routeId}`,
    },
    "update-route-manifest-print-status": {
      method: "put",
      url: `e/p/mercury/${params.shopCode}/delivery/routes/${params.routeId}/manifest/print`,
    },
    "route-optimize": {
      method: "put",
      url: `e/p/mercury/${params.shopCode}/delivery/routes/${params.routeId}/optimize`,
      ...(params.CFROptimise && { body: params.body }),
    },
    "update-route-stop-status": {
      method: "PATCH",
      url: `e/p/mercury/${params.shopCode}/delivery/routes/${params.routeId}/stops/${params.stopId}`,
      body: params.body,
    },
    "delivery-listing": {
      method: "get",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders?startDate=${
        params.startDate
      }&endDate=${params.endDate}&status=${
        params.fetchStatuses
      }&listingFilter=${params.dateType || "DELIVERY_DATE"}`,
    },
    "export-shops": {
      method: "GET",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/admin/shopGroup/exportShops?listAll=${params.listAll}&memberCodes=${params.memberCodes}&matchMembers=ALL&listActive=false&matchPartial=false`,
    },
    "invalidate-sessions": {
      method: "get",
      url: `e/p/ep-authn-service/user/clear/mercuryos?clearAll=${params.clearAll}&deviceType=${params.deviceType}&memberCodes=${params.memberCodes}&email=${params.email}&mobileTokenId=${params.mobileTokenId}`,
    },
    "order-summary-report": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/order-summary?shopName=${params.shopName}&startDate=${params.startDate}&endDate=${params.endDate}`,
    },
    "occasion-reminder": {
      method: "GET",
      url: `e/p/mercury-reporting/${
        authzParams.memberCode
      }/report/occasion-reminder?startDate=${
        params.startDate
      }&occasions=${params.occasions?.join()}&endDate=${params.endDate}`,
    },
    "customer-report": {
      method: "GET",
      url: `e/p/mercury-reporting/${
        authzParams.memberCode
      }/report/customer-summary?search=${params.search || ""}&timeZone=${
        params.userTimeZone
      }`,
    },
    "order-detail-report": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/order-details?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.rangeType}&timeZone=${params.userTimeZone}`,
    },
    "product-sales-report": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/product-sales?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.rangeType}&timeZone=${params.userTimeZone}`,
    },
    "product-comparison-report": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/product-comparison?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.rangeType}&comparisonStartDate=${params.comparisonStartDate}&comparisonEndDate=${params.comparisonEndDate}&timeZone=${params.userTimeZone}`,
    },
    "aged-analysis-detail": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/aged-analysis-detail?formatType=${params.reportFormat}&timeZone=${params.userTimeZone}`,
    },
    "aged-analysis-summary": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/aged-analysis-summary?formatType=${params.reportFormat}&timeZone=${params.userTimeZone}`,
    },
    "sales-summary-report": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/sales-summary?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.rangeType}&timeZone=${params.userTimeZone}`,
    },
    "advance-optimization-usage-report": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/advance-route-optimization-usage?startDate=${params.startDate}&endDate=${params.endDate}&timeZone=${params.userTimeZone}`,
    },
    "business-snapshot-report": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/business-snapshot?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.rangeType}&timeZone=${params.userTimeZone}`,
    },
    "sales-analysis-business-snapshot": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/sales-analysis-business-snapshot?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.rangeType}&timeZone=${params.userTimeZone}&formatType=${params.reportFormat}`,
    },
    "delivery-analysis-business-snapshot": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/delivery-analysis-business-snapshot?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.rangeType}&timeZone=${params.userTimeZone}&formatType=${params.reportFormat}`,
    },
    "product-analysis-business-snapshot": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/product-analysis-business-snapshot?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.rangeType}&timeZone=${params.userTimeZone}&formatType=${params.reportFormat}`,
    },
    "wire-analysis-business-snapshot": {
      method: "GET",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/wire-analysis-business-snapshot?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.rangeType}&timeZone=${params.userTimeZone}&formatType=${params.reportFormat}`,
    },
    "authorize-payment": {
      method: "post",
      url: "api/payment-gateway/mhq/api/payment/multipay/authorize",
      body: params.requestPayload,
    },
    "tokenize-credit-card": {
      method: "post",
      url: "api/payment-gateway/mhq/api/payment/order/paymentmethod",
      body: params.requestPayload,
    },
    "void-credit-card-payment": {
      method: "delete",
      url: "api/payment-gateway/mhq/api/payment/reverseAuthorization",
      body: params.requestPayload,
    },
    "get-refund-preview": {
      method: "post",
      url: `e/p/mercury/${params.memberCode || authzParams.memberCode}/orders/${
        params.orderId
      }/refund/preview?deliveryMethod=${params.deliveryMethod}`,
      body: params,
    },
    "initiate-refund": {
      method: "post",
      url: `e/p/mercury/${authzParams.memberCode}/orders/${params.orderId}/refund?deliveryMethod=${params.deliveryMethod}`,
      body: params,
    },
    "save-suspension": {
      method: params.suspendId ? "put" : "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/suspend`,
      body: omit(params, ["shopCode"]),
    },
    "get-suspensions": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/suspend?startDate=${params.startDate}`,
    },
    "delete-suspension": {
      method: "delete",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/suspend?suspendId=${params.exceptionId}`,
    },
    "get-shop-hours": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/shop-hours`,
    },
    "update-shop-hours": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/shop-hours`,
      body: omit(params, ["shopCode"]),
    },
    "get-delivery-cutoff-times": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/cutoff-time`,
    },
    "update-delivery-cutoff-times": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/cutoff-time`,
      body: omit(params, ["shopCode"]),
    },
    "get-all-collections": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/collection/search?collectionsSize=${
        params.collectionsSize
      }&offset=${params.offset}&source=${
        params.shopCode || authzParams.memberCode
      },mol`,
    },
    "get-all-collections-admin": {
      method: "get",
      url: `e/p/mercury/mol/products/mol/collection/search?size=${params.collectionsSize}&offset=${params.offset}&source=mol&basic=true`,
    },
    "create-collection": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/collection`,
      body: params.requestPayload,
    },
    "get-collection": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/collection/search?query=${params.handle}&source=${
        params.shopCode || authzParams.memberCode
      },mol`,
    },
    "update-collection": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/collection`,
      body: params.requestPayload,
    },
    "delete-collection": {
      method: "delete",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/collection/${params.collectionId}?permanent=true${
        params.handle ? `&handle=${params.handle}` : ""
      }`,
    },
    "create-my-catalog": {
      method: "put",
      url: `e/p/mercury/product/addLocalGroup?siteId=${
        params.shopCode || authzParams.memberCode
      }`,
    },
    "update-shared-catalog": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/product-groups/group/${params.groupId}`,
      body: omit(params, ["shopCode", "memberCode"]),
    },
    "get-shared-catalogs": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/product-groups`,
    },
    "get-shared-catalog-member-mappings": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/product-groups/mappings`,
    },
    "update-shared-catalog-member-mappings": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/product-groups/mappings`,
      body: params.payload,
    },
    "get-mol-catalog": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/catalog/fsg-search${
        params.queryByGlobal ? "?queryByGlobal=true" : ""
      }`,
    },
    "get-florist-catalog": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/catalog/search`,
    },
    "get-mol-and-florist-addons": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/addons${
        params.queryByGlobal ? "?queryByGlobal=true" : ""
      }`,
    },
    "sync-product-catalog": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/sync`,
    },
    "create-product": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/v2/products/mol/product`,
      body: omit(params, ["shopCode", "memberCode"]),
    },
    "get-product": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/product/${params.productId}${
        params.includeDeletedProducts ? "?includeDeletedProducts=true" : ""
      }`,
    },
    "update-product": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/v2/products/mol/product/${params.productId}`,
      body: omit(params, ["shopCode", "memberCode"]),
    },
    "delete-product": {
      method: "delete",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/product/${params.productId}`,
      body: omit(params, ["shopCode", "memberCode"]),
    },
    "restore-product": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/restore/${params.productId}`,
    },
    "product-revert-to-global": {
      method: "delete",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/product/revert-to-global/${params.productId}`,
      body: omit(params, ["shopCode", "memberCode"]),
    },
    "collection-revert-to-global": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/collection/revert-to-global?handles=${params.handle}`,
      body: omit(params, ["shopCode", "memberCode"]),
    },
    "bulk-products-update": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/bulkProductUpdate`,
      body: omit(params, ["shopCode", "memberCode"]),
    },
    "create-scheduled-price-adjustment": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/prices/rule`,
      body: omit(params, ["shopCode"]),
    },
    "get-scheduled-price-adjustment": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/prices/rules`,
    },
    "update-scheduled-price-adjustment": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/prices/rule/${params.ruleId}`,
      body: omit(params, ["shopCode"]),
    },
    "delete-scheduled-price-adjustment": {
      method: "delete",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/prices/rule/${params.ruleId}`,
      body: omit(params, ["shopCode"]),
    },
    "get-product-prs": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/${params.productId}${
        params.queryByGlobal ? "?queryByGlobal=true" : ""
      }`,
    },
    "get-website-settings": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/shop`,
    },
    "save-website-settings": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/shop`,
      body: omit(params, ["shopCode"]),
    },
    "get-store-pickup-settings": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/shop-pickup`,
    },
    "update-store-pickup-settings": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/shop-pickup`,
      body: omit(params, ["shopCode"]),
    },
    "upload-MOL-image": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/image?imageCategory=${params.imageCategory}${
        params.imageSource ? `&imageSource=${params.imageSource}` : ""
      }`,
      body: omit(params, ["shopCode, imageCategory"]),
    },
    "upload-image-to-shopify-cdn": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/shopify/image`,
      body: omit(params, ["shopCode, imageCategory"]),
    },
    "download-MOL-image": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/image?imageCategory=${
        params.imageCategory
      }&imageName=${params.imageName}`,
    },
    "get-mol-content": {
      method: "get",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/mol/${
        params.component
      }/${params.context || params.shopCode || authzParams.memberCode}/${
        params.assetId
      }/effectiveBytes`,
    },
    "get-mol-content-meta": {
      method: "get",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/mol/${
        params.component
      }/${params.context || params.shopCode || authzParams.memberCode}/${
        params.assetId
      }/effectiveMetaData`,
    },
    "save-mol-content": {
      method: "put",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/mol/${
        params.component
      }/${params.context || params.shopCode || authzParams.memberCode}/${
        params.assetId
      }`,
      body: omit(params, ["assetId", "component", "context", "shopCode"]),
    },
    "delete-mol-content": {
      method: "delete",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/mol/${
        params.component
      }/${params.context || params.shopCode || authzParams.memberCode}/${
        params.assetId
      }`,
    },
    "delete-mol-overridden-shops": {
      method: "delete",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/mol/${
        params.component
      }/${params.context || params.shopCode || authzParams.memberCode}/${
        params.assetId
      }`,
      body: params.body,
    },
    "get-site-fees": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/fees-site`,
    },
    "save-site-fees": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/fees-site`,
      body: params.requestPayload,
    },
    "get-cities-fees": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/fees-city`,
    },
    "save-cities-fees": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/fees-city`,
      body: params.requestPayload,
    },
    "get-cityzipcodes-fees": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/fees-zipcode?city=${params.city}&state=${
        params.state
      }&countryId=${params.countryId}`,
    },
    "save-cityzipcodes-fees": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/fees-zipcode`,
      body: params.requestPayload,
    },
    "get-cities": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/state-city-zipcode/lookup?countryId=${
        params.countryId
      }&provinceId=${params.state}`,
    },
    "get-delivery-exceptions": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/mol/deliveryExceptions?channel=MOL&isLegacyId=true`,
    },
    "save-delivery-exceptions": {
      method: "put",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/mol/deliveryExceptions?channel=MOL&isLegacyId=true`,
      body: params.requestPayload,
    },
    "delete-delivery-exceptions": {
      method: "delete",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/florist/mol/settings/mercury-os/suspend?isLegacyId=true&type=${
        params.exceptionType
      }&suspendId=${params.exceptionId}&channel=MOL`,
    },
    "get-exception-cities": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/mol/city-zip-info?isLegacyId=true`,
    },
    "get-exception-city-zips": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/mol/city-zip-info?${
        params.city ? `city=${params.city}&` : ""
      }isLegacyId=true`,
    },
    "refund-order": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/orders/mol/${params.orderItemId}/request/refund`,
      body: omit(params, ["orderItemId"]),
    },
    "validate-order": {
      method: "post",
      url: `e/p/mercury/${authzParams.memberCode}/orders/validate`,
      body: params.requestPayload,
    },
    "get-tax": {
      method: "post",
      url: `e/p/mercury/${authzParams.mosMemberCode}/taxes`,
      body: params,
    },
    "mol-order-search": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/orders/mol/${params.operatorID}/search?isOrderDetails=${
        params.isOrderDetails
      }`,
    },
    "get-member-entitlements": {
      method: "get",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/member-entitlements/`,
    },
    "get-user-roles-permissions": {
      method: "get",
      url: `e/p/ep-user-profile/api/mercuryos/${authzParams.memberCode}/permissions?roles=${params.roles}`,
    },
    "get-delivery-fee": {
      method: "get",
      url: `e/p/mercury/${params.selectedMemberCode}/florist/delivery-fees?country=${params.country}&state=${params.state}&deliveryDate=${params.deliveryDate}&city=${params.city}&zipCode=${params.zip}&streetAddress=${params.streetAddress}`,
    },
    "delivery-service-cancel": {
      method: "post",
      url: `e/p/mercury/${params.memberCode}/orders/${params.orderItemId}/delivery/cancel?deliveryMethod=${params.deliveryMethod}`,
      body: omit(params, ["orderItemId", "memberCode"]),
    },
    "sync-store-settings": {
      method: "put",
      url: `e/p/mercury/${params.memberCode}/mol/storeSettings/sync?${
        params.isCatalogSync
          ? "isCatalogSync=true"
          : params.isStoreSync
          ? "isStoreSync=true"
          : ""
      }`,
    },
    "manual-sync-settings": {
      method: "put",
      url: `e/p/mercury/${params.memberCode}/mol/storeSettings/${params.setting}/sync`,
    },
    "stuck-order-listing": {
      method: "get",
      url: `e/p/mercury/${params.memberCode}/mol/storeSettings/stuckOrderListing`,
    },

    "create-sem-rush-project": {
      method: "put",
      url: `e/p/mercury/${params.memberCode}/mol/storeSettings/semrush/project`,
    },
    "modify-order": {
      method: "PATCH",
      url: `e/p/mercury/${
        params.sourceMemberCode || authzParams.mosMemberCode
      }/orders/${params.recordId}?deliveryMethod=${params.deliveryMethod}`,
      body: params.orderUpdates,
    },
    "get-order-lock-status": {
      method: "GET",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/${params.recordId}/locks/status?deliveryMethod=${params.deliveryMethod}`,
    },
    "lock-order": {
      method: "POST",
      url: `e/p/mercury/${
        params.sourceMemberCode || authzParams.mosMemberCode
      }/orders/${params.recordId}/locks?deliveryMethod=${
        params.deliveryMethod
      }`,
    },
    "unlock-order": {
      method: "DELETE",
      url: `e/p/mercury/${authzParams.mosMemberCode}/orders/${params.recordId}/locks?deliveryMethod=${params.deliveryMethod}`,
    },
    "create-user-action": {
      method: "POST",
      url: `e/p/ep-user-profile/${
        params.siteId || authzParams.memberCode
      }/user-actions`,
      body: params,
    },
    "update-user-action": {
      method: "PATCH",
      url: `e/p/ep-user-profile/${
        params.sourceMemberCode || authzParams.memberCode
      }/user-actions/${params.userActionId}`,
      body: params.updatedInfo,
    },
    "get-user-actions": {
      method: "GET",
      url: `e/p/ep-user-profile/${
        params.sourceMemberCode || authzParams.memberCode
      }/user-actions?status=VIEWING&referenceId=${params.referenceId}`,
    },
    "install-middleware": {
      method: "get",
      url: `e/p/mercury/${params.memberCode}/mol/storeSettings/middleware/install`,
    },
    "install-mol-checkout-app": {
      method: "get",
      url: `e/p/mercury/${params.memberCode}/mol/storeSettings/checkout-ext/install`,
    },
    "ping-middleware-mol-checkout-app": {
      method: "get",
      url: `e/p/mercury/${params.memberCode}/mol/storeSettings/checkout-ext/app/ping`,
    },
    "ping-middleware": {
      method: "get",
      url: `e/p/mercury/${params.memberCode}/mol/storeSettings/middleware/app/ping`,
    },
    "google-geocode": {
      method: "get",
      url: `server/google-geocode?${params.lat ? `lat=${params.lat}` : ""}${
        params.lng ? `&lng=${params.lng}` : ""
      }${params.address ? `&address=${params.address}` : ""}${
        params.place_id ? `&place_id=${params.place_id}` : ""
      }`,
    },
    "google-place-id": {
      method: "get",
      url: `server/google-place-id?zip=${params.zip}`,
    },
    "autocomplete-location": {
      method: "get",
      url: `server/autocomplete-location?input=${params.input}&country=${params.country}&city=${params.city}&state=${params.state}&zip=${params.zip}
      &locationType=${params.locationType}`,
    },
    textsearch: {
      method: "get",
      url: `server/textsearch?input=${params.input}&country=${params.country}&city=${params.city}&state=${params.state}&zip=${params.zip}&locationType=${params.locationType}`,
    },
    "create-wedding-blog": {
      method: "get",
      url: `server/create-wedding-blog?shopId=${params.shopId}&accessToken=${params.accessToken}`,
    },
    "house-account-statements": {
      method: "get",
      url: `e/p/mercury-reporting/${
        authzParams.memberCode
      }/report/house-account/statements?month=${params.month}&year=${
        params.year
      }${
        params.houseAccountId ? `&houseAccountId=${params.houseAccountId}` : ""
      }`,
    },
    "get-delivery-dashboard-details": {
      method: "get",
      url: `e/p/mercury/${authzParams.memberCode}/orders?startDate=${params.startDate}&endDate=${params.endDate}&memberCodes=${params.fetchMemberCodes}&dashboardType=${params.dashboardType}`,
    },
    "create-autoroutes": {
      method: "post",
      url: `e/p/mercury/${params.shopCode}/delivery/auto-routes`,
      body: params.reqObj,
    },
    "delete-autoroutes": {
      method: "delete",
      url: `e/p/mercury/${params?.shopCode}/delivery/auto-routes/${params?.autoRouteId}`,
    },
    "create-task": {
      method: "post",
      url: `e/p/mercury/${params.shopCode}/shipments`,
      body: params.body,
    },
    "redelivery-ds-request": {
      method: "post",
      url: `e/p/mercury/${params.shopCode}/shipments/${params.previousShipmentId}/redelivery`,
      body: params.body,
    },
    "distance-matrix": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/delivery/distance-matrix?origins=${params.origins}&destinations=${params.destinations}`,
    },
    "get-quotations": {
      method: "get",
      url: `e/p/mercury/${params.memberCode}/shipment/quotations?referenceId=${params.referenceId}&latestQuoteOnly=${params.latestQuoteOnly}`,
    },
    "get-delivery-tasks-list": {
      method: "get",
      url: `e/p/mercury/${authzParams.memberCode}/shipments?deliveryStartDate=${params.deliveryStartDate}&deliveryEndDate=${params.deliveryEndDate}&sources=${params.sources}&states=${params.fetchStates}&memberCodes=${params.fetchMemberCodes}`,
    },
    "get-delivery-tasks-by-referenceId": {
      method: "get",
      url: `e/p/mercury/${params.memberCode}/shipments?referenceId=${params.referenceId}`,
    },
    "cancel-delivery-request": {
      method: "delete",
      url: `e/p/mercury/${params.shopCode}/shipments/${params?.shipmentId}`,
    },
    "get-delivery-request-details": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/shipments/${params?.shipmentId}`,
    },
    "acknowledge-delivery-request": {
      method: "PATCH",
      url: `e/p/mercury/${params.shopCode}/shipments/${params?.shipmentId}`,
      body: params.body,
    },
    "modify-delivery-request": {
      method: "PUT",
      url: `e/p/mercury/${params.shopCode}/shipments/${params?.shipmentId}`,
      body: params.body,
    },
    "approve-delivery-credit": {
      method: "POST",
      url: `e/p/mercury/${params.shopCode}/shipments/${params?.shipmentId}/credits`,
      body: params.body,
    },
    "get-pickup-manifest-print-status": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/print/${params.printKind}/${params.printKindId}`,
    },
    "create-pickup-manifest-print-status": {
      method: "post",
      url: `e/p/mercury/${params.shopCode}/print`,
      body: params.body,
    },
    "update-pickup-manifest-print-status": {
      method: "PATCH",
      url: `e/p/mercury/${params.shopCode}/print/${params.printKind}/${params.printKindId}`,
      body: params.body,
    },
    "edit-autoroutes": {
      method: "put",
      url: `e/p/mercury/${params?.shopCode}/delivery/auto-routes/${params?.autoRouteId}`,
      body: params.reqObj,
    },
    "validate-teleflora-credentials": {
      method: "post",
      url: `api/mercury-ingestion-teleflora/${authzParams.memberCode}/authenticate`,
      body: params,
    },
    "validate-WOI-credentials": {
      method: "post",
      url: `api/mercury-ingestion-woi/${authzParams.memberCode}/authenticate`,
      body: params,
    },
    "sales-tax-detail": {
      method: "get",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/sales-tax-details?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.filterBy}&printBy=${params.printBy}&timeZone=${params.userTimeZone}`,
    },
    "sales-tax-summary": {
      method: "get",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/sales-tax-summary?startDate=${params.startDate}&endDate=${params.endDate}&filterBy=${params.filterBy}&printBy=${params.printBy}&timeZone=${params.userTimeZone}`,
    },
    "sales-by-promo-code-detail": {
      method: "get",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/promo-code-detail?startDate=${params.startDate}&endDate=${params.endDate}&timeZone=${params.userTimeZone}`,
    },
    "sales-by-promo-code-summary": {
      method: "get",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/promo-code-summary?startDate=${params.startDate}&endDate=${params.endDate}&timeZone=${params.userTimeZone}`,
    },
    "credit-card-settlement-detail": {
      method: "get",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/credit-card-settlement-detail?startDate=${params.startDate}&endDate=${params.endDate}&formatType=${params.reportFormat}&timeZone=${params.userTimeZone}`,
    },
    "credit-card-settlement-summary": {
      method: "get",
      url: `e/p/mercury-reporting/${authzParams.memberCode}/report/credit-card-settlement-summary?startDate=${params.startDate}&endDate=${params.endDate}&formatType=${params.reportFormat}&timeZone=${params.userTimeZone}`,
    },
    "get-autoroutes-data": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/delivery/auto-routes${
        params.autoRouteId ? `/${params.autoRouteId}` : ""
      }`,
    },
    "get-zone-details": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/delivery/auto-routes?latitude=${params.latitude}&longitude=${params.longitude}`,
    },
    "get-customer-subscriptions": {
      method: "get",
      url: `e/p/customer-subscription/member/${
        params.shopCode || authzParams.memberCode
      }/customer/${params.customerId}/subscription`,
    },
    "create-subscription": {
      method: "post",
      url: `e/p/customer-subscription/member/${
        params.shopCode || authzParams.memberCode
      }/customer/${params.customerId}/subscription`,
      body: omit(params, ["customerId", "shopCode"]),
    },
    "modify-subscription": {
      method: "put",
      url: `e/p/customer-subscription/member/${
        params.shopCode || authzParams.memberCode
      }/customer/${params.customerId}/subscription/${params.subscriptionId}`,
      body: omit(params, ["customerId", "shopCode", "subscriptionId"]),
    },
    "delete-subscription-template": {
      method: "delete",
      url: `e/p/customer-subscription/member/${
        params.shopCode || authzParams.memberCode
      }/customer/${params.customerId}/subscription/${params.subscriptionId}`,
    },
    "pause-subscription-template": {
      method: "post",
      url: `e/p/customer-subscription/member/${
        params.shopCode || authzParams.memberCode
      }/subscription/pause`,
      body: params,
    },
    "update-pause-subscription-template": {
      method: "put",
      url: `e/p/customer-subscription/member/${
        params.shopCode || authzParams.memberCode
      }/subscription/pause/${params.pauseId}`,
      body: params,
    },
    "resume-subscription-template": {
      method: "delete",
      url: `e/p/customer-subscription/member/${
        params.shopCode || authzParams.memberCode
      }/subscription/pause/${params.pauseId}`,
    },
    "product-activity": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/products/mol/productactivity/${
        params.productId
      }?includeVariations=true`,
    },
    "create-bulk-ds-request": {
      method: "post",
      url: `e/p/mercury/${params.shopCode}/shipments/bulk-import`,
      body: params.body,
    },
    "get-bulk-ds-request-status": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/shipments/bulk-import/${params?.bulkImportJobId}`,
    },
    "get-bulk-ds-request-report": {
      method: "get",
      url: `e/p/mercury/${params.shopCode}/shipments/bulk-import/${params?.bulkImportJobId}/exception-report`,
    },
    "customer-notifications": {
      method: "get",
      url: `e/p/mercury/${authzParams.memberCode}/customer/${params.customerId}/email-notifications?fileName=${params.fileName}`,
    },
    "open-end-session": {
      method: "post",
      url: `e/p/ep-user-profile/api/mercuryOS/${authzParams.memberCode}/cash-drawer/session`,
      body: params,
    },
    "create-session-transaction": {
      method: "post",
      url: `e/p/ep-user-profile/api/mercuryOS/${authzParams.memberCode}/cash-drawer/event`,
      body: params,
    },
    "get-available-sessions": {
      method: "get",
      url: `e/p/ep-user-profile/api/mercuryOS/${authzParams.memberCode}/cash-drawer/sessions?macAddress=${params.macAddress}&status=active`,
    },
    "get-store-accessinfo": {
      method: "get",
      url: `e/p/mercury/${authzParams.memberCode}/mol/storeSettings/accessInfo`,
    },
    "get-designer-suggestions": {
      method: "get",
      url: `e/p/mercury/${authzParams.memberCode}/florist/users?memberCodes=${params.members}&status=ACTIVE&role=DESIGNER`,
    },
    "assign-designer": {
      method: "post",
      url: `e/p/mercury/${params.memberCode}/orders/designers`,
      body: params,
    },
    "connect-to-quickBooks": {
      method: "get",
      url: `e/p/mercury-accounting/${authzParams.memberCode}/connectToQuickBooks`,
    },
    "disconnect-from-quickBooks": {
      method: "get",
      url: `e/p/mercury-accounting/${authzParams.memberCode}/revokeToken`,
    },
    "get-quickBooks-authorization-status": {
      method: "get",
      url: `e/p/mercury-accounting/${authzParams.mosMemberCode}/authDetails`,
    },
    "get-gift-cards": {
      method: "get",
      url: `api/mercury-payment-giftcode/${
        params.shopCode || authzParams.memberCode
      }/giftcards?offset=0&size=100000`,
    },
    "get-gift-card-details": {
      method: "get",
      url: `api/mercury-payment-giftcode/${
        params.shopCode || authzParams.memberCode
      }/giftcard/${params.giftCardId}`,
    },
    "get-search-events": {
      method: "get",
      url: `e/p/member-event/${
        params.shopCode || authzParams.memberCode
      }/event?limit=${params.limit}&type.in=${
        params.eventType ? params.eventType : "WEDDING,FUNERAL,PARTY"
      }&search=${params.searchText}`,
    },
    "get-events": {
      method: "get",
      url: `e/p/member-event/${
        params.shopCode || authzParams.memberCode
      }/event?locations.0.deliveryDate.gte=${params.start}&limit=${
        params.limit
      }&type.in=${
        params.eventType ? params.eventType : "WEDDING,FUNERAL,PARTY"
      }&updated.sort=desc`,
    },
    "get-event": {
      method: "get",
      url: `e/p/member-event/${
        params.shopCode || authzParams.memberCode
      }/event/${params.eventId}`,
    },
    "get-public-event": {
      method: "get",
      url: `e/p/member-event/p/event/${params.eventId}`,
    },
    "save-event": {
      method: "post",
      url: `e/p/member-event/${
        params?.reqObj?.memberCode || params.shopCode || authzParams.memberCode
      }/event`,
      body: params.reqObj,
    },
    "update-event": {
      method: "put",
      url: `e/p/member-event/${
        params?.reqObj?.memberCode || params.shopCode || authzParams.memberCode
      }/event`,
      body: params.reqObj,
    },
    "update-public-event": {
      method: "PATCH",
      url: `e/p/member-event/p/event/${params.eventId}`,
      body: params.reqObj,
    },
    "delete-event": {
      method: "delete",
      url: `e/p/member-event/${
        params.shopCode || authzParams.memberCode
      }/event/${params.eventId}`,
    },
    "send-event-proposal": {
      method: "post",
      url: `e/p/member-event/${
        params.shopCode || authzParams.memberCode
      }/event/proposal`,
      body: params.reqObj,
    },
    "update-recipient-email": {
      method: "put",
      url: `api/mercury-payment-giftcode/${
        params.shopCode || authzParams.memberCode
      }/giftcard`,
      body: params,
    },
    "validate-gift-card": {
      method: "post",
      url: `api/mercury-payment-giftcode/${
        params.shopCode || authzParams.memberCode
      }/giftcard/payment/validate`,
      body: params,
    },
    "authorize-gift-card-payment": {
      method: "post",
      url: `api/mercury-payment-giftcode/${
        params.shopCode || authzParams.memberCode
      }/giftcard/payments`,
      body: params.requestPayload,
    },
    "validate-physical-giftCard": {
      method: "post",
      url: `api/mercury-payment-giftcode/${
        params.shopCode || authzParams.memberCode
      }/giftcard/encrypt`,
      body: params.requestPayload,
    },
    "get-giftCard-activities": {
      method: "get",
      url: `api/mercury-payment-giftcode/${
        params.shopCode || authzParams.memberCode
      }/giftcards/${
        params.giftCardId
      }/activities?limit=100000&offset=0&timezone=${params.userTimeZone}`,
    },
    "get-terminal-activation-codes": {
      method: "get",
      url: `api/payment-gateway/mhq/api/terminal/activationcodes?membercode=${params.membercode}&numberOfCodes=${params.size}`,
    },
    "post-on-demand-email": {
      method: "post",
      url: `e/p/mercury/${params.shopCode || authzParams.memberCode}/orders/${
        params.recordId
      }/on-demand-email-content`,
      body: params.requestPayload,
    },
    "get-html-email-content": {
      method: "get",
      url: `e/p/mercury/p/on-demand-email-content?id=${params.emailContentId}`,
    },
    "get-customers-info": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/customer/search/contact-info?searchBy=${params.searchBy}&search=${
        params.search
      }`,
    },
    "order-summary": {
      method: "PATCH",
      url: `e/p/mercury/${
        params.sourceMemberCode || authzParams.mosMemberCode
      }/orders/${params.recordId}/summary?deliveryMethod=${
        params.deliveryMethod
      }`,
      body: params.orderUpdates,
    },
    "save-draft-order": {
      method: "POST",
      url: `e/p/mercury/${
        params.sendingMember || authzParams.memberCode
      }/orders/draft`,
      body: params,
    },
    "get-draft-orders": {
      method: "GET",
      url: `e/p/mercury/${
        params.sendingMember || authzParams.memberCode
      }/orders/draft`,
    },
    "modify-draft-order": {
      method: "PUT",
      url: `e/p/mercury/${
        params.sendingMember || authzParams.memberCode
      }/orders/draft/${params.draftOrderID}`,
      body: params,
    },
    "delete-draft-order": {
      method: "DELETE",
      url: `e/p/mercury/${
        params.sendingMember || authzParams.memberCode
      }/orders/draft/${params.draftOrderID}`,
    },
    "get-marketing-emails": {
      method: "GET",
      url: `e/p/member-notification/${
        params.sendingMember || authzParams.memberCode
      }/marketing-notification/email?accountId=${
        params.accountId
      }&email_address=${params.email}`,
    },
    "add-marketing-email": {
      method: "POST",
      url: `e/p/member-notification/${
        params.sendingMember || authzParams.memberCode
      }/marketing-notification/email?accountId=${
        params.accountId
      }&email_address=${params.email}`,
    },
    "get-distance-in-miles": {
      method: "get",
      url: `e/p/mercury/${
        params.memberCode || authzParams.memberCode
      }/delivery/distance?origin=${params.origin}&destination=${
        params.destination
      }`,
    },
    "save-promo-code": {
      method: "POST",
      url: `e/p/mercury/${
        params.sendingMember || authzParams.memberCode
      }/promocode`,
      body: params,
    },
    "get-promo-code": {
      method: "GET",
      url: `e/p/mercury/${
        params.memberCode || authzParams.memberCode
      }/promocode/search?${params.queryParams}`,
    },
    "update-promo-code": {
      method: "PUT",
      url: `e/p/mercury/${params.memberCode}/promocode${
        params.action
          ? `/${params.promoCodeID}?actionType=${params.action}`
          : ""
      }`,
      ...(!params?.action && { body: params }),
    },
    "delete-promo-code": {
      method: "DELETE",
      url: `e/p/mercury/${params.memberCode}/promocode/${params.promoCodeID}`,
    },
    "get-subscriptions-for-contact": {
      method: "get",
      url: `e/p/customer-subscription/member/${
        params.shopCode || authzParams.memberCode
      }/customer/${params.customerId}/subscription/customerContact?contactIds=${
        params.contactIds
      }`,
    },
    "get-orders-new": {
      method: "GET",
      url: `api/2024-04/orders/annotated/${
        params.sendingMember || authzParams.memberCode
      }?q=${params.searchQuery}${params.filterQuery || ""}&limit=${
        params.limit
      }&offset=${params.offset}`,
    },
    "upload-designed-image": {
      method: "post",
      url: `e/p/mercury/${params.shopCode}/orders/${params.orderId}/images`,
      body: params.body,
    },
    "command-to-terminal": {
      method: "post",
      url: "server/terminal-transaction",
      body: params,
    },
    "get-auto-email-invoice-orders": {
      method: "get",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/orders/auto-email-invoice`,
    },
    "post-auto-email-invoice-orders": {
      method: "post",
      url: `e/p/mercury/${
        params.shopCode || authzParams.memberCode
      }/orders/auto-email-invoice`,
      body: params,
    },
  };

  return service[serviceName];
}
