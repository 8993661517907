import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { View, ActivityIndicator, TouchableOpacity, Text } from "react-native";
import { useFormikContext } from "formik";

import {
  LabelField,
  SecondaryButton,
  ConfirmModal,
  SaveCancelButtons,
  DateField,
  Currency,
  Section,
} from "components/wrappers";
import {
  ToasterHandler,
  DraggableFlatList as DraggableFlatListForDesktop,
} from "components/elements";
import DraggableFlatList from "react-native-draggable-flatlist";

import {
  Form,
  FormFieldPicker,
  FormFieldAutoComplete,
  FormField,
  FormFieldSwitch,
} from "components/elements/forms";
import { getUIConfig } from "../common/ui-config";
import { getValidationSchema } from "../common/yup";

import {
  selectScheduledPriceAdjustment,
  selectShopCode,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import { updateScheduledPriceAdjustment } from "library/sagas/views/home/drawer/product-catalog/common/slice";
import {
  selectScreenParam,
  selectProductLookup,
  selectIsPageLoading,
  selectAllCollections,
  selectAllAddons,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import { Settings, PriceMinimumProducts } from "../common/settings";

import { ScreenComponent } from "../helper";
import { DeviceContext } from "library/contexts/appSettings";
import I18NContext from "library/contexts/i18N";

import { fonts, backgroundColors } from "styles/theme";
import tw from "tailwind-rn";
import orderBy from "lodash/orderBy";
import capitalize from "lodash/capitalize";
import toUpper from "lodash/toUpper";
import moment from "moment";

const ScheduledPriceAdjustment = () => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);

  const currency = Currency(shopCode);
  const rules = useSelector(selectScheduledPriceAdjustment) || [];
  const { Localise, messages } = React.useContext(I18NContext);
  const { isDesktop } = React.useContext(DeviceContext);
  const isPageLoading = useSelector(selectIsPageLoading);
  const [priceRules, setPriceRules] = useState([]);
  const [scrollEnable, setScrollEnable] = useState(true);
  const [openSection, setOpenSection] = useState(false);
  const allCollections = useSelector(selectAllCollections);

  const initialValues = getUIConfig();

  useEffect(() => {
    setPriceRules(rules);
  }, [JSON.stringify(rules)]);

  const onSubmit = (values, formikBag) => {
    const {
      id = "",
      operation = "",
      fieldValue = "",
      action = "",
      startDate = "",
      endDate = "",
      products = [],
      collections = [],
      addons = [],
      active: ruleActive = true,
    } = values || {};

    const payloadData = priceRules
      .map((rule) => {
        const finalFieldValue =
          rule.id === id
            ? operation === "decrement"
              ? `-${fieldValue}`
              : fieldValue
            : rule.operation === "decrement"
            ? `-${rule.fieldValue}`
            : rule.fieldValue;

        return {
          id: rule.id === id ? id : rule.id,
          action: rule.id === id ? action : rule.action,
          startDate: rule.id === id ? startDate : rule.startDate,
          endDate: rule.id === id ? endDate : rule.endDate,
          products: rule.id === id ? products : rule.products,
          collections: rule.id === id ? collections : rule.collections,
          addons: rule.id === id ? addons : rule.addons,
          fieldValue: finalFieldValue,
          active: rule.id === id ? ruleActive : rule.active,
        };
      })
      .filter(
        (rule) => moment().format("L") <= moment(rule.endDate).format("L")
      );

    dispatch(
      updateScheduledPriceAdjustment({
        params: { values: payloadData },
        resolve: () => {
          ToasterHandler(
            "looking good",
            Localise(
              messages,
              `Product price settings have been ${id ? "updated" : "created"}`
            )
          );
          formikBag.setSubmitting(false);
          formikBag.resetForm({ values });
          setOpenSection(true);
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
          formikBag.setSubmitting(false);
          setOpenSection(true);
        },
      })
    );
  };

  useEffect(() => {
    return () => {
      setOpenSection(false);
    };
  }, [shopCode]);

  const DraggableComponent = isDesktop
    ? DraggableFlatListForDesktop
    : DraggableFlatList;

  const PriceAdjustment = ({ data }) => {
    const priceRulesData = data.map((rule) => {
      return rule.id;
    });

    return (
      <>
        {data.length > 0 ? (
          <DraggableComponent
            containerStyle={{
              ...(isDesktop ? { height: 1000 } : {}),
              width: "100%",
            }}
            overrideZindex={2}
            data={priceRulesData}
            onDragEnd={(prop) => {
              const data = isDesktop ? prop : prop.data;
              const sortedData = [...priceRules].sort((a, b) => {
                return data.indexOf(a.id) - data.indexOf(b.id);
              });

              setPriceRules(sortedData);

              const payloadData = sortedData.map((rule) => {
                const finalFieldValue =
                  rule.operation === "decrement"
                    ? `-${rule.fieldValue}`
                    : rule.fieldValue;

                return {
                  id: rule.id,
                  action: rule.action,
                  startDate: rule.startDate,
                  endDate: rule.endDate,
                  products: rule.products,
                  collections: rule?.collections || [],
                  addons: rule.addons,
                  fieldValue: finalFieldValue,
                  active: rule.active,
                };
              });

              dispatch(
                updateScheduledPriceAdjustment({
                  params: { values: payloadData },
                  resolve: () => {
                    ToasterHandler(
                      "looking good",
                      Localise(messages, `Price Rules order have been sorted`)
                    );
                  },
                  reject: () => {
                    ToasterHandler(
                      "uh oh",
                      Localise(
                        messages,
                        "We were unable to process your request, please try again."
                      )
                    );
                  },
                })
              );

              setScrollEnable(true);
            }}
            keyExtractor={(item) => item}
            onDragBegin={() => {
              setScrollEnable(false);
            }}
            autoscrollThreshold={100}
            dragDropIcon={true}
            activationDistance={scrollEnable ? 10 : 1}
            renderItem={(data, i) => {
              const ruleId = isDesktop ? data : data.item;
              const index = isDesktop ? i : data.index;
              const rule = priceRules.find(
                (ruleData) => ruleData.id === ruleId
              );
              const { active: ruleActive = true } = rule || {};
              const ruleOperation =
                rule.operation === "increment" ? "Increase" : "Decrease";
              const ruleAction =
                rule.action === "dollar"
                  ? `$${rule.fieldValue.replace(/\.00$/, "")}`
                  : `${rule.fieldValue.replace(/\.00$/, "")}%`;

              const isRuleExists =
                rule.action &&
                rule.fieldValue &&
                rule.products.length > 0 &&
                rule.startDate &&
                rule.endDate;

              const findCollections = allCollections
                .filter((e) => rule.collections?.includes(e.handle))
                .map((e) => e.name);

              const skus = rule.products.includes("*")
                ? `SKUs - All Products`
                : rule?.collections?.length > 0
                ? `Collections - (${findCollections.toString()})`
                : ` SKUs - ${
                    rule.products.length > 3
                      ? rule.products.slice(0, 3).toString() + ", etc..."
                      : rule.products.toString()
                  }`;
              const openAccordion =
                openSection && index === priceRules.length - 1;
              const title = isRuleExists
                ? isDesktop
                  ? `Price ${ruleOperation} by ${ruleAction} - ${moment(
                      rule.startDate,
                      "MM/DD/YYYY"
                    ).format("M/D/YY")} to ${moment(
                      rule.endDate,
                      "MM/DD/YYYY"
                    ).format("M/D/YY")} - ${skus}`
                  : `${moment(rule.startDate, "MM/DD/YYYY").format(
                      "M/D/YY"
                    )} to ${moment(rule.endDate, "MM/DD/YYYY").format(
                      "M/D/YY"
                    )} `
                : "Create Price Rule";
              const drag = !isDesktop && data.drag;
              return (
                <TouchableOpacity
                  style={tw("flex flex-col")}
                  onLongPress={drag && drag}
                  disabled={isDesktop && true}
                >
                  <Section
                    title={title}
                    defaultOpen={!isRuleExists || openAccordion}
                    overrideZindex={-10 - index}
                    labelStyle={{
                      backgroundColor: ruleActive
                        ? backgroundColors.navBar
                        : backgroundColors.sideCar,
                    }}
                  >
                    <Form
                      initialValues={getUIConfig(rule)}
                      onSubmit={onSubmit}
                      validateOnChange={true}
                      validateOnBlur={false}
                      validationSchema={getValidationSchema(Localise, messages)}
                      render={() => (
                        <ProductPriceAdjustment
                          currency={currency}
                          priceRules={priceRules}
                          index={index}
                          setPriceRules={setPriceRules}
                        />
                      )}
                    />
                  </Section>
                </TouchableOpacity>
              );
            }}
          />
        ) : null}
      </>
    );
  };

  const createNewRule = () => {
    const existingRules = [...rules];
    existingRules.push({
      ...initialValues,
      id: `${Math.floor(Math.random() * 90000) + 10000}`,
    });

    setPriceRules(existingRules);
  };
  const checkRules = priceRules.length > rules.length;

  return isPageLoading ? (
    <ActivityIndicator size={"small"} />
  ) : (
    <View
      style={[
        tw(
          `flex w-4/5 mb-5 ${
            priceRules.length > 0
              ? "flex-col"
              : "flex-row items-center justify-between"
          } `
        ),
        { marginTop: 25 },
      ]}
    >
      <View style={tw("flex flex-col mb-5")}>
        <LabelField
          heading={false}
          style={fonts.heading3}
          text={"Price Adjustment Rules"}
        />
        <LabelField
          heading={false}
          numberOfLines={2}
          style={fonts.default}
          text={
            "Configure price rules and set the priority using drag and drop feature."
          }
        />
      </View>
      <PriceAdjustment data={priceRules} />

      <View
        style={[tw("flex flex-row items-center justify-end"), { zIndex: -1 }]}
      >
        {!checkRules && (
          <SecondaryButton
            title={!priceRules.length ? "Create" : "Add More"}
            style={{
              width: 100,
              paddingBottom: 50,
            }}
            action={createNewRule}
          />
        )}
      </View>
    </View>
  );
};

const ProductPriceAdjustment = ({
  currency,
  priceRules,
  index,
  setPriceRules,
}) => {
  const [modalVisible, setModalVisibile] = useState(false);
  const [dropDownValue, setDropDownValue] = useState("");
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const dispatch = useDispatch();
  const allRules = useSelector(selectScheduledPriceAdjustment) || [];
  const allProductsData = useSelector(selectProductLookup);
  const allCollections = useSelector(selectAllCollections);
  const allAddons = useSelector(selectAllAddons);
  const checkForNewRule = priceRules.length > allRules.length;
  const productsData = Object.values(allProductsData);

  const { setFieldValue, values } = useFormikContext();
  const {
    action,
    products: scheduledProducts = [],
    id: ruleId = "",
    collections: scheduledCollections = [],
    addons: scheduledAdons = [],
    startDate = "",
    endDate = "",
    active = true,
  } = values;

  const getDropDownData = (data, pricRuleAppliedData, isSelected, handle) => {
    return data.filter((val) =>
      isSelected
        ? pricRuleAppliedData.includes(val[handle])
        : !pricRuleAppliedData.includes(val[handle])
    );
  };

  const filterMapSort = (data, filterConditions, dropDownValue) => {
    return orderBy(
      data
        .filter(
          (e) =>
            (toUpper(e.name).includes(toUpper(dropDownValue)) ||
              toUpper(e.productId).includes(toUpper(dropDownValue))) &&
            filterConditions(e)
        )
        .map((e) => ({
          label: Localise(
            messages,
            e.name.split(" ").map(capitalize).join(" ")
          ),
          value: e.productId || e.handle,
        })),
      "label",
      "asc"
    );
  };

  const generateAllDropdownData = (dropDownValue) => {
    const generateData = (allData, scheduledData, handle, filterConditions) => {
      const selectedData = getDropDownData(
        allData,
        scheduledData,
        true,
        handle
      );
      const unselectedData = getDropDownData(
        allData,
        scheduledData,
        false,
        handle
      );

      return [
        ...filterMapSort(selectedData, filterConditions, dropDownValue),
        ...filterMapSort(unselectedData, filterConditions, dropDownValue),
      ];
    };

    return {
      productsDropDownData: [
        { label: "All Products", value: "*" },
        ...generateData(
          productsData,
          scheduledProducts,
          "productId",
          (e) => e.catalogType !== "addons"
        ),
      ],
      collectionDropDownData: generateData(
        allCollections,
        scheduledCollections,
        "handle",
        (e) => e.handle !== "addons"
      ),
      addonsDropDownData: generateData(
        allAddons,
        scheduledAdons,
        "productId",
        (e) => e.catalogType === "addons"
      ),
    };
  };

  const { productsDropDownData, collectionDropDownData, addonsDropDownData } =
    generateAllDropdownData(dropDownValue);

  const onDelete = () => {
    const payload = priceRules.filter((rule) => rule.id !== ruleId);

    dispatch(
      updateScheduledPriceAdjustment({
        params: { values: payload },
        resolve: () => {
          ToasterHandler(
            "looking good",
            Localise(messages, "Product price settings have been deleted")
          );
        },
        reject: () => {
          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              "We were unable to process your request, please try again."
            )
          );
        },
      })
    );
  };

  const onCancel = () => {
    if (priceRules.length > allRules.length) {
      const updatedRules = priceRules.slice(0, -1);
      setPriceRules(updatedRules);
    }
  };
  const handleDeleteRule = () => {
    setModalVisibile(false);
    onDelete();
  };

  const handleNevermind = () => {
    setModalVisibile(false);
  };

  useEffect(() => {
    if (
      startDate &&
      (!endDate || moment(startDate).isAfter(moment(endDate, "MM/DD/YYYY")))
    ) {
      setFieldValue("endDate", startDate);
    }
  }, [startDate, endDate]);

  return (
    <View style={{ marginTop: 20 }} key={index}>
      <View style={[tw("flex flex-row flex-wrap"), { zIndex: 10 }]}>
        <FormFieldAutoComplete
          name={"collections"}
          label={"Collections"}
          customDataLimit={
            isMobile && allCollections.length > 100 ? 100 : undefined
          }
          outerContainerStyle={{
            width: isMobile ? "100%" : 250,
            paddingLeft: 0,
            zIndex: 12,
          }}
          labelStyle={fonts.heading4}
          data={collectionDropDownData}
          placeholder={
            scheduledCollections?.length > 0
              ? scheduledCollections[0] === "*"
                ? Localise(messages, "All Products")
                : scheduledCollections?.length === 1
                ? collectionDropDownData[
                    collectionDropDownData.findIndex(
                      (i) => i.value === scheduledCollections[0]
                    )
                  ]?.label
                : `${scheduledCollections?.length} ${Localise(
                    messages,
                    "Selected"
                  )}`
              : Localise(messages, "Select Collections")
          }
          initialDataLength={allCollections.length}
          isMultiSelect={true}
          onSelect={(selectedValue) => {
            const newCollections = [...scheduledCollections];
            const index = newCollections.indexOf(selectedValue.value);
            const unSelected = index >= 0;
            unSelected
              ? newCollections.splice(index, 1)
              : newCollections.push(selectedValue.value);

            const productIds = [
              ...new Set(
                allCollections
                  .filter((item) => newCollections.includes(item.handle))
                  .map((item) => item.productIds)
                  .flat()
              ),
            ];

            const unSelectedCollection =
              allCollections.find((e) => e.handle === selectedValue.value)
                ?.productIds || [];
            const removeUnselectedProducts =
              [...scheduledProducts].filter(
                (e) => !unSelectedCollection.includes(e)
              ) || [];

            setFieldValue("collections", newCollections);
            unSelected
              ? setFieldValue("products", removeUnselectedProducts)
              : setFieldValue("products", [
                  ...new Set([...scheduledProducts, ...productIds]),
                ]);
          }}
          onBlur={() => {
            setDropDownValue("");
          }}
          showOnFocus={true}
          setFocusBack={true}
          onChangeText={(val) => setDropDownValue(val)}
          listDisplayValues={["label"]}
        />
        <FormFieldAutoComplete
          name={"products"}
          label={"Products"}
          outerContainerStyle={{
            width: isMobile ? "100%" : 250,
            paddingLeft: 0,
            zIndex: 11,
          }}
          customDataLimit={
            isMobile && productsData.length > 100 ? 100 : undefined
          }
          labelStyle={fonts.heading4}
          data={productsDropDownData}
          placeholder={
            productsDropDownData.filter((e) =>
              scheduledProducts.includes(e.value)
            )?.length > 0
              ? scheduledProducts[0] === "*"
                ? Localise(messages, "All Products")
                : productsDropDownData.filter((e) =>
                    scheduledProducts.includes(e.value)
                  )?.length === 1
                ? productsDropDownData[
                    productsDropDownData.findIndex(
                      (i) =>
                        i.value ===
                        scheduledProducts.find((val) => val === i.value)
                    )
                  ]?.label
                : `${
                    productsDropDownData.filter((e) =>
                      scheduledProducts.includes(e.value)
                    )?.length
                  } ${Localise(messages, "Selected")}`
              : Localise(messages, "Select products")
          }
          initialDataLength={productsData.length}
          isMultiSelect={true}
          onSelect={(selectedValue) => {
            const newProducts = [...scheduledProducts];
            const index = newProducts.indexOf(selectedValue.value);
            const unSelected = index >= 0;
            unSelected
              ? newProducts.splice(index, 1)
              : newProducts.push(selectedValue.value);

            setFieldValue("products", newProducts);
          }}
          onBlur={() => {
            setDropDownValue("");
          }}
          showOnFocus={true}
          setFocusBack={true}
          onChangeText={(val) => setDropDownValue(val)}
          listDisplayValues={["label"]}
        />
        <FormFieldAutoComplete
          name={"addons"}
          label={"Add-Ons"}
          outerContainerStyle={{
            width: isMobile ? "100%" : 250,
            paddingLeft: 0,
            zIndex: 10,
          }}
          customDataLimit={isMobile && allAddons.length > 100 ? 100 : undefined}
          labelStyle={fonts.heading4}
          data={addonsDropDownData}
          placeholder={
            scheduledAdons?.length > 0
              ? scheduledAdons[0] === "*"
                ? Localise(messages, "All Products")
                : scheduledAdons?.length === 1
                ? addonsDropDownData[
                    addonsDropDownData.findIndex(
                      (i) => i.value === scheduledAdons[0]
                    )
                  ]?.label
                : `${scheduledAdons?.length} ${Localise(messages, "Selected")}`
              : Localise(messages, "Select Addons")
          }
          initialDataLength={allAddons.length}
          isMultiSelect={true}
          onSelect={(selectedValue) => {
            const newProducts = [...scheduledAdons];
            const index = newProducts.indexOf(selectedValue.value);
            const unSelected = index >= 0;
            unSelected
              ? newProducts.splice(index, 1)
              : newProducts.push(selectedValue.value);
            const removeUnselectedProducts =
              [...scheduledProducts].filter((e) => e !== selectedValue.value) ||
              [];

            setFieldValue("addons", newProducts);
            unSelected
              ? setFieldValue("products", removeUnselectedProducts)
              : setFieldValue("products", [
                  ...new Set([...scheduledProducts, ...newProducts]),
                ]);
          }}
          onBlur={() => {
            setDropDownValue("");
          }}
          showOnFocus={true}
          setFocusBack={true}
          onChangeText={(val) => setDropDownValue(val)}
          listDisplayValues={["label"]}
        />
      </View>

      <View style={tw("flex flex-row flex-wrap my-2")}>
        <FormFieldPicker
          name={`operation`}
          labelStyle={fonts.heading4}
          containerStyle={{
            width: isMobile ? 110 : 180,
            paddingBottom: 5,
            paddingLeft: 0,
          }}
          placeholder={{
            label: "Choose variation",
            value: "",
          }}
          data={[
            { label: "Increase", value: "increment" },
            { label: "Decrease", value: "decrement" },
          ]}
        />
        <FormFieldPicker
          name={"action"}
          labelStyle={fonts.heading4}
          containerStyle={{
            width: 110,
            paddingBottom: 7,
          }}
          placeholder={{
            label: `$${currency} or %`,
            value: "",
          }}
          data={[
            { label: `$${currency}`, value: "dollar" },
            { label: "%", value: "percentage" },
          ]}
        />
        {action ? (
          <FormField
            containerStyle={{ width: 100 }}
            name={"fieldValue"}
            iconType={"material-community"}
            iconSize={14}
            iconName={action === "dollar" ? "currency-usd" : "percent"}
            iconPosition={action !== "percentage"}
            currency={currency}
            inputStyle={{
              fontSize: 13,
              maxWidth: 100,
            }}
            inputContainerStyle={{
              padding: 6,
            }}
            leftIconContainerStyle={{
              paddingRight: 0,
            }}
            editable={true}
            disabled={false}
            isPrice={true}
          />
        ) : null}
      </View>
      <View style={[tw("flex flex-row")]}>
        <DateField
          name={`startDate`}
          label={"Start Date"}
          endDatePath="endDate"
          popperPlacement={"bottom"}
        />

        <DateField
          label={"End Date"}
          name={`endDate`}
          popperPlacement={"bottom"}
        />
      </View>
      <View
        style={[
          tw("flex flex-row items-center justify-between py-3 pl-1 mt-3"),
          { width: isMobile ? "100%" : 150, zIndex: -1 },
        ]}
      >
        <View style={tw("flex")}>
          <Text
            style={{
              ...fonts.heading5,
            }}
          >
            {Localise(messages, "Status")}
          </Text>
        </View>

        <View style={tw("flex flex-row items-center")}>
          <FormFieldSwitch
            name={"active"}
            value={active}
            onValueChange={() => setFieldValue("active", !active)}
          />
          {!isMobile ? (
            <LabelField
              text={active ? "Active" : "Inactive"}
              style={{
                ...fonts.heading5,
                paddingLeft: 6,
                marginTop: 1,
              }}
            />
          ) : null}
        </View>
      </View>
      <View
        style={[
          tw(
            `flex ${
              isDesktop
                ? ` flex-row items-center justify-between `
                : `flex-col items-end`
            }`
          ),
          { zIndex: -2, paddingBottom: 50 },
        ]}
      >
        {!checkForNewRule && (
          <SecondaryButton
            title={Localise(messages, "Delete")}
            action={() => setModalVisibile(true)}
            disabled={!ruleId}
            style={{ width: 100 }}
          />
        )}
        <ConfirmModal
          modalVisible={modalVisible}
          handlePrimary={handleDeleteRule}
          handleSecondary={handleNevermind}
          textPrimary={"Delete"}
          textSecondary={"Nevermind"}
        />
        <SaveCancelButtons
          onCancel={onCancel}
          buttonTitle={ruleId ? "Save Adjustment" : "Create Adjustment"}
          showCancelAlways={true}
          saveCancelContainerStyles={{ paddingBottom: isMobile ? 50 : 0 }}
        />
      </View>
    </View>
  );
};

const PriceAdjustments = ({ rootNavigation }) => {
  const CatalogSideCar = () => {
    const sideCar = useSelector(selectScreenParam("sideCar"));

    return sideCar === "catalogSettings" ? (
      <Settings />
    ) : sideCar === "priceMinimumProducts" ? (
      <PriceMinimumProducts />
    ) : null;
  };
  return (
    <ScreenComponent rootNavigation={rootNavigation}>
      <View style={{ height: 800 }}>
        <ScheduledPriceAdjustment />
      </View>
      <CatalogSideCar />
    </ScreenComponent>
  );
};

export default PriceAdjustments;
