import React, { useContext, useEffect } from "react";
import { View, TouchableOpacity, Image, Platform } from "react-native";
import { useSelector } from "react-redux";

import { Text, Button } from "react-native-elements";
import { useFormikContext } from "formik";

import find from "lodash/find";
import startCase from "lodash/startCase";
import get from "lodash/get";
import toLower from "lodash/toLower";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";

import moment from "moment";
import tw from "tailwind-rn";
import { theme, fonts, colors } from "styles/theme";
import { getRequestObj } from "../helper";
import {
  Form,
  FormFieldDatePicker,
  SubmitButton,
} from "components/elements/forms";
import IMAGES from "static/assets/images";
import useStateIfMounted from "library/utils/useStateIfMounted";
import I18NContext from "library/contexts/i18N";
import { compare as generateJSONPatch } from "fast-json-patch";
import { request } from "library/utils/request";
import {
  selectRecordData,
  selectOrderItemId,
  selectActualDeliveryMethod,
} from "library/sagas/ongoing/current-orders/selector";
import { selectRouteDetails } from "library/sagas/ongoing/order-details/selector";
import {
  extractTimeFromDI,
  DELIVER_BY_REGEX,
} from "components/views/drawer/create-order/helper";
import { ToasterHandler, Tooltip, CustomModal } from "components/elements";
import AppSettingsContext from "library/contexts/appSettings";
import ShopPermissions from "library/utils/featureAvailability";

const RequestDateChange = ({
  triggerRequest,
  triggerAction,
  index,
  supportDateChange,
  isSmallScreen,
}) => {
  const orderDetailResponse = useSelector(selectRecordData);
  const orderDetails = orderDetailResponse.orderItems[index];
  const routeDetails = useSelector(selectRouteDetails);

  const { messages: locMessages, Localise } = useContext(I18NContext);
  const { values } = useFormikContext();
  const [showRequestNewDateForm, setShowRequestNewDateForm] =
    useStateIfMounted(false);

  const [showDeliverByTimeForm, setShowDeliverByTimeForm] =
    useStateIfMounted(false);
  const [modalVisible, setModalVisibile] = useStateIfMounted(false);

  const deliveryInfoPath = `orderItems.${index}.deliveryInfo`;
  const deliveryInfo = get(values, deliveryInfoPath, {});

  const {
    deliveryDate,
    newDeliveryDate,
    dateChangeInfo = {},
    requestDirection = "",
    isDSSubmitted,
  } = deliveryInfo;

  const {
    messages = [],
    dsEligible = false,
    status: orderStatus,
    hasFulfillmentError = false,
    orderSource = "",
    deliveryInfo: {
      deliveryMethod,
      deliveryDate: oldDeliveryDate,
      deliveryInstructions,
      orderDeliveryTime = "",
      routeId = "",
    },
    pickupInfo = {},
    isRushOrder = false,
    direction = "",
    receivingMember = {},
    sendingMember = {},
  } = orderDetails;

  const hasApprovedorDeniedMessage = find(
    [...messages].reverse(),
    (message) =>
      message.messageType === "DeliveryDateApproval" ||
      message.messageType === "DeliveryDateDeny"
  );
  const hasApprovedandDeniedStatus =
    hasApprovedorDeniedMessage &&
    requestDirection === "OUTBOUND" &&
    hasApprovedorDeniedMessage.status === "NEW";
  const isFolMolOrder = orderSource === "FOL" || orderSource === "MOL";
  const pendingDateChangeRequest =
    messages.find(
      (message) =>
        message.messageType === "DeliveryDate" &&
        message.requestStatus === "WAITING"
    ) || false;

  //Properties used directly
  const showRequestNewDateLink =
    dateChangeInfo.status !== "APPROVE_DENY" &&
    (!["FORFEITED", "CANCELLED", "REJECTED"].includes(orderStatus) ||
      (hasFulfillmentError && orderStatus === "REJECTED")) &&
    supportDateChange &&
    !showRequestNewDateForm;
  const showDateChangeRequestStatus =
    hasApprovedandDeniedStatus ||
    (!!pendingDateChangeRequest &&
      dateChangeInfo.status !== "APPROVE_DENY" &&
      !isFolMolOrder);

  const { storePickupDateTime = "" } = pickupInfo || {};

  const rushTime = extractTimeFromDI(deliveryInstructions); //For old rush orders before timed delivery feature

  const deliverBy =
    orderDeliveryTime !== ""
      ? orderDeliveryTime
      : isRushOrder
      ? rushTime
      : storePickupDateTime !== ""
      ? moment(storePickupDateTime).format("hh:mm A ")
      : "";

  const requestNewDateActions = ({
    values: { newDeliveryDate = "" } = {},
    actionType = "",
    askId,
    formikBag,
  }) => {
    if (actionType.length > 0) {
      triggerAction({
        action: actionType,
        askId: askId,
        formikBag,
      });
    } else {
      const itemPrice = "";
      const requestType = "delivery-date";
      const orderReq = getRequestObj(
        orderDetails,
        requestType,
        itemPrice,
        newDeliveryDate,
        `Requesting to change delivery date from ${moment(deliveryDate).format(
          "MM/DD/YYYY"
        )} to ${moment(newDeliveryDate).format("MM/DD/YYYY")}`,
        "Changing Delivery Date"
      );

      triggerRequest({ requestType, orderReq });
    }
  };

  const { permissions = {} } = useContext(AppSettingsContext);

  const sourceMemberCode =
    direction === "INBOUND"
      ? receivingMember?.memberCode
      : sendingMember?.memberCode;

  const isTimedDeliveryAvailable = ShopPermissions.isTimedDeliveryAvailable(
    permissions,
    sourceMemberCode
  );

  /* Displaying the TimePicker icon even if the member doesn't have the Timed Delivery feature, 
   as long as the order has an orderDeliveryTime. 
   This can occur if the sender has the Timed Delivery feature, but the receiver does not.
  */
  const showDeliverByTimeChangeIcon =
    (isTimedDeliveryAvailable || !!orderDeliveryTime) &&
    deliveryMethod === "FLORIST_PARTNER" &&
    !showDeliverByTimeForm &&
    (!["FORFEITED", "CANCELLED", "REJECTED"].includes(orderStatus) ||
      (hasFulfillmentError && orderStatus === "REJECTED"));

  const isPastDate = moment(oldDeliveryDate).isBefore(moment(), "day");

  const handleContinue = () => {
    setModalVisibile(false);
    setShowRequestNewDateForm(true);
  };

  return (
    <View style={[tw("flex flex-col items-end"), { zIndex: -1 }]}>
      <Text
        style={[
          fonts.style1,
          {
            paddingVertical: 5,
            fontSize: 15,
            color: colors.primary,
            fontWeight: "bold",
            textAlign: "right",
          },
        ]}
        testID={"order_details_delivery_date_text"}
      >
        {Localise(locMessages, "Delivery Date")}
      </Text>
      <Text>
        {moment(storePickupDateTime || deliveryDate).format("MM/DD/YYYY")}
      </Text>

      {showRequestNewDateLink && (
        <TouchableOpacity
          style={[tw("pt-1")]}
          onPress={() => {
            routeId !== ""
              ? setModalVisibile(true)
              : !isDSSubmitted
              ? setShowRequestNewDateForm(true)
              : "";
          }}
          testID="request_new_delivery_date"
          accessibilityLabel="request_new_delivery_date"
        >
          <Tooltip
            text={Localise(locMessages, "Click to change Delivery Date")}
            renderForWebOnly={true}
          >
            <Image
              style={{ width: 30, height: 30 }}
              resizeMode="cover"
              source={IMAGES["calendar-rounded"]}
            />
          </Tooltip>
        </TouchableOpacity>
      )}
      {showDateChangeRequestStatus && (
        <Text
          style={[
            {
              fontStyle: "italic",
              paddingTop: dsEligible && !isDSSubmitted ? 15 : 0,
              textAlign: "right",
            },
          ]}
          testID="date_change_info_status"
        >
          {Localise(locMessages, startCase(toLower(dateChangeInfo.status)))}
        </Text>
      )}
      {/* Request New Delivery Date */}
      {showRequestNewDateForm && (
        <Form
          initialValues={{
            newDeliveryDate: "",
          }}
          onSubmit={(values, formikBag) => {
            if (formikBag.isSubmitting) return;
            setShowRequestNewDateForm(false);
            requestNewDateActions({ values });
          }}
          render={({ values, isSubmitting }) => (
            <>
              <FormFieldDatePicker
                label={Localise(locMessages, "New Delivery Date")}
                dateValueFormat={"YYYY-MM-DD"}
                name={"newDeliveryDate"}
                placeholder={"New Date"}
                containerStyle={{
                  zIndex: 20,
                  marginTop: 10,
                }}
                labelStyle={{
                  fontSize: 15,
                  fontWeight: "bold",
                  textAlign: "right",
                }}
                inputContainerStyle={{ maxWidth: 110 }}
                customInputContainerStyle={{
                  ...tw(`items-end`),
                  paddingHorizontal: 0,
                }}
                alignIconRight={true}
                iconName="calendar"
                iconType="font-awesome"
                disabled={false}
                editable={true}
                minDate={new Date()}
                testID="newDeliveryDate"
                popperPlacement={"bottom"}
                errorStyle={{ paddingBottom: 0 }}
                renderErrorMessage={false}
                popperModifiersOverride={{
                  offset: {
                    enabled: true,
                    offset: "-40px, 3px",
                  },
                }}
              />
              <View
                style={tw(
                  `flex flex-row flex-wrap justify-end ${
                    !isSmallScreen ? "items-baseline" : ""
                  }`
                )}
              >
                {values.newDeliveryDate.length > 0 &&
                  values.newDeliveryDate !== oldDeliveryDate && (
                    <SubmitButton
                      title={"Request"}
                      isLoading={isSubmitting}
                      containerStyle={{ margin: 0, marginTop: 5 }}
                      buttonStyle={{
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                      }}
                      testID={"request_date_change_request"}
                      accessibilityLabel={"request_date_change_request"}
                    />
                  )}
                <Button
                  testID={"cancel_date_change_request"}
                  accessibilityLabel={"cancel_date_change_request"}
                  title={Localise(locMessages, "Cancel")}
                  titleStyle={{
                    ...theme.Button.secondaryTitleStyle,
                    fontSize: 12,
                  }}
                  buttonStyle={{
                    ...theme.Button.secondaryButtonStyle,
                    paddingVertical: 5,
                    paddingHorizontal: 15,
                  }}
                  containerStyle={{
                    margin: 5,
                    justifyContent: "center",
                    marginRight: 0,
                  }}
                  onPress={() => setShowRequestNewDateForm(false)}
                />
              </View>
            </>
          )}
        />
      )}
      {/* Show Approve & Deny */}
      {dateChangeInfo.status === "APPROVE_DENY" && (
        <Form
          initialValues={{
            newDeliveryDate,
            isDenyClicked: false,
          }}
          onSubmit={(_, formikBag) => {
            if (formikBag.isSubmitting) return;
            setShowRequestNewDateForm(false);
            requestNewDateActions({
              actionType: "delivery-date-approval",
              askId: dateChangeInfo.askId,
              formikBag,
            });
          }}
          render={({ isSubmitting, setFieldValue, values, ...formikBag }) => (
            <View style={[tw(`flex flex-wrap flex-col items-end`)]}>
              <Text
                style={[
                  fonts.style1,
                  {
                    paddingVertical: 5,
                    fontSize: 15,
                    color: colors.primary,
                    fontWeight: "bold",
                    textAlign: "right",
                  },
                ]}
                testID={"order_details_new_delivery_date_text"}
              >
                {Localise(locMessages, "New Delivery Date")}
              </Text>
              <Text>{moment(newDeliveryDate).format("MM/DD/YYYY")}</Text>
              <View
                style={[
                  tw(
                    `flex flex-row flex-wrap justify-end ${
                      !isSmallScreen ? "items-baseline" : ""
                    }`
                  ),
                ]}
              >
                {!(values?.isDenyClicked ?? false) && (
                  <SubmitButton
                    title={"Approve"}
                    isLoading={isSubmitting}
                    containerStyle={{ margin: 0, marginTop: 5 }}
                    buttonStyle={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                    }}
                    testID={"approve_date_change_request"}
                    accessibilityLabel={"approve_date_change_request"}
                  />
                )}
                {!isSubmitting && (
                  <Button
                    testID={"deny_date_change_request"}
                    accessibilityLabel={"deny_date_change_request"}
                    title={Localise(locMessages, "Deny")}
                    titleStyle={{
                      ...theme.Button.secondaryTitleStyle,
                      fontSize: 12,
                    }}
                    buttonStyle={{
                      ...theme.Button.secondaryButtonStyle,
                      paddingVertical: 5,
                      paddingHorizontal: 20,
                    }}
                    containerStyle={{
                      margin: 5,
                      justifyContent: "center",
                      marginRight: 0,
                    }}
                    loading={values?.isDenyClicked ?? false}
                    loadingProps={{
                      color: colors.highlighter,
                    }}
                    onPress={() => {
                      setFieldValue("isDenyClicked", true);
                      setShowRequestNewDateForm(false);
                      requestNewDateActions({
                        actionType: "delivery-date-deny",
                        askId: dateChangeInfo.askId,
                        formikBag: { ...formikBag, setFieldValue },
                      });
                    }}
                  />
                )}
              </View>
            </View>
          )}
        />
      )}
      <View style={[tw(`flex flex-col items-end pt-2`)]}>
        <Text
          style={[
            fonts.style1,
            {
              paddingVertical: 5,
              fontSize: 15,
              color: colors.primary,
              fontWeight: "bold",
            },
          ]}
          testID={"order_details_deliver_by_text"}
        >
          {Localise(locMessages, "Deliver By")}
        </Text>
        <Text>{deliverBy}</Text>
        {showDeliverByTimeChangeIcon && (
          <Tooltip
            text={
              Platform.OS === "web"
                ? Localise(
                    messages,
                    isPastDate
                      ? "Adjust Delivery Date to select time"
                      : "Click to change Delivery Time"
                  )
                : ""
            }
          >
            <TouchableOpacity
              style={deliverBy ? [tw("pt-1")] : {}}
              onPress={() => setShowDeliverByTimeForm(true)}
              testID="time_change_request"
              accessibilityLabel="time_change_request"
              disabled={isPastDate}
            >
              <Image
                style={{ width: 30, height: 30 }}
                resizeMode="cover"
                source={IMAGES["time-picker"]}
              />
            </TouchableOpacity>
          </Tooltip>
        )}
        {showDeliverByTimeForm && (
          <RequestNewDeliverByTime
            closeForm={setShowDeliverByTimeForm}
            prevOrderDetailsResponse={orderDetailResponse}
            path={deliveryInfoPath}
            triggerAction={triggerAction}
          />
        )}
      </View>
      <RouteWarning
        modalVisible={modalVisible}
        handlePrimary={handleContinue}
        modalText={`Order will stay in ${
          routeDetails?.route?.routeName || "Route"
        } for delivery on ${moment(deliveryDate).format(
          "MM/DD/YYYY"
        )}. Please move to new route if needed.`}
      />
    </View>
  );
};

const RequestNewDeliverByTime = ({
  closeForm,
  prevOrderDetailsResponse,
  path,
  triggerAction,
}) => {
  const { messages, Localise } = useContext(I18NContext);
  const orderItemId = useSelector(selectOrderItemId) || "";
  const actualDeliveryMethod = useSelector(selectActualDeliveryMethod) || "";

  const {
    deliveryDate: prevDeliveryDate,
    orderDeliveryTime: prevDeliverByTime,
    deliveryInstructions: prevDeliveryInstructions = "",
  } = get(prevOrderDetailsResponse, path, "");

  const onSubmit = (newDeliverByTime) => {
    const updatedDeliverByTime = moment(newDeliverByTime).format("h:mm A");
    let updatedOrderDetailsResponse = cloneDeep(prevOrderDetailsResponse);

    let updatedDeliveryInstructions = "";

    if (!prevDeliveryInstructions.match(DELIVER_BY_REGEX)) {
      //If the order is being modified from non-timed to timed
      updatedDeliveryInstructions = `Deliver by ${updatedDeliverByTime}.${
        prevDeliveryInstructions ? `\n${prevDeliveryInstructions?.trim()}` : ""
      }`;
    } else {
      updatedDeliveryInstructions = prevDeliveryInstructions?.replace(
        `Deliver by ${prevDeliverByTime}.`,
        `Deliver by ${updatedDeliverByTime}.`
      );
    }
    set(
      updatedOrderDetailsResponse,
      `${path}.deliveryInstructions`,
      updatedDeliveryInstructions
    );

    set(
      updatedOrderDetailsResponse,
      `${path}.orderDeliveryTime`,
      updatedDeliverByTime
    );

    const orderUpdates = generateJSONPatch(
      prevOrderDetailsResponse,
      updatedOrderDetailsResponse
    );

    request("modify-order", {
      recordId: orderItemId,
      deliveryMethod: actualDeliveryMethod,
      orderUpdates,
    })
      .then(() => {
        closeForm(false);
        triggerAction({ action: "update-deliver-by-time" });
      })
      .catch(() => {
        closeForm(false);
        ToasterHandler(
          "uh oh",
          Localise(messages, "Something went wrong. Please try again!")
        );
      });
  };

  return (
    <Form
      initialValues={{
        newDeliverByTime: "",
      }}
      onSubmit={(values, formikBag) => {
        if (formikBag.isSubmitting) return;
        onSubmit(values.newDeliverByTime);
      }}
      render={({ values, isSubmitting, setFieldValue }) => {
        const { newDeliverByTime } = values;
        const showSubmitButton =
          newDeliverByTime.length > 0 &&
          moment(newDeliverByTime).format("h:mm A") !== prevDeliverByTime;

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (newDeliverByTime) {
            const time = moment(newDeliverByTime, "YYYY-MM-DDTHH:mm:ss").format(
              "HH:mm:ss"
            );
            const formattedDateTime = `${moment(prevDeliveryDate).format(
              "YYYY-MM-DD"
            )}T${time}`;
            if (moment().isAfter(formattedDateTime))
              setFieldValue("newDeliverByTime", "");
          }
        }, [newDeliverByTime]);

        return (
          <>
            <FormFieldDatePicker
              label={Localise(messages, "New Deliver By Time")}
              dateFormat="h:mm a"
              name={"newDeliverByTime"}
              placeholder={"Time"}
              containerStyle={{
                zIndex: 20,
                marginTop: 10,
              }}
              labelStyle={{
                ...fonts.default,
                fontSize: 15,
                fontWeight: "bold",
                textAlign: "right",
              }}
              inputContainerStyle={{ maxWidth: 110 }}
              customInputContainerStyle={{
                ...tw(`items-end`),
                paddingHorizontal: 0,
              }}
              alignIconRight={true}
              timeIntervals={30}
              iconName="clock"
              errorStyle={{ paddingBottom: 0 }}
              renderErrorMessage={false}
              showTimeSelect={true}
              showTimeSelectOnly={true}
              minTime={
                moment(prevDeliveryDate).isSame(moment(), "day")
                  ? moment().toDate()
                  : moment().startOf("day").toDate()
              }
              maxTime={moment().endOf("day").toDate()}
              filterTime={() =>
                !moment(prevDeliveryDate).isBefore(moment(), "day")
              }
              popperPlacement={"bottom"}
              popperModifiersOverride={{
                offset: {
                  enabled: true,
                  offset: "-40px, 3px",
                },
              }}
            />
            <View style={tw(`flex flex-row flex-wrap justify-end`)}>
              {showSubmitButton && (
                <SubmitButton
                  title={"Update"}
                  isLoading={isSubmitting}
                  containerStyle={{ margin: 0, marginTop: 5 }}
                  buttonStyle={{
                    paddingVertical: 5,
                    paddingHorizontal: 12,
                  }}
                  testID={"submit_time_change_request"}
                  accessibilityLabel={"submit_time_change_request"}
                />
              )}
              <Button
                testID={"cancel_time_change_request"}
                accessibilityLabel={"cancel_time_change_request"}
                title={Localise(messages, "Cancel")}
                titleStyle={{
                  ...theme.Button.secondaryTitleStyle,
                  fontSize: 12,
                }}
                buttonStyle={{
                  ...theme.Button.secondaryButtonStyle,
                  paddingVertical: 5,
                  paddingHorizontal: 15,
                }}
                containerStyle={{
                  margin: 5,
                  justifyContent: "center",
                  marginRight: 0,
                }}
                onPress={() => closeForm(false)}
              />
            </View>
          </>
        );
      }}
    />
  );
};

const RouteWarning = ({ modalVisible, handlePrimary, modalText = "" }) => {
  const { Localise, messages } = React.useContext(I18NContext);

  const modalContent = {
    content: (
      <View>
        <Text
          style={{
            ...tw("underline text-center"),
            fontSize: 16,
            color: colors.highlighter,
          }}
        >
          {Localise(messages, "Notice")}
        </Text>
        <Text
          style={{
            ...tw("p-4"),
            fontSize: 15,
            color: colors.highlighter,
            textAlign: "center",
          }}
        >
          {Localise(messages, modalText)}
        </Text>
      </View>
    ),
    buttons: [{ type: "primary", title: Localise(messages, "Continue") }],
  };

  return (
    <CustomModal
      modalVisible={modalVisible}
      modalContent={modalContent}
      primaryhandler={handlePrimary}
      contentStyle={[
        tw("border border-black p-4"),
        { backgroundColor: "white" },
      ]}
      modalStyle={
        Platform.OS !== "web"
          ? {
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              backgroundColor: "#00000070",
              color: "#FFFFFF",
            }
          : {
              width: "35%",
            }
      }
    />
  );
};

export default RequestDateChange;
