import React, { useContext, useMemo } from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";
import { useNavigation, CommonActions } from "@react-navigation/native";
import tw from "tailwind-rn";
import { fonts } from "styles/theme";
import useStateIfMounted from "library/utils/useStateIfMounted";
import UserProfileStorage from "library/storage/userProfile";
import I18NContext from "library/contexts/i18N";
import {
  selectActiveTab,
  selectDSEligibilityFailureMessage,
} from "library/sagas/ongoing/current-orders/selector";
import { selectRouteDetails } from "library/sagas/ongoing/order-details/selector";
import { setSelectScreenKey } from "library/sagas/views/home/drawer/delivery/slice";
import { setCurrentPage } from "library/sagas/ongoing/global-data/slice";
import { hasDeliveryZone } from "library/utils/featureAvailability";
import { Maps, DeliveryZone } from "components/elements";
import { generateDeliveryInfoContent } from "../helper";

import RecipientInfo from "./recipient-info";
import RequestDateChange from "./request-date-change";
import { isFloristDelivered, isIncomingWiredOrder } from "./helper";

const RecipientDateInfo = ({
  index,
  recordId,
  isSmallScreen,
  orderDetails,
  triggerRequest,
  triggerAction,
  supportDateChange,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const currentTab = useSelector(selectActiveTab);
  const dsEligibilityFailureDetails = useSelector(
    selectDSEligibilityFailureMessage
  );
  const routeDetails = useSelector(selectRouteDetails);
  const dsEligibilityFailureMessage = Object.values(dsEligibilityFailureDetails)
    ?.filter(Boolean)
    ?.join("\n");
  const [mapAccordionStatus, setMapAccordionStatus] = useStateIfMounted(false);
  const { messages: locMessages, Localise } = useContext(I18NContext);

  const {
    orderItemId = "",
    direction = "",
    messages = [],
    receivingMember: {
      latitude: fillerLatitude = "",
      longitude: fillerLongitude = "",
      memberCode: receivingMemberCode = "",
    } = {},
    recipientInfo: {
      addressLine1,
      latitude: recipientLatitude = "",
      longitude: recipientLongitude = "",
    } = {},
    deliveryInfo: { deliveryMethod, routeId },
  } = orderDetails;

  const hasDSSubmittedMsg = !!messages.find(
    (e) =>
      e.messageType === "OLC" &&
      e.comments?.includes("Delivery Service Requested")
  );
  const showDSRequestsLink = direction === "INBOUND" && hasDSSubmittedMsg;

  const fillerLocation = {
    locations: [
      {
        latitude: parseFloat(fillerLatitude || 0),
        longitude: parseFloat(fillerLongitude || 0),
        isFillingFlorist: true,
      },
    ],
  };

  const viewDSRequests = () => {
    dispatch(setSelectScreenKey("DS"));
    UserProfileStorage.setCurrentTab("DS");
    dispatch(setCurrentPage("DeliveryDashboard"));
    navigation.dispatch(
      CommonActions.navigate({
        name: "lmds-delivery",
        params: {
          referenceNumber: orderItemId,
          shopCode: receivingMemberCode,
          applyParamFilters: true,
          search: {
            title: orderItemId,
            value: orderItemId,
          },
        },
      })
    );
  };

  const showDeliveryZone = useMemo(
    () =>
      hasDeliveryZone({
        shopCode: receivingMemberCode,
        deliveryMethod,
        direction,
        addressLine1,
      }),
    [receivingMemberCode, deliveryMethod, direction, addressLine1]
  );

  const showRouteName =
    isFloristDelivered(deliveryMethod) ||
    isIncomingWiredOrder(deliveryMethod ?? "", direction);

  return (
    <View style={[tw("flex flex-col"), { zIndex: 6 }]}>
      <View style={tw("flex flex-row w-full")}>
        <View style={tw("w-1/2 flex items-start")}>
          <RecipientInfo
            {...{
              index,
              recordId,
              isSmallScreen,
              orderDetails,
              mapAccordionStatus,
              setMapAccordionStatus,
            }}
          />
        </View>
        <View style={tw("w-1/2 flex items-end")}>
          <RequestDateChange
            {...{
              index,
              triggerRequest,
              triggerAction,
              supportDateChange,
              isSmallScreen,
            }}
          />
          {/* Delivery Zone Name */}
          {showDeliveryZone ? (
            <DeliveryZone
              {...{
                memberCode: receivingMemberCode,
                index,
                orderDetailsPage: true,
                customStyle: [
                  { fontSize: 14, paddingLeft: 0, paddingVertical: 2 },
                ],
                isVertical: true,
                zIndex: -2,
              }}
            />
          ) : null}
          {showRouteName &&
            generateDeliveryInfoContent({
              label: "Route Name",
              name:
                routeId === routeDetails?.route?.routeId
                  ? routeDetails?.route?.routeName || ""
                  : "",
              zIndex: -3,
            })}
        </View>
      </View>

      {/* View Delivery Service Requests */}
      {currentTab === "delivery" && showDSRequestsLink ? (
        <Text
          testID={"view_linked_DS_requests"}
          accessibilityLabel={"view_linked_DS_requests"}
          style={[
            fonts.link1,
            tw("my-2"),
            { textDecorationLine: "underline", width: 300 },
          ]}
          onPress={viewDSRequests}
        >
          {Localise(locMessages, "View Delivery Service Requests")}
        </Text>
      ) : null}

      {currentTab === "delivery" && dsEligibilityFailureMessage ? (
        <View style={[tw("flex items-start mt-2 pr-4"), { zIndex: -1 }]}>
          <Text
            style={{
              fontSize: 14,
            }}
          >
            {Localise(
              locMessages,
              "This order is not eligible for Delivery Service due to"
            ) + ":"}
          </Text>
          <Text
            style={{
              ...tw("text-left"),
              padding: 5,
              fontSize: 14,
            }}
          >
            {dsEligibilityFailureMessage}
          </Text>
        </View>
      ) : null}

      {/* Map View */}
      {mapAccordionStatus && (
        <View style={tw("py-3")}>
          <Maps
            mapMarkers={{
              ...fillerLocation,
              ...{
                recipientLatLong: {
                  recipientLatitude: parseFloat(recipientLatitude),
                  recipientLongitude: parseFloat(recipientLongitude),
                },
              },
            }}
            zoomLevel={8}
          />
        </View>
      )}
    </View>
  );
};

export default RecipientDateInfo;
