import UserProfileStorage from "library/storage/userProfile";

export const Entitlements = Object.freeze({
  DASHBOARD: "DASHBOARD",
  DASHBOARD_PAGE: {
    AOV: "AOV",
    TOV: "TOV",
  },
  CATALOG_MANAGEMENT: "CATALOG MANAGEMENT",
  CREATE_ORDER: "CREATE ORDER",
  CREATE_ORDER_PAGE: {
    CUSTOMER_SEARCH: "CUSTOMER SEARCH",
    DM_WALK_IN: "DELIVERY METHOD WALK IN",
    DM_PICKUP: "DELIVERY METHOD PICKUP",
    DM_WIRE_OUT: "DELIVERY METHOD WIRE OUT",
    DM_FLORIST_DELIVERED: "DELIVERY METHOD FLORIST DELIVERED",
    DM_PHONE_OUT: "DELIVERY METHOD PHONE OUT",
    PAYMENT_SECTION: "PAYMENT SECTION",
    FUNERAL_LOG: "FUNERAL LOG",
    SPLIT_PAYMENT: "SPLIT PAYMENT",
    TIMED_DELIVERY: "TIMED DELIVERY",
    ORDER_NOTES: "ORDER NOTES",
  },
  ORDERS: "ORDERS",
  ORDERS_PAGE: {
    DESIGNER: "DESIGNER",
    FOLLOWUP: "FOLLOWUP",
  },
  STAFF_MANAGEMENT: "STAFF MANAGEMENT",
  MERCURY_SETTINGS: "MERCURY SETTINGS",
  SHOP_SETTINGS: "SHOP SETTINGS",
  SHOP_SETTINGS_PAGE: {
    QUICK_BOOKS: "QUICK BOOKS",
    PT_PAY_LATER: "PAYMENT TYPE PAY LATER",
  },
  CUSTOMIZE_PUSH_NOTIFICATIONS: "CUSTOMIZED PUSH NOTIFICATIONS",
  DELIVERY_SERVICE: "DELIVERY SERVICE",
  DELIVERY_TASKS: "DELIVERY TASKS",
  DELIVERY_TASKS_PAGE: {
    CREATE_DS_REQUEST: "CREATE DELIVERY SERVICE REQUEST",
    BULK_IMPORT: "BULK IMPORT",
  },
  DELIVERY: "LMDS DELIVERY",
  DELIVERY_PAGE: {
    ROUTE_OPTIMIZATION: "OPTIMIZATION",
    ROUTE_CONSIDER_LOCAL_ORDERS: "CONSIDER LOCAL ORDERS",
    ROUTE_ADHOC_STOPS: "ADHOC STOPS",
    ROUTE_PRINT_MANIFEST: "PRINT MANIFEST",
    ROUTE_TBT_DIRECTIONS: "TURN BY TURN DIRECTIONS",
    ROUTE_NON_ROUTED_ORDERS: "NON ROUTED ORDERS",
    ROUTE_STOPS_REORDER: "REORDER",
    EDIT_DRIVER: "EDIT DRIVER",
    ROUTE_REMOVE_STOP: "REMOVE ROUTE",
    ROUTE_MAP_ADD_ROUTE: "MAP ADD ROUTE",
    ROUTE_MAP_REMOVE_ROUTE: "MAP REMOVE ROUTE",
    ROUTE_MAP_DRAW_TOOL: "MAP DRAW TOOL",
    ROUTE_MULTISHOP_OWNERSHIP: "MULTISHOP OWNERSHIP",
    ROUTE_CFR_OPTIMIZATION: "CFR OPTIMIZATION",
    QR_SCAN: "QR SCAN",
  },
  ROUTES: "LMDS ROUTES",
  ROUTES_PAGE: {
    ROUTE_OPTIMIZATION: "OPTIMIZATION",
    ROUTE_ADHOC_STOPS: "ADHOC STOPS",
    ROUTE_TBT_DIRECTIONS: "TURN BY TURN DIRECTIONS",
    ROUTE_STOPS_REORDER: "REORDER",
    ROUTE_MULTISHOP_OWNERSHIP: "MULTISHOP OWNERSHIP",
    ROUTE_CFR_OPTIMIZATION: "CFR OPTIMIZATION",
    QR_SCAN: "QR SCAN",
    EDIT_ROUTE: "EDIT ROUTE",
  },
  DRIVER_DASHBOARD: "LMDS DRIVER DASHBOARD",
  DRIVER_DASHBOARD_PAGE: {
    CREATE_ROUTE: "CREATE ROUTE",
    EDIT_ROUTE: "EDIT ROUTE",
    ROUTE_REMOVE_STOP: "REMOVE ROUTE",
  },
  DELIVERY_DASHBOARD: "DELIVERY DASHBOARD",
  ROUTES_TAB: "ROUTES TAB",
  LMDS_DS_MODIFY: "LMDS DS MODIFY",
  LMDS_APPROVE_DS_CREDIT: "LMDS APPROVE DS CREDIT",
  REPORTS: "REPORTS",
  REPORTS_PAGE: {
    ORDER_SUMMARY: "ORDER SUMMARY",
    CUSTOMER_DIRECTORY: "CUSTOMER DIRECTORY REPORT",
    ORDER_DETAIL: "ORDER DETAIL REPORT",
    HOUSE_ACCOUNT_STATEMENTS: "HOUSE ACCOUNT STATEMENTS",
    SALES_TAX_REPORTS: "SALES TAX",
    SALES_BY_PROMO_CODE: "SALES BY PROMO CODE",
    PRODUCT_SALES: "PRODUCT SALES REPORT",
    SALES_SUMMARY: "SALES SUMMARY REPORT",
    PRODUCT_COMPARISON: "PRODUCT COMPARISON REPORT",
    AGED_ANALYSIS_REPORT: "AGED ANALYSIS REPORT",
    ADVANCE_OPTIMIZATION_USAGE_REPORT: "ADVANCED ROUTE OPTIMIZE USAGE REPORT",
    CREDIT_CARD_SETTLEMENT_REPORT: "CREDIT CARD SETTLEMENT REPORT",
    BUSINESS_SNAPSHOT_REPORT: "BUSINESS SNAPSHOT REPORT",
    BUSINESS_SNAPSHOT_REPORT_BY_TYPE: "BUSINESS SNAPSHOT REPORT BY TYPE",
  },
  MERCURY_ONLINE: "MERCURY ONLINE",
  PRODUCT_CATALOG: "PRODUCT CATALOG",
  PRODUCT_CATALOG_PAGE: {
    MY_CATALOG: "MY CATALOG",
    ADDONS: "ADDONS",
    FTD_CATALOG: "FTD CATALOG",
    COLLECTIONS: "COLLECTIONS",
  },
  CUSTOMER_DIRECTORY: "CUSTOMER DIRECTORY",
  GIFT_CARDS: "GIFT CARDS",
  GIFT_CARDS_PAGE: {
    CREATE_GIFT_CARD: "CREATE GIFT CARD",
  },
  MOL_REFUNDS: "MERCURY ONLINE REFUNDS",
  HELP: "HELP",
  CREATE_EVENT: "CREATE EVENT",
  CREATE_EVENT_PAGE: {
    SPLIT_PAYMENT: "SPLIT PAYMENT",
  },
  EVENTS: "EVENTS",
  QR_SCAN: "QR SCAN",
  HOUSE_ACCOUNT: "HOUSE ACCOUNT",
  HOUSE_ACCOUNT_PERMISSIONS: {
    LATE_FEE: "LATE FEE",
    BALANCE_FORWARD_ACCOUNTING: "BALANCE FORWARD ACCOUNTING",
    OPEN_ITEM_ACCOUNTING: "OPEN ITEM ACCOUNTING",
  },
  ADDON_SUBSCRIPTIONS: "A LA CARTE MEMBER SUBSCRIPTIONS",
  DRAFT_ORDERS: "DRAFT ORDERS",
  OCCASION_REMINDERS: "OCCASION REMINDERS",
  OCCASION_REMINDER_PERMISSIONS: {
    BIRTHDAY_REMINDER: "BIRTHDAY REMINDER",
    ANNIVERSARY_REMINDER: "ANNIVERSARY REMINDER",
    EMAIL_MARKETING_NOTIFICATION: "EMAIL MARKETING NOTIFICATION",
  },
  PROMO_CODE: "PROMO CODE",
});

export const memberEntitlements = Object.freeze({
  MHQ_STANDARD: "MHQ STANDARD",
  MHQ_ESSENTIALS: "MHQ ESSENTIALS",
  MERCURY_ONLINE: "MERCURY ONLINE",
  MHQ_CORE: "MHQ CORE",
  MHQ_PREMIUM: "MHQ PREMIUM",
  MHQ_PREMIUM_MERCURY_ONLINE: "MHQ PREMIUM + MERCURY ONLINE",
  MHQ_STANDARD_MERCURY_ONLINE: "MHQ STANDARD + MERCURY ONLINE",
  MHQ_ESSENTIALS_MERCURY_ONLINE: "MHQ ESSENTIALS + MERCURY ONLINE",
  MHQ_CORE_MERCURY_ONLINE: "MHQ CORE + MERCURY ONLINE",
  DELIVERY_SERVICE: "DELIVERY SERVICE",
  MHQ_CONN: "MHQ CONNECT",
  MHQ_CONN_MERCURY_ONLINE: "MHQ CONNECT + MERCURY ONLINE",
});

/**
 * We can use this function to know whether a given member code is standard/premium or not -- we are using it in MTAC screen
 *
 * @param entitlements - member code entitlements
 * @returns - true if member code entitlement is MHQ STANDARD, MHQ STANDARD + MERCURY ONLINE or MHQ PREMIUM
 */
export const isMHQNonCoreUser = (entitlements) => {
  const indexOfNonCoreEntitlement = entitlements.findIndex(
    (entitlement) =>
      entitlement.includes(memberEntitlements.MHQ_STANDARD) ||
      entitlement.includes(memberEntitlements.MHQ_PREMIUM) ||
      entitlement.includes(memberEntitlements.MHQ_ESSENTIALS)
  );

  return indexOfNonCoreEntitlement >= 0 ? true : false;
};

export const isMHQNonCoreMember = (memberCode) => {
  if (memberCode === "") return false;

  if (memberCode === "all") {
    const premiumMembers = UserProfileStorage.getEntitlementAllMembers(
      memberEntitlements.MHQ_PREMIUM
    );

    const standardMembers = UserProfileStorage.getEntitlementAllMembers(
      memberEntitlements.MHQ_STANDARD
    );

    const essentialsMembers = UserProfileStorage.getEntitlementAllMembers(
      memberEntitlements.MHQ_ESSENTIALS
    );

    return (
      premiumMembers.length > 0 ||
      standardMembers.length > 0 ||
      essentialsMembers.length > 0 ||
      false
    );
  } else {
    const entitlement = UserProfileStorage.getMemberEntitlement(memberCode);

    return (
      entitlement.includes(memberEntitlements.MHQ_STANDARD) ||
      entitlement.includes(memberEntitlements.MHQ_PREMIUM) ||
      entitlement.includes(memberEntitlements.MHQ_ESSENTIALS)
    );
  }
};

export const isCoreMember = (memberCode) => {
  if (memberCode === "") return false;

  if (memberCode === "all") {
    const coreMemberCodes = UserProfileStorage.getEntitlementAllMembers(
      memberEntitlements.MHQ_CORE
    );

    return coreMemberCodes.length > 0 || false;
  } else {
    const entitlement = UserProfileStorage.getMemberEntitlement(memberCode);

    return entitlement.includes(memberEntitlements.MHQ_CORE) ?? false;
  }
};

export const isMOLOnlyMember = (memberCode) => {
  const entitlement = UserProfileStorage.getMemberEntitlement(memberCode);
  return entitlement === memberEntitlements.MERCURY_ONLINE ?? false;
};

export const isStandardMember = (memberCode) => {
  if (memberCode === "") return false;

  if (memberCode === "all") {
    const standardMemberCodes = UserProfileStorage.getEntitlementAllMembers(
      memberEntitlements.MHQ_STANDARD
    );

    const essentialsMembers = UserProfileStorage.getEntitlementAllMembers(
      memberEntitlements.MHQ_ESSENTIALS
    );

    return (
      standardMemberCodes.length > 0 || essentialsMembers.length > 0 || false
    );
  } else {
    const entitlement = UserProfileStorage.getMemberEntitlement(memberCode);

    return (
      entitlement.includes(memberEntitlements.MHQ_STANDARD) ||
      entitlement.includes(memberEntitlements.MHQ_ESSENTIALS)
    );
  }
};

export const isPremiumMember = (memberCode) => {
  if (memberCode === "") return false;

  if (memberCode === "all") {
    const premiumMemberCodes = UserProfileStorage.getEntitlementAllMembers(
      memberEntitlements.MHQ_PREMIUM
    );

    return premiumMemberCodes.length > 0 || false;
  } else {
    const entitlement = UserProfileStorage.getMemberEntitlement(memberCode);

    return entitlement.includes(memberEntitlements.MHQ_PREMIUM) ?? false;
  }
};
export const isCoreConnectMember = (memberCode) => {
  if (memberCode === "") return false;

  if (memberCode === "all") {
    const coreMemberCodes = UserProfileStorage.getEntitlementAllMembers(
      memberEntitlements.MHQ_CONN
    );

    return coreMemberCodes.length > 0 || false;
  } else {
    const entitlement = UserProfileStorage.getMemberEntitlement(memberCode);

    return entitlement.includes(memberEntitlements.MHQ_CONN) ?? false;
  }
};

export default {
  Entitlements,
  memberEntitlements,
  isMHQNonCoreUser,
  isMHQNonCoreMember,
  isCoreMember,
  isStandardMember,
  isPremiumMember,
  isCoreConnectMember,
  isMOLOnlyMember,
};
