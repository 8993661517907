import React, { memo, useContext } from "react";
import { Text } from "react-native-elements";
import { Platform } from "react-native";
import { useDispatch } from "react-redux";
import { createStyles } from "react-native-media-queries";
import { CustomModal } from "components/elements";
import tw from "tailwind-rn";
import I18NContext from "library/contexts/i18N";
import { colors, backgroundColors } from "styles/theme";
import { setReOpenWarnDetails } from "library/sagas/ongoing/current-orders/slice";

const ReopenModal = ({ reopenWarnDetails, setFieldValue = () => {} }) => {
  const dispatch = useDispatch();
  const { messages, Localise } = useContext(I18NContext);
  const {
    showModal = false,
    action = "",
    resolve = () => {},
  } = reopenWarnDetails;

  const reopenStatusMap = {
    accept: "Accepted",
    design: "In Design",
    designed: "Designed",
    "out-for-delivery": "Delivery",
  };

  const newOrderStatus = reopenStatusMap[action] || "";

  return (
    <CustomModal
      modalVisible={showModal}
      modalContent={{
        content: (
          <Text
            style={{ ...tw("p-4"), fontSize: 15, color: colors.highlighter }}
          >
            {Localise(
              messages,
              `Reopen this order? Status will change to '${newOrderStatus}', allowing you to resume processing and fulfillment.`
            )}
          </Text>
        ),
        buttons: [
          { type: "secondary", title: Localise(messages, "Cancel") },
          { type: "primary", title: Localise(messages, "Continue") },
        ],
      }}
      primaryhandler={() => {
        resolve();
        dispatch(setReOpenWarnDetails({ showModal: false }));
        setFieldValue("actionItem", "");
      }}
      secondaryhandler={() => {
        dispatch(setReOpenWarnDetails({ showModal: false }));
        setFieldValue("actionItem", "");
      }}
      contentStyle={[
        tw("border border-black p-2"),
        {
          backgroundColor: backgroundColors.secondary,
          textAlign: "center",
        },
      ]}
      modalStyle={styles.modalStyle}
    />
  );
};

export default memo(ReopenModal);

const styles = createStyles({
  modalStyle:
    Platform.OS !== "web"
      ? {
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          backgroundColor: "#00000070",
          color: "#FFFFFF",
        }
      : { width: "35%" },
});
