import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { isDSorder } from "library/utils/orderListing";

export const orderStatuses = [
  "Order Received",
  "In Progress",
  "Out for Delivery",
  "Delivered",
  "Canceled",
];

export const statusesBGColors = {
  "Order Received": "#e2f7ee80",
  "In Progress": "#23a5871a",
  "Out for Delivery": "#037c771a",
  "Attempted Delivery": "#e5175e1a",
  Delivered: "#e5f3f9",
  Canceled: "#c4c4c4",
};

export const olcMessagesFilterKeywords = {
  "Order Received": [
    "Local order created",
    "Received Order from Mercury Network",
    "Sent Order to Mercury Network",
    "Phone Out order created",
  ],
  "In Progress": ["Accepted"],
  "Out for Delivery": [
    "Out For Delivery",
    "Ready for Pickup",
    "Driver Picked Up Delivery",
    "Delivery in Progress",
  ],
  Delivered: ["Order is picked up", "Delivered"],
  "Attempted Delivery": ["Returned by Delivery Service"],
  Canceled: ["Customer Cancelled the Order", "Other"],
};

export const eventTypes = {
  "Attempted Delivery": "Attempted Delivery",
  Delivered: "Delivered",
};

export const getOrderCurrentStatus = (orderItem = {}) => {
  const { status = "" } = orderItem;

  if (["NEW", "SENT"].includes(status)) return "Order Received";

  if (
    [
      "ACKNOWLEDGED",
      "ACKNOWLEDGE_PRINT",
      "DESIGN",
      "DESIGNED",
      "DS_REQUESTED",
      "REJECTED",
    ].includes(status)
  )
    return "In Progress";

  if (status === "OUT_FOR_DELIVERY") return "Out for Delivery";

  if (["DELIVERED"].includes(status)) return "Delivered";

  if (status === "CANCELLED") return "Canceled";
};

export const filterMessages = (
  messages,
  keywords = [],
  messageType = "OLC"
) => {
  return messages.filter(
    (message) =>
      message.messageType === messageType &&
      keywords.some((keyword) =>
        message.comments?.toLowerCase().includes(keyword.toLowerCase())
      )
  );
};

export const filterEvents = (events = [], eventType) => {
  return events.filter((event) => event.type === eventType);
};

export const isOrderDeliveryAttempted = (events = [], messages = []) => {
  /* marked Attempted Delivery from MHQ Orders page */
  const userDeliveryAttempts = filterEvents(
    events,
    eventTypes["Attempted Delivery"]
  );
  /* Attempted Delivery by DS provider */
  const DSDeliveryAttempts = filterMessages(
    messages,
    olcMessagesFilterKeywords["Attempted Delivery"],
    "OLC"
  );

  const allDeliveryAttemptedEvents = [
    ...userDeliveryAttempts,
    ...DSDeliveryAttempts,
  ];

  return allDeliveryAttemptedEvents.length > 0;
};

export const fetchAllDeliveryAttempts = (
  events = [],
  messages = [],
  getSourceOfLatest = false
) => {
  /* marked Attempted Delivery from MHQ Orders page */
  const userDeliveryAttempts = filterEvents(
    events,
    eventTypes["Attempted Delivery"]
  );
  /* Attempted Delivery by DS provider */
  const DSattemptedDeliveryMessages = filterMessages(
    messages,
    olcMessagesFilterKeywords["Attempted Delivery"],
    "OLC"
  );
  const DSDeliveryAttempts = DSattemptedDeliveryMessages.map((each) => {
    return {
      ...each,
      notes: "Delivery Exception", // Default Driver Notes
      source: "DS",
    };
  });

  const allDeliveryAttemptedEvents = [
    ...userDeliveryAttempts,
    ...DSDeliveryAttempts,
  ].sort((e1, e2) => new Date(e1.createdOn) - new Date(e2.createdOn));

  if (getSourceOfLatest) {
    return (
      allDeliveryAttemptedEvents[allDeliveryAttemptedEvents.length - 1]
        ?.source || ""
    );
  }

  return allDeliveryAttemptedEvents;
};

export const getFormattedMessage = (
  message = {},
  currentStatusIndex,
  status,
  isPickupOrder = false
) => {
  const { createdOn = "", comments = "" } = message;

  return {
    date: createdOn ? moment(createdOn).format("MMM DD, YYYY") : "",
    comments:
      currentStatusIndex > 2
        ? status === "Delivered"
          ? `${isPickupOrder ? "Picked up" : "Delivered"} at ${moment(
              createdOn
            ).format("hh:mm A")}`
          : comments
        : "",
  };
};

export const getFormattedEvent = (event = {}, deliveryTime) => {
  const { type, createdOn = "", notes = "" } = event;

  return {
    date: createdOn ? moment(createdOn).format("MMM DD, YYYY") : "",
    comments:
      type === eventTypes["Delivered"]
        ? `Delivered at ${
            deliveryTime ? deliveryTime : moment(createdOn).format("hh:mm A")
          }`
        : `Driver Note: ${notes}`,
  };
};

export const getStatusLabel = (status, isPickupOrder) => {
  if (isPickupOrder) {
    return status === "Out for Delivery"
      ? "Ready for Pickup"
      : status === "Delivered"
      ? "Picked up"
      : status;
  } else {
    return status;
  }
};

export const prepareOrderStatusesData = (orderType, orderItem, events) => {
  let orderStatusesData = [];

  const {
    messages = [],
    deliveryInfo: {
      deliveryMethod = "",
      deliveryTime = "",
      operator = "",
    } = {},
    latestDSRequestedStatus = "",
    status: respStatus,
  } = orderItem;

  const orderStatus = getOrderCurrentStatus(orderItem);

  const isPickupOrder = [
    "STORE_PICKUP",
    "MOL_CUSTOMER_PICKUP",
    "PARTNER_STORE_PICKUP",
  ].includes(deliveryMethod);

  const orderStatusIndex = orderStatuses.indexOf(orderStatus);

  orderStatuses.map((status, index) => {
    if (status === "Canceled" && orderStatus !== "Canceled") return;

    if (index <= orderStatusIndex) {
      if (
        !isPickupOrder &&
        status === "Delivered" &&
        !isDSorder(latestDSRequestedStatus, respStatus) &&
        operator !== "DeliveryService"
      ) {
        // Delivered through DCON(not DS) - get details from events
        const filteredEvent = filterEvents(events, eventTypes["Delivered"]);

        orderStatusesData[index] = {
          status,
          active: !isEmpty(filteredEvent),
          ...getFormattedEvent(filteredEvent?.[0], deliveryTime),
        };
      } else {
        let filteredMessages =
          filterMessages(
            messages,
            olcMessagesFilterKeywords[status],
            status !== "Canceled"
              ? "OLC"
              : orderType === "Local"
              ? "CAN"
              : "Cancel"
          ) || [];

        // To mark "Out for Delivery" active with DCONdate if order Delivered
        if (
          orderStatus === "Delivered" &&
          status === "Out for Delivery" &&
          isEmpty(filteredMessages)
        ) {
          const deliveredEvent = filterEvents(events, eventTypes["Delivered"]);

          filteredMessages = [
            { createdOn: deliveredEvent[0]?.createdOn || "" },
          ];
        }

        orderStatusesData[index] = {
          status: getStatusLabel(status, isPickupOrder),
          active: !isEmpty(filteredMessages),
          ...getFormattedMessage(
            filteredMessages?.[filteredMessages?.length - 1],
            index,
            status,
            isPickupOrder
          ),
        };
      }
    } else {
      orderStatusesData[index] = {
        status: getStatusLabel(status, isPickupOrder),
        active: false,
      };
    }
  });

  if (!isPickupOrder) {
    const allDeliveryAttemptEvents = fetchAllDeliveryAttempts(events, messages);
    if (allDeliveryAttemptEvents.length > 0) {
      const allAttemptedDeliveries = [];

      allDeliveryAttemptEvents.map((deliveryAttemptEvent, index) => {
        allAttemptedDeliveries[index] = {
          status: "Attempted Delivery",
          active: true,
          ...getFormattedEvent(deliveryAttemptEvent),
        };
      });

      orderStatusesData.splice(3, 0, ...allAttemptedDeliveries);
    }
  }

  return orderStatusesData;
};

const getbase64Uri = (byteArray) => `data:image/png;base64,${byteArray}`;

export const getImageUriConfig = ({ trackingDetails }) => {
  const {
    designedImageUrls = [],
    deliveryImages = [],
    signatureImages = [],
  } = trackingDetails;
  const [designedImageUrl] = designedImageUrls;
  const { photo = "", signature = "" } = trackingDetails?.events?.[0] || {};

  return {
    designed_photo: designedImageUrl,
    delivery_photo: getbase64Uri(photo),
    delivery_signature: getbase64Uri(signature),
    ds_delivery_photo: deliveryImages[0],
    ds_delivery_signature: getbase64Uri(signatureImages[0]),
  };
};
