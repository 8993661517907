import Environment from "library/utils/environment";
import moment from "moment";
import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import find from "lodash/find";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import sortBy from "lodash/sortBy";
import cloneDeep from "lodash/cloneDeep";
import toNumber from "lodash/toNumber";
import toUpper from "lodash/toUpper";
import lowerCase from "lodash/lowerCase";

import { orderStatusColorCodes } from "styles/theme";
import {
  getTimeLeftInMins,
  getPickupTimeLeftInMins,
} from "library/utils/datetime";

import UserProfileStorage from "library/storage/userProfile";
import { isCoreMember, isCoreConnectMember } from "library/utils/entitlements";
import AppSettingsStorage from "library/storage/appSettings";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";
import { capitalizeFirstLetter } from "library/utils/formatter";

export const processOrderResponse = (
  orders = [],
  init,
  listingType,
  isCustomRangeFilter
) => {
  const timeStamp = moment().utc().format();
  const orderCollection = cloneDeep(init);
  const originalData = isCustomRangeFilter ? [] : cloneDeep(orders);

  !isEmpty(orders) &&
    orders.map((order) => {
      const {
        createdOn,
        deliveryInfo: {
          deliveryDate,
          orderDeliveryTime = "",
          deliveryTime = "09:00 AM",
          occasion,
          deliveryInstructions,
          routeId,
          deliveryMethod,
          deliveryZoneId = "",
          deliveryZoneName = "",
          pickUpBy = "",
        },
        designer: designerInfo = { id: "", name: "" },
        direction,
        isPrinted,
        latestPendingRequestType,
        latestUnreadResponseStatus,
        latestUnreadResponseType,
        latestDeliveryDateRequestStatus,
        latestRequestedDeliveryDate,
        latestPriceRequestStatus,
        latestRequestedPrice,
        messageNumber,
        numOfPendingApproveRequests,
        numOfUnreadChatMessages,
        numOfUnreadStructuredResponses,
        orderItemId,
        omsOrderId,
        receivingMember,
        recipientInfo: {
          addressLine1 = "",
          addressLine2,
          firstName = "",
          lastName = "",
          city = "",
          state = "",
          zip,
          country,
          locationType,
          latitude,
          longitude,
        },
        addressVerificationInfo,
        sendingMember,
        status,
        hasFulfillmentError,
        hasRefundError,
        hasSettlementError,
        dsEligible = false,
        orderSource = "",
        pickupInfo: { storePickupDateTime = "" } = {},
        latestDSRequestedStatus = "",
        hasDSFulfillmentError = false,
        autoSendToDS,
        customerOrderId,
        orderChannel,
        isRushOrder = false,
        isDropShipOrder = false,
        hasPendingPayLaterPayment = false,
        giftCardType = "",
        customerName = "",
        isInvoiceDetailsPrinted,
        isTrifoldDetailsPrinted,
        isDesignerWorkSheetPrinted,
        notes = "",
        isFollowUp = false,
      } = order;
      const name = `${firstName}${firstName !== "" ? " " : ""}${lastName}`;

      const memberCodes = UserProfileStorage.getProfileMemberCodes();
      const memberTimeZones = UserProfileStorage.getAllShopTimeZones() || {};
      const shopHours = UserProfileStorage.getAllShopHours() || {};
      const fillerMemberCode =
        direction === "INBOUND" ? receivingMember?.memberCode : "";
      const isOutgoingOrder =
        (direction === "OUTBOUND" &&
          sendingMember &&
          memberCodes.includes(sendingMember.memberCode) &&
          sendingMember.memberCode !== receivingMember.memberCode) ||
        deliveryMethod == "PHONE_OUT";

      const sourceMemberCode =
        direction === "INBOUND"
          ? receivingMember?.memberCode
          : sendingMember?.memberCode;

      const pickUpDateTime = moment(storePickupDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
      const isPickupForPayLaterPast =
        moment(currentDateTime).isAfter(pickUpDateTime);

      const orderCategory = getOrderCategory(
        order,
        isOutgoingOrder,
        listingType,
        isPickupForPayLaterPast
      );

      const designerOrderCategory = getDesignerOrderCategory(
        order,
        listingType
      );
      if (!orderCategory) return;

      /*
       * we can depend on storePickupDateTime also for MOL Pickup orders to identify it as Pickup Order but as we have issue at
       * FCS end (sending millisec as part of storePickupDateTime) so adding orderSource + deliveryMethod check here to identify MOL Pickup order.
       */
      const isPickupOrder =
        ((orderSource === "DoorDash" || orderSource === "UberEats") &&
          !isEmpty(storePickupDateTime)) ||
        (orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP") ||
        ["STORE_PICKUP", "WALK_IN"].includes(deliveryMethod) ||
        (hasPendingPayLaterPayment && isPickupForPayLaterPast);

      let deliveryInstructionsFormatted = deliveryInstructions;
      if (
        orderSource === "MOL" &&
        deliveryMethod === "MOL_CUSTOMER_PICKUP" &&
        !!deliveryInstructions
      ) {
        const [molPickupValue, ...otherDelInstructions] =
          deliveryInstructions.split(",");

        if (!!molPickupValue && !molPickupValue.includes("\\n")) {
          deliveryInstructionsFormatted = otherDelInstructions.join(",");
        } else {
          const [molPickupValue, ...otherDelInstructions] =
            deliveryInstructions.split("\\n");
          if (
            !!molPickupValue &&
            moment(molPickupValue).format("h:mm A") !== "Invalid date"
          ) {
            deliveryInstructionsFormatted = otherDelInstructions;
          }
        }
      }

      const internalStatus = getOrderInternalStatus(order, isPickupOrder);
      const internalSubStatus = getOrderInternalSubStatus(order, listingType);
      let internalStatusOverride = internalStatus;

      const countDownTimer =
        orderCategory === "needsAction" &&
        internalStatus === "incoming" &&
        !isPickupOrder
          ? calcCountDownTimer(
              order.createdOn,
              fillerMemberCode,
              memberTimeZones,
              shopHours
            )
          : {
              countDownWithinShopEndTime: undefined,
              countDownEndTime: "",
              countDownPauseTime: "",
            };
      const {
        countDownWithinShopEndTime: countDownTimerEndTime,
        countDownEndTime = "",
        countDownPauseTime = "",
      } = countDownTimer;

      if (countDownTimerEndTime && !isPickupOrder) {
        const timeLeftInMins = countDownTimerEndTime
          ? getTimeLeftInMins(
              countDownTimerEndTime,
              memberTimeZones[fillerMemberCode]
            )
          : 0;

        const expiringTimeInterval = Environment.get(
          "ORDER_ACCEPTANCE_EXPIRING_INTERVAL",
          10
        );
        // eligible for countdown timer display
        if (timeLeftInMins < expiringTimeInterval) {
          if (timeLeftInMins > 0) internalStatusOverride = "expiring";
          else if (status === "FORFEITED") internalStatusOverride = "expired";
        }
      }

      // if (hasFulfillmentError) {
      //   internalStatusOverride = "replace-filler";
      // }
      const pendingRequestOrResponse =
        latestPendingRequestType || latestUnreadResponseType;

      const displayOrderStatus =
        hasFulfillmentError || hasDSFulfillmentError
          ? `Fulfillment Error`
          : hasSettlementError || hasRefundError
          ? "Payment Error"
          : internalSubStatus.includes("approve-request")
          ? pendingRequestOrResponse === "DeliveryDate"
            ? "Approve Date"
            : pendingRequestOrResponse === "Price"
            ? "Approve Price"
            : "approve-request"
          : internalSubStatus.includes("view-responses")
          ? latestUnreadResponseStatus === "APPROVED"
            ? pendingRequestOrResponse === "DeliveryDate"
              ? "Date Approved"
              : pendingRequestOrResponse === "Price"
              ? "Price Approved"
              : latestUnreadResponseStatus
            : latestUnreadResponseStatus === "DENIED"
            ? pendingRequestOrResponse === "DeliveryDate"
              ? "Date Denied"
              : pendingRequestOrResponse === "Price"
              ? "Price Denied"
              : latestUnreadResponseStatus
            : latestUnreadResponseStatus
          : isOutgoingOrder &&
            !["incoming", "outgoing"].includes(internalStatus)
          ? `Outgoing ${internalStatus}`
          : internalStatusOverride === "expiring"
          ? "Hurry!"
          : internalStatusOverride === "expired"
          ? "Forfeited"
          : isPickupOrder && ["completed-pickup"].includes(internalStatus)
          ? "Completed"
          : isPickupOrder && ["cancelled-pickup"].includes(internalStatus)
          ? "Cancelled"
          : isPickupOrder
          ? "Pickup"
          : internalStatus === "designed-pickup"
          ? "designed"
          : internalStatus;

      const imageName =
        hasPendingPayLaterPayment &&
        isPickupForPayLaterPast &&
        internalStatus !== "cancelled-pickup"
          ? "pay-later-past-time"
          : hasFulfillmentError
          ? "replace-filler"
          : hasDSFulfillmentError
          ? "delivery-service-error"
          : (hasRefundError || hasSettlementError) &&
            orderChannel !== "Subscription"
          ? "payment-error"
          : isDSorder(latestDSRequestedStatus, status)
          ? `delivery-service-${lowerCase(displayOrderStatus)}`
          : orderChannel === "Subscription"
          ? "subscription"
          : pendingRequestOrResponse === "Cancel"
          ? displayOrderStatus === "APPROVED" ||
            displayOrderStatus === "approve-request"
            ? "approve-request-denied"
            : "cancel"
          : pendingRequestOrResponse === "DeliveryDate"
          ? internalStatusOverride === "expired" ||
            displayOrderStatus === "Date Denied"
            ? "forfeited-date"
            : displayOrderStatus === "Date Approved"
            ? "approved-date"
            : "date"
          : pendingRequestOrResponse === "Price"
          ? internalStatusOverride === "expired" ||
            displayOrderStatus === "Price Denied"
            ? "forfeited-price"
            : displayOrderStatus === "Price Approved"
            ? "approved-price"
            : "price"
          : numOfUnreadChatMessages > 0
          ? internalStatusOverride === "expiring" ||
            internalStatusOverride === "expired"
            ? "expiring-chat"
            : internalStatus === "completed"
            ? "completed-chat"
            : internalStatus === "incoming"
            ? "incoming-chat"
            : internalStatus === "outgoing"
            ? "outgoing-chat"
            : "chat"
          : internalStatus === "incoming" &&
            internalStatusOverride === "expired"
          ? "forfeited-incoming"
          : internalStatusOverride === "expiring"
          ? "expiring"
          : internalStatus === "incoming-pickup"
          ? hasPendingPayLaterPayment
            ? "pay-later-incoming-pickup"
            : "incoming-pickup"
          : internalStatus === "accepted-pickup"
          ? hasPendingPayLaterPayment
            ? "pay-later-accepted-pickup"
            : "accepted-pickup"
          : internalStatus === "design-pickup"
          ? hasPendingPayLaterPayment
            ? "pay-later-design-pickup"
            : "design-pickup"
          : internalStatus === "completed-pickup"
          ? hasPendingPayLaterPayment
            ? "pay-later-completed-pickup"
            : "completed-pickup"
          : internalStatus === "cancelled-pickup"
          ? hasPendingPayLaterPayment
            ? "pay-later-cancelled-pickup"
            : "cancelled-pickup"
          : isRushOrder ||
            (isRushOrder && orderDeliveryTime) ||
            (!isRushOrder && orderDeliveryTime && internalStatus !== "incoming")
          ? internalStatus === "completed"
            ? "timed-delivery-delivered"
            : internalStatus === "cancelled" || internalStatus === "rejected"
            ? "timed-delivery-cancelled"
            : "timed-delivery-progress"
          : isDropShipOrder
          ? internalStatus === "completed"
            ? "product-drop-ship-delivered"
            : internalStatus === "cancelled" || internalStatus === "rejected"
            ? "product-drop-ship-cancelled"
            : "product-drop-ship-progress"
          : internalStatus === "in-design"
          ? "design"
          : internalStatus === "designed-pickup"
          ? hasPendingPayLaterPayment
            ? "pay-later-designed-pickup"
            : "designed-pickup"
          : internalStatus === "delivery-pickup"
          ? hasPendingPayLaterPayment
            ? "pay-later-delivery-pickup"
            : "delivery-pickup"
          : internalStatus;
      const toolTipText =
        hasPendingPayLaterPayment &&
        isPickupForPayLaterPast &&
        internalStatus !== "cancelled-pickup"
          ? "Paylater"
          : hasFulfillmentError
          ? "Fulfillment Error"
          : hasDSFulfillmentError
          ? "Delivery Service"
          : (hasRefundError || hasSettlementError) &&
            orderChannel !== "Subscription"
          ? "Payment Error"
          : isDSorder(latestDSRequestedStatus, status)
          ? `Delivery Service`
          : orderChannel === "Subscription"
          ? "Subscription"
          : pendingRequestOrResponse === "Cancel"
          ? displayOrderStatus === "APPROVED" ||
            displayOrderStatus === "approve-request"
            ? "Approve request denied"
            : "Cancel"
          : pendingRequestOrResponse === "DeliveryDate"
          ? internalStatusOverride === "expired" ||
            displayOrderStatus === "Date Denied"
            ? "Forfeited Date"
            : displayOrderStatus === "Date Approved"
            ? "Approved Date"
            : "Date"
          : pendingRequestOrResponse === "Price"
          ? internalStatusOverride === "expired" ||
            displayOrderStatus === "Price Denied"
            ? "Forfeited price"
            : displayOrderStatus === "Price Approved"
            ? "Price"
            : "Price"
          : numOfUnreadChatMessages > 0
          ? internalStatusOverride === "expiring" ||
            internalStatusOverride === "expired"
            ? "Expiring chat"
            : internalStatus === "completed"
            ? "Incoming chat"
            : internalStatus === "incoming"
            ? "Incoming chat"
            : internalStatus === "outgoing"
            ? "Outgoing chat"
            : "Chat"
          : internalStatus === "incoming" &&
            internalStatusOverride === "expired"
          ? "Hurry Pickup"
          : internalStatusOverride === "expiring"
          ? "Hurry Pickup"
          : internalStatus === "incoming-pickup"
          ? hasPendingPayLaterPayment
            ? "Paylater"
            : "Pickup"
          : internalStatus === "accepted-pickup"
          ? hasPendingPayLaterPayment
            ? "Paylater"
            : internalStatusOverride === "expiring" ||
              internalStatusOverride === "expired"
            ? "Hurry Pickup"
            : "Pickup"
          : internalStatus === "design-pickup"
          ? hasPendingPayLaterPayment
            ? "Paylater"
            : "Pickup"
          : internalStatus === "completed-pickup"
          ? hasPendingPayLaterPayment
            ? "Paylater"
            : "Pickup"
          : internalStatus === "cancelled-pickup"
          ? hasPendingPayLaterPayment
            ? "Paylater Cancelled pickup"
            : "Pickup"
          : isRushOrder ||
            (isRushOrder && orderDeliveryTime) ||
            (!isRushOrder && orderDeliveryTime && internalStatus !== "incoming")
          ? internalStatus === "completed"
            ? "Rush/Timed Delivery"
            : internalStatus === "cancelled" || internalStatus === "rejected"
            ? "Rush/Timed Delivery"
            : "Rush/Timed Delivery"
          : isDropShipOrder
          ? internalStatus === "completed"
            ? "Walk-in"
            : internalStatus === "cancelled" || internalStatus === "rejected"
            ? "Walk-in"
            : "Product Dropship Progress"
          : internalStatus === "in-design"
          ? "design"
          : internalStatus === "designed-pickup"
          ? hasPendingPayLaterPayment
            ? "Paylater Designed Pickup"
            : "Designed Pickup"
          : internalStatus === "delivery-pickup"
          ? hasPendingPayLaterPayment
            ? "Paylater Delivery Pickup"
            : "Delivery Pickup"
          : capitalizeFirstLetter(internalStatus);

      const hasDateChangeNotification = [
        "Approve Date",
        "Date Approved",
        "Date Denied",
      ].includes(displayOrderStatus);
      const hasPriceChangeNotification = [
        "Approve Price",
        "Price Approved",
        "Price Denied",
      ].includes(displayOrderStatus);
      const hasCancelRequest = displayOrderStatus === "Approve Request";

      const statusColor =
        orderStatusColorCodes[
          hasPendingPayLaterPayment &&
          isPickupForPayLaterPast &&
          internalStatus !== "cancelled-pickup"
            ? "paymentPending"
            : displayOrderStatus?.toLowerCase()
        ] ||
        orderStatusColorCodes[internalSubStatus[0]] ||
        orderStatusColorCodes[internalStatus];

      const price = get(order, "price.0.value", 0);
      const lineItems = get(order, "lineItems", []);
      const productInfo = lineItems[0] || {};

      const isCoreUser = isCoreMember(sourceMemberCode);
      const isCoreConnectUser = isCoreConnectMember(sourceMemberCode);

      let items = "Bouquet";
      if (!isCoreUser && !isCoreConnectUser) {
        let quantity = 0;
        lineItems.map((lineItem) => {
          quantity += lineItem?.quantity;
        });

        // To consider old orders which has add-ons as part of first product
        quantity += productInfo?.accessories?.length || 0;

        items = `${quantity} items`;
      }

      const showDateStatus =
        (hasDateChangeNotification && latestRequestedDeliveryDate) ||
        (!hasPriceChangeNotification &&
          !hasCancelRequest &&
          latestDeliveryDateRequestStatus === "WAITING");
      const displayDeliveryDate = showDateStatus
        ? moment(latestRequestedDeliveryDate).format("MM/DD/YYYY")
        : isPickupOrder
        ? moment(storePickupDateTime || deliveryDate).format("MM/DD/YYYY")
        : moment(deliveryDate).format("MM/DD/YYYY");
      const deliveryDateSubColumn = showDateStatus
        ? DatePriceRequestStatusConfig[latestDeliveryDateRequestStatus]
        : deliveryInstructionsFormatted;

      const showPriceStatus =
        (hasPriceChangeNotification && latestRequestedPrice) ||
        (!hasDateChangeNotification &&
          !hasCancelRequest &&
          latestPriceRequestStatus === "WAITING");
      const displayPrice = `$${
        showPriceStatus
          ? parseFloat(latestRequestedPrice).toFixed(2)
          : price.toFixed(2)
      }`;
      const priceSubColumn = showPriceStatus
        ? DatePriceRequestStatusConfig[latestPriceRequestStatus]
        : "";

      const floristType = [
        "Bloomnet",
        "Teleflora",
        "TEL eFlorist",
        "Email Order",
        "UberEats",
        "DoorDash",
        "MOL",
        "FTD",
      ].includes(orderSource);

      let orderCollectionData = {
        deliveryDate,
        deliveryTime,
        deliveryZoneId,
        deliveryZoneName,
        direction,
        displayDeliveryDate,
        displayOrderStatus:
          displayOrderStatus === `Outgoing in-design`
            ? `Outgoing\nIn Design`
            : startCase(toLower(displayOrderStatus)),
        displayPrice,
        imageName,
        toolTipText: toolTipText === `Cancelled` ? `Canceled` : toolTipText,
        internalStatus,
        internalSubStatus,
        isPrinted,
        isRushOrder,
        locationType: firstName ? locationType ?? "Other" : "",
        messageNumber,
        orderItemId,
        orderSource,
        productFirstChoiceDescription: `${(
          productInfo.productFirstChoiceDescription || ""
        )
          .split("\\n")
          .join("\n")}`,
        receivingMember,
        recipientAddressLine: addressLine1,
        recipientCity: city ? `${city}, ${state}` : "",
        recipientZip: zip,
        recipientName: name || pickUpBy,
        recipientLocation: { latitude, longitude },
        status,
        statusColor,
        zip,
        price,
        dsEligible,
        deliveryInstructions: deliveryInstructionsFormatted,
        sourceMemberCode,
        routeId,
        deliveryMethod,
        addressVerificationInfo,
        hasSettlementError,
        hasFulfillmentError,
        hasPendingPayLaterPayment,
        customerName: floristType ? orderSource : customerName,
        orderDeliveryTime,
        isInvoiceDetailsPrinted,
        isTrifoldDetailsPrinted,
        isDesignerWorkSheetPrinted,
        notes,
        isFollowUp,
      };

      if (listingType !== "delivery-details") {
        orderCollectionData = {
          ...orderCollectionData,
          countDownTimerEndTime,
          countDownEndTime,
          countDownPauseTime,
          createdOn,
          designer: designerInfo,
          deliveryDateSubColumn,
          priceSubColumn,
          giftCardType,
          fillerMemberCode,
          internalStatusOverride,
          isOutgoingOrder,
          items,
          omsOrderId,
          memberTimeZones,
          numOfPendingApproveRequests,
          numOfUnreadChatMessages,
          numOfUnreadStructuredResponses,
          occasion,
          recipientAddressLine2: addressLine2,
          recipientCityOnly: city,
          recipientState: state,
          recipientCountry: country,
          sendingMember,
          isPickupOrder,
          storePickupDateTime,
          latestDSRequestedStatus,
          hasDSFulfillmentError,
          autoSendToDS,
          customerOrderId,
          hasSettlementError,
          hasFulfillmentError,
          hasPendingPayLaterPayment,
          customerName,
        };
      }

      orderCollection[orderCategory]?.orders.push({
        ...orderCollectionData,
        orderCategory,
      });

      orderCollection[designerOrderCategory]?.orders.push({
        ...orderCollectionData,
        designerOrderCategory,
      });
    });

  return { timeStamp, originalData, orderCollection };
};

export const refineOrders = (
  orders,
  { filters = [], shops = [], sort = {}, search = {}, orderIds = "" }
) => {
  const OrderDisplayNameToKeyMap = {
    "Created Date: New to Old": "createdOn",
    "Created Date: Old to New": "createdOn",
    "Date Range": "deliveryDate",
    "Delivery Date: New to Old": "deliveryDate",
    "Delivery Date: Old to New": "deliveryDate",
    "Default: Delivery Date + Rush/Timed": "deliveryDate",
    "Order Date: New to Old": "createdOn",
    "Ordered Date: Old to New": "createdOn",
    "Order Status (A to Z)": "displayOrderStatus",
    "Order Status (Z to A)": "displayOrderStatus",
    "Order Status": "status",
    "Price Range": "price",
    "Price: High to Low": "price",
    "Price: Low to High": "price",
    "Delivery Zone Name (A to Z)": "deliveryZoneName",
    "Delivery Zone Name (Z to A)": "deliveryZoneName",
  };

  let sortField;
  const filterCategoryMap = {};

  if (filters.length) {
    filters.map((e) => {
      const [type, { value }] = Object.entries(e).pop();
      filterCategoryMap[type] = filterCategoryMap[type]
        ? filterCategoryMap[type].concat(":" + value)
        : value;
    });
  }

  const isHavingUndeliveredOrdersFilters =
    filterCategoryMap.Categories &&
    filterCategoryMap.Categories.includes("needsAction") &&
    filterCategoryMap.Categories.includes("undeliveredOrders") &&
    filterCategoryMap.Categories.includes("INBOUND");

  const today = moment();

  orders = orders.filter((order) => {
    let matchesFilter = true;

    // Filtering the orders based on the search query
    if (!isEmpty(search) && matchesFilter) {
      matchesFilter =
        toLower(order.productFirstChoiceDescription).includes(
          toLower(search.value)
        ) ||
        toLower(order.messageNumber).includes(toLower(search.value)) ||
        toLower(order.orderItemId).includes(toLower(search.value)) ||
        toLower(order.recipientName).includes(toLower(search.value)) ||
        toLower(order.recipientCity).includes(toLower(search.value)) ||
        toLower(order.customerName).includes(toLower(search.value)) ||
        toLower(order.deliveryZoneName).includes(toLower(search.value)) ||
        toLower(
          order?.displayOrderStatus?.replace("Cancelled", "Canceled")
        ).includes(toLower(search.value)) ||
        toLower(order.sendingMember.memberCode).includes(
          toLower(search.value)
        ) ||
        toLower(order.receivingMember.memberCode).includes(
          toLower(search.value)
        ) ||
        toLower(order.customerOrderId).includes(toLower(search.value))
          ? true
          : false;
    }

    // Filter orders by shop codes
    if (!isEmpty(shops) && matchesFilter) {
      const results = shops.filter((shop) => {
        const memberCode = shop["Shop Codes"].value;
        if (
          (order.direction === "OUTBOUND" &&
            get(order, "sendingMember.memberCode") === memberCode) ||
          (order.direction === "INBOUND" &&
            get(order, "receivingMember.memberCode") === memberCode)
        )
          return true;
      });
      matchesFilter = results.length ? true : false;
    }

    if (filters.length && matchesFilter) {
      // Filtering list by filters
      matchesFilter = Object.keys(filterCategoryMap).reduce(
        (matches, category) => {
          const value = filterCategoryMap[category];
          // filters a day, week or month newer or older orders
          const dateType = value?.dateType;
          const orderDateToCompare =
            dateType === "ORDER_DATE"
              ? moment(order.createdOn).format("YYYY-MM-DD")
              : order.deliveryDate;
          // Filtering by Date Range
          if (matches && category === "Date Range") {
            if (!isObject(value)) {
              const multiValues = value.split(":");
              const results = multiValues.filter((value) => {
                const diffInDays = Math.ceil(
                  moment(orderDateToCompare).diff(today, "days", true)
                );

                if (value == 0 && diffInDays == 0) return true;

                if (value == 1 && diffInDays == 1) return true;

                if (value > 1 && diffInDays >= 1 && diffInDays <= value)
                  return true;

                if (value < 0 && diffInDays < 0 && diffInDays >= value)
                  return true;
              });
              matches = results.length ? true : false;
            } else {
              const isUndeliveredOrder = ![
                "CANCELLED",
                "REJECTED",
                "FORWARDED",
                "DELIVERED",
                "ERROR",
              ].includes(order.status);
              // Check if the date range is valid and the order matches the criteria
              matches =
                value?.startDate === value?.endDate
                  ? isHavingUndeliveredOrdersFilters
                    ? isUndeliveredOrder &&
                      orderDateToCompare === value?.startDate
                    : orderDateToCompare === value?.startDate
                  : true;
            }
          }

          // filter orders by category bucket
          if (matches && category === "Categories") {
            const multiValues = value.split(":");
            const hasInbound = multiValues.includes("INBOUND");
            const hasOutbound = multiValues.includes("OUTBOUND");
            const hasRushFilter = multiValues.includes("rush");
            const hasTimedFilter = multiValues.includes("timedOrders");
            const results = multiValues.filter((value) => {
              if (value === "INBOUND") {
                return (
                  order.direction === value && order.status !== "FORWARDED"
                );
              } else if (value === "OUTBOUND") {
                return (
                  (order.direction === "INBOUND" &&
                    order.status === "FORWARDED") ||
                  order.direction === value
                );
              } else if (value === "rush") {
                return order.isRushOrder;
              } else if (value === "timedOrders") {
                return !!order?.orderDeliveryTime && !order.isRushOrder;
              } else {
                return hasInbound
                  ? order.direction === "INBOUND" &&
                      order.orderCategory === value
                  : hasOutbound
                  ? order.direction === "OUTBOUND" &&
                    order.orderCategory === value
                  : hasRushFilter
                  ? order.isRushOrder && order.orderCategory === value
                  : hasTimedFilter
                  ? !!order?.orderDeliveryTime &&
                    !order.isRushOrder &&
                    order.orderCategory === value
                  : order.orderCategory === value;
              }
            });

            matches = results.length ? true : false;
          }

          // filter orders by designer name bucket
          if (matches && category === "FilterByDesignerName") {
            const results = value.filter((obj) => {
              return order?.designer?.name === obj?.value;
            });
            matches = results.length ? true : false;
          }

          // filter orders by status
          if (matches && category === "Order Status") {
            const multiValues = value.split(":");
            if (value.includes("regular")) {
              const results = multiValues.filter((value) => {
                return (
                  order.internalStatus === "incoming" ||
                  order.internalSubStatus.includes("incoming")
                );
              });

              matches = results.length ? true : false;
            } else if (value.includes("new-pickup")) {
              const results = multiValues.filter((value) => {
                return (
                  order.isPickupOrder &&
                  order.internalStatus === "incoming-pickup" &&
                  getPickupTimeLeftInMins(order.storePickupDateTime) > 31
                );
              });

              matches = results.length ? true : false;
            } else if (value.includes("hurry-pickup")) {
              const results = multiValues.filter((value) => {
                const isValidOrderSource =
                  order.orderSource === "DoorDash" ||
                  order.orderSource === "UberEats" ||
                  order.deliveryMethod === "MOL_CUSTOMER_PICKUP"; // for orderSource === "MOL"
                const isValidInternalStatus =
                  order.internalStatus === "incoming-pickup" ||
                  order.internalStatus === "accepted-pickup";

                const isPendingPayLaterPaymentOrder =
                  order?.hasPendingPayLaterPayment || false;

                const isHurryPickUpOrder =
                  isPendingPayLaterPaymentOrder ||
                  (order.isPickupOrder &&
                    isValidOrderSource &&
                    isValidInternalStatus &&
                    getPickupTimeLeftInMins(order.storePickupDateTime) < 31);
                return isHurryPickUpOrder;
              });

              matches = results.length ? true : false;
            } else if (value.includes("active-orders")) {
              const results = multiValues.filter((value) => {
                return (
                  ![
                    "rejectedOrders",
                    "cancelledOrders",
                    "forfeitedOrders",
                  ].includes(order.orderCategory) &&
                  order.status !== "CANCELLED"
                );
              });

              matches = results.length ? true : false;
            } else if (value.includes("__deliveryDashboard")) {
              //Filter Route eligible Accepted Orders, filter from Delivery Dashboard
              const filterVal = value.split("__")[0];

              matches =
                isOrderEligibleforDeliveryDashboard(order) &&
                order.internalStatus === filterVal;
            } else {
              const results = multiValues.filter((value) => {
                if (
                  order.internalStatus === value ||
                  order.internalStatus === `${value}-pickup` ||
                  (value === "in-design" &&
                    order.internalStatus === `design-pickup`) ||
                  order.internalSubStatus.includes(value) ||
                  (value === "unassignedOrders" &&
                    order.designer?.name?.length === 0) ||
                  (value === "assignedOrders" &&
                    order.designer?.name?.length > 0)
                ) {
                  return true;
                }
              });

              matches = results.length ? true : false;
            }
          }

          // filter orders with price range
          if (matches && category === "Price Range" && value.length == 2) {
            matches =
              order.price >= toNumber(value[0]) &&
              order.price <= toNumber(value[1])
                ? true
                : false;
          }

          // filter orders which are unprinted
          if (matches && category === "Individual Filters") {
            const multiValues = value.split(":");
            if (multiValues.includes("unprintedOrders"))
              matches = !order.isPrinted ? true : false;
            if (matches && multiValues.includes("flaggedOrders"))
              matches = order.isFollowUp ? true : false;
          }

          // filter orders by order source bucket
          if (matches && category === "Order Source") {
            const multiValues = value.split(":");

            const result = multiValues.filter((value) => {
              if (value === "Local") {
                return (
                  order.orderSource === "FLORIST" &&
                  ["FLORIST_DELIVERED", "STORE_PICKUP", "WALK_IN"].includes(
                    order?.deliveryMethod
                  )
                );
              } else {
                return value === order.orderSource;
              }
            });

            matches = result.length ? true : false;
          }

          // filter orders by routeId
          if (matches && category === "RouteId") {
            matches = isOrderEligibleforDeliveryDashboard(order, true);
            matches =
              matches &&
              (value
                ? order?.routeId === value
                : !order?.routeId || order?.routeId === "null");
          }

          // filter orders by deliveryZoneId
          if (matches && category === "ZoneId") {
            matches =
              isOrderEligibleforDeliveryDashboard(order, true) &&
              order?.deliveryZoneId === value;
          }

          // filter orders eligible for Routes and Rush/Routed/NonRouted from Delivery Dashboard
          if (matches && category === "Additional Status") {
            matches = isOrderEligibleforDeliveryDashboard(order, true);

            if (matches && value === "rush") {
              matches = !!order?.isRushOrder;
            } else if (matches && value === "non-routed") {
              matches = !order?.routeId || order?.routeId === "null";
            } else if (matches && value === "routed") {
              matches = order?.routeId && order?.routeId !== "null";
            }
          }

          return matches;
        },
        true
      );
    }

    // Filter orders by orderIds
    if (orderIds && matchesFilter) {
      const results = orderIds.split(",").filter((orderId) => {
        if (order.orderItemId === orderId) return true;
      });
      matchesFilter = results.length ? true : false;
    }
    if (matchesFilter) return order;
  });

  const sortNeedsActionOrder = () => {
    const needsActionOrdersWithRush = sortBy(
      orders,
      OrderDisplayNameToKeyMap[sortField]
    );
    const rushOrders = needsActionOrdersWithRush.filter((e) => e.isRushOrder);
    const needsActionOrders = needsActionOrdersWithRush.filter(
      (e) => !e.isRushOrder
    );

    const sortedOrder = needsActionOrders
      .filter((e) => e.internalStatusOverride === "expiring")
      .concat(rushOrders)
      .concat(
        needsActionOrders.filter(
          (e) => e.internalStatusOverride === "incoming-pickup"
        )
      )
      .concat(
        needsActionOrders.filter(
          (e) => getPickupTimeLeftInMins(e.storePickupDateTime) < 31
        )
      )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.internalStatusOverride === "incoming" &&
            e.internalSubStatus.includes("approve-request")
        )
      )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.internalStatusOverride === "incoming" &&
            e.internalSubStatus.includes("view-responses")
        )
      )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.internalStatusOverride === "incoming" &&
            e.internalSubStatus.includes("view-chats")
        )
      )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.internalStatusOverride === "incoming" &&
            !e.internalSubStatus.length
        )
      )
      // .concat(
      //   needsActionOrders.filter(
      //     (e) => e.internalStatusOverride === "replace-filler"
      //   )
      // )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.internalStatus !== "incoming" &&
            e.internalSubStatus.includes("approve-request")
        )
      )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.internalStatus !== "incoming" &&
            e.internalSubStatus.includes("view-responses")
        )
      )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.internalStatus !== "incoming" &&
            e.internalSubStatus.includes("view-chats")
        )
      )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.internalStatusOverride === "expired" &&
            e.internalSubStatus.includes("approve-request")
        )
      )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.internalStatusOverride === "expired" &&
            e.internalSubStatus.includes("view-responses")
        )
      )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.internalStatusOverride === "expired" &&
            e.internalSubStatus.includes("view-chats")
        )
      )
      .concat(
        needsActionOrders.filter((e) => e.internalStatusOverride === "expired")
      )
      .concat(
        needsActionOrders.filter(
          (e) =>
            e.hasPendingPayLaterPayment &&
            e.internalStatus !== "cancelled-pickup" &&
            e.internalSubStatus.includes("approve-request")
        )
      );

    return uniqWith(sortedOrder, isEqual);
  };

  const sortBasedOnDeliveryTime = (orderList) => {
    if (
      orderList?.length &&
      [
        "todayOrders",
        "tomorrowOrders",
        "futureOrders",
        "outgoingOrders",
      ].includes(orderList[0].orderCategory)
    ) {
      return orderList.sort((orderA, orderB) => {
        const orderADeliveryDate = moment(orderA.deliveryDate, "YYYY-MM-DD");
        const orderBDeliveryDate = moment(orderB.deliveryDate, "YYYY-MM-DD");

        // Both orders have the same delivery date
        if (orderADeliveryDate.isSame(orderBDeliveryDate)) {
          const orderAHasTime = !!orderA?.orderDeliveryTime;
          const orderBHasTime = !!orderB?.orderDeliveryTime;
          const orderAIsRush = !!orderA?.isRushOrder;
          const orderBIsRush = !!orderB?.isRushOrder;

          // Neither order has a deliverByTime
          if (!orderAHasTime && !orderBHasTime) {
            if (orderAIsRush && !orderBIsRush) return -1;
            if (!orderAIsRush && orderBIsRush) return 1;
            return 0;
          }

          // orderA has deliverByTime, orderB doesn't
          if (orderAHasTime && !orderBHasTime) {
            if (orderBIsRush) return 1;
            return -1;
          }

          // orderB has deliverByTime, orderA doesn't
          if (!orderAHasTime && orderBHasTime) {
            if (orderAIsRush) return -1;
            return 1;
          }

          // Both orders have a deliverByTime
          if (orderAHasTime && orderBHasTime) {
            const orderADeliveryTime = moment(
              orderA.orderDeliveryTime,
              "h:mm A"
            );
            const orderBDeliveryTime = moment(
              orderB.orderDeliveryTime,
              "h:mm A"
            );
            // Both have the same deliverByTime
            if (orderADeliveryTime.isSame(orderBDeliveryTime)) {
              if (orderAIsRush && !orderBIsRush) return -1;
              if (!orderAIsRush && orderBIsRush) return 1;
              return 0;
            }

            return orderADeliveryTime.isBefore(orderBDeliveryTime) ? -1 : 1;
          }

          return 0;
        }

        return orderADeliveryDate.isBefore(orderBDeliveryDate) ? -1 : 1;
      });
    }
    return orderList;
  };

  // const sortOrdersForRush = (orders) => {
  //   // Priortize RushOrders in Today & Tomorrow orders bucket
  //   if (
  //     orders.length &&
  //     ["todayOrders", "tomorrowOrders"].includes(orders[0].orderCategory)
  //   ) {
  //     const rushOrders = orders.filter((e) => e.isRushOrder);
  //     const nonRushOrders = orders.filter((e) => !e.isRushOrder);
  //     return [...rushOrders, ...nonRushOrders];
  //   }
  //   return orders;
  // };

  // Filter orders by sort
  if (isEmpty(sort)) {
    if (orders.length && orders[0].orderCategory === "needsAction") {
      sortField = "Created Date: Old to New";
      orders = sortNeedsActionOrder();
    } else {
      sortField = "Default: Delivery Date + Rush/Timed";
      orders = sortBasedOnDeliveryTime(orders);
    }
  } else if (sort.value === "Default: Delivery Date + Rush/Timed") {
    orders = sortBasedOnDeliveryTime(orders);
  } else {
    sortField = sort.value;
    if (orders.length && orders[0].orderCategory === "needsAction") {
      orders = sortNeedsActionOrder();
    }
    orders = sortBy(orders, OrderDisplayNameToKeyMap[sortField]);
  }

  if (
    sortField === "Price: High to Low" ||
    sortField === "Delivery Date: New to Old" ||
    sortField === "Created Date: New to Old" ||
    sortField === "Order Date: New to Old" ||
    sortField === "Order Status (Z to A)" ||
    sortField === "Delivery Zone Name (Z to A)"
  )
    orders = orders.reverse();

  return orders;
};

export const getOrderCategory = (
  order,
  isOutgoingOrder,
  listingType,
  isPickupForPayLaterPast = false
) => {
  const {
    status,
    numOfPendingApproveRequests,
    numOfUnreadStructuredResponses,
    numOfUnreadChatMessages,
    deliveryDate,
    deliveryInfo,
    orderSource,
    latestDSRequestedStatus,
    direction,
    pickupInfo: { storePickupDateTime = "" } = {},
    deliveryInfo: { deliveryMethod = "" },
    hasFulfillmentError = false,
    hasPendingPayLaterPayment = false,
  } = order;

  const date = deliveryDate || deliveryInfo?.deliveryDate;

  const deliveryInPast = moment(date).isBefore(moment().startOf("day"));
  const deliveryInFuture = moment(date).isAfter();
  const deliveryAfterOneDay = moment(date).isAfter(moment().add(1, "days"));
  // current orders
  if (listingType === "current") {
    const needsActionCheck =
      numOfPendingApproveRequests > 0 ||
      numOfUnreadStructuredResponses > 0 ||
      numOfUnreadChatMessages > 0 ||
      ["NEW", "VIEWED", "PRINTED"].includes(status) ||
      ([
        "NEW",
        "VIEWED",
        "PRINTED",
        "ACKNOWLEDGE_PRINT",
        "ACKNOWLEDGED",
      ].includes(status) &&
        (orderSource === "DoorDash" ||
          orderSource === "UberEats" ||
          deliveryMethod === "MOL_CUSTOMER_PICKUP") &&
        getPickupTimeLeftInMins(storePickupDateTime) < 31) ||
      hasFulfillmentError ||
      (hasPendingPayLaterPayment &&
        isPickupForPayLaterPast &&
        status !== "CANCELLED");

    const unDeliveredPastOrder =
      deliveryInPast &&
      [
        "ACKNOWLEDGED",
        "PRINTED",
        "DS_REQUESTED",
        "DS_REQUEST_PENDING",
        "DESIGN",
        "DESIGNED",
        "OUT_FOR_DELIVERY",
        "ACKNOWLEDGE_PRINT",
      ].includes(status); //Get only Accepted orders which are not yet marked as Delivered.

    const deliveredOrder = ["DELIVERED"].includes(status);

    const forwardedOrder = ["FORWARDED"].includes(status);

    if (needsActionCheck) return "needsAction";

    if (
      (isOutgoingOrder && (!deliveredOrder || status !== "SENT")) ||
      forwardedOrder
    )
      return "outgoingOrders";

    if (deliveredOrder && deliveryInPast) return "completedOrders";

    if (status === "FORFEITED") return "forfeitedOrders";

    if (status === "REJECTED") return "rejectedOrders";

    if (status === "CANCELLED") return "cancelledOrders";

    if (deliveryAfterOneDay && !isOutgoingOrder) return "futureOrders";

    if (deliveryInFuture && !isOutgoingOrder) return "tomorrowOrders";

    if (!deliveryInPast && !isOutgoingOrder) return "todayOrders";

    if (unDeliveredPastOrder && !isOutgoingOrder) return "undeliveredOrders"; //show undelivered orders - PAR-230
  } else if (
    listingType === "delivery-listing" ||
    listingType === "delivery-details"
  ) {
    const listType = listingType.split("-"); //to differentiate listing and details screens.

    //nonRouted Orders
    // const nonRoutedOrder = [
    //   "ACKNOWLEDGED",
    //   "PRINTED",
    //   "DESIGN",
    //   "OUT_FOR_DELIVERY",
    //   "ACKNOWLEDGE_PRINT",
    // ].includes(status);

    const isPickupOrder =
      ((orderSource === "DoorDash" || orderSource === "UberEats") &&
        !isEmpty(storePickupDateTime)) ||
      (orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP") ||
      deliveryMethod === "STORE_PICKUP";
    const isWalkInOrder = deliveryMethod === "WALK_IN";

    const listTypeCheck =
      listType[1] === "listing"
        ? !deliveryInfo?.routeId || deliveryInfo?.routeId === "null"
        : true; //We should avoid orders with routeId in listing screen.

    if (
      !isDSorder(latestDSRequestedStatus, status) &&
      !isPickupOrder &&
      !isWalkInOrder &&
      direction === "INBOUND" &&
      listTypeCheck
    )
      return "nonRouted";
  } else {
    return listingType;
  }
};

export const getDesignerOrderCategory = (order, listingType) => {
  const {
    status,
    direction,
    designer: designerInfo = { name: "" },
    hasSettlementError = false,
    receivingMember,
  } = order;

  const shopCode = receivingMember?.memberCode;
  const permissions = AppSettingsStorage.getAllPermissions();

  const hasDesignCenter = hasDesignCenterFeature(permissions, shopCode);

  const assignDesigner = ![
    "CANCELLED",
    "REJECTED",
    "FORFEITED",
    "ERROR",
    "DELIVERED",
    "DESIGNED",
    "OUT_FOR_DELIVERY",
    "FORWARDED",
    "NEW",
    "INCOMING",
  ].includes(status);

  const isActiveOrder = ![
    "CANCELLED",
    "REJECTED",
    "FORFEITED",
    "ERROR",
    "FORWARDED",
  ].includes(status);

  // current orders
  if (listingType === "current") {
    if (designerInfo.name?.length > 0 && isActiveOrder) return "assignedOrders";

    if (
      direction === "INBOUND" &&
      !hasSettlementError &&
      assignDesigner &&
      designerInfo.name?.length === 0 &&
      hasDesignCenter
    )
      return "unassignedOrders";
  }
};

export const getOrderInternalSubStatus = (order, listingType) => {
  const {
    numOfPendingApproveRequests,
    numOfUnreadStructuredResponses,
    numOfUnreadChatMessages,
    hasFulfillmentError,
    hasDSFulfillmentError,
    hasRefundError,
    hasSettlementError,
  } = order;

  if (listingType === "completed") return [];

  const subStatusArray = [];

  if (numOfPendingApproveRequests > 0) subStatusArray.push("approve-request");

  if (numOfUnreadStructuredResponses > 0) subStatusArray.push("view-responses");

  if (
    numOfUnreadChatMessages > 0 ||
    hasFulfillmentError ||
    hasDSFulfillmentError ||
    hasRefundError ||
    hasSettlementError
  )
    subStatusArray.push("view-chats");

  // if (numOfUnreadChatMessages === 0 && hasChatMessages) subStatusArray.push("review-chats");

  return subStatusArray;
};

export const getOrderInternalStatus = (order, isPickupOrder = false) => {
  const { status } = order;

  if (isPickupOrder) {
    if (["NEW", "VIEWED", "PRINTED"].includes(status)) return "incoming-pickup";

    if (["DESIGN"].includes(status)) return "design-pickup";

    if (["DESIGNED"].includes(status)) return "designed-pickup";

    if (status === "OUT_FOR_DELIVERY") return "delivery-pickup";

    if (["DELIVERED"].includes(status)) return "completed-pickup";

    if (["ACKNOWLEDGED", "ACKNOWLEDGE_PRINT"].includes(status))
      return "accepted-pickup";

    if (status === "CANCELLED") return "cancelled-pickup";
  }

  // current orders
  if (["NEW", "VIEWED", "PRINTED"].includes(status)) return "incoming";

  if (["DESIGN", "DS_REQUESTED", "DS_REQUEST_PENDING"].includes(status))
    return "in-design";

  if (["DESIGNED"].includes(status)) return "designed";

  if (status === "OUT_FOR_DELIVERY") return "delivery";

  if (["DELIVERED"].includes(status)) return "completed";

  if (["PENDING", "SENT", "FORWARDED"].includes(status)) return "outgoing";

  if (status === "CANCELLED") return "cancelled";

  if (status === "REJECTED") return "rejected";

  if (status === "FORFEITED") return "forfeited";

  if (status === "ERROR") return "error";

  return "accepted";
};

const DatePriceRequestStatusConfig = {
  WAITING: "Pending",
  APPROVED: "Approved",
  DENIED: "Denied",
};

export const calcCountDownTimer = (
  createdOn,
  fillerMemberCode = "",
  memberTimeZones = {},
  shopHours = {}
) => {
  const shopTimeZone = memberTimeZones[fillerMemberCode];
  const orderCreationDate = shopTimeZone
    ? moment(createdOn).tz(shopTimeZone)
    : moment(createdOn);
  const currentTime = shopTimeZone ? moment().tz(shopTimeZone) : moment();
  const currentDayShopHours = shopHours[fillerMemberCode];
  const { openTime = "", closeTime = "" } =
    find(currentDayShopHours, {
      day: toUpper(currentTime.format("dddd")),
    }) || {};
  //Start countdown timer only during business hours based on florist timeZone
  //if the day crosses, EROS will restart the 110 minute interval for order acceptance
  //Depend on EROS/order status to show FORFEITED and not on countdown elapse
  const businessStartTime = getBusinessTimings(shopTimeZone, openTime, 9);
  const businessEndTime = getBusinessTimings(shopTimeZone, closeTime, 17);

  const orderAcceptanceInterval = parseInt(
    Environment.get("ORDER_ACCEPTANCE_INTERVAL", 110)
  );

  const isSameDayOrder = currentTime.isSame(orderCreationDate, "day");
  //const diffInDays = Math.ceil(currentTime.diff(orderCreationDate, "days"));
  // if (diffInDays !== 0 && diffInDays !== 1) return currentTime; //expired, end timer right

  const dateTimeToConsider = orderCreationDate.isBetween(
    businessStartTime,
    businessEndTime
  )
    ? orderCreationDate
    : orderCreationDate.isBefore(businessStartTime)
    ? businessStartTime
    : isSameDayOrder && orderCreationDate.isAfter(businessEndTime)
    ? currentTime
    : currentTime;

  // const countDownTimerStartTime = moment(dateTimeToConsider);
  const countDownTimerEndTime = moment(dateTimeToConsider).add(
    orderAcceptanceInterval,
    "minutes"
  );

  const withinShopTimings = currentTime.isBetween(
    businessStartTime,
    businessEndTime
  );

  return {
    countDownWithinShopEndTime: withinShopTimings
      ? countDownTimerEndTime
      : undefined,
    countDownEndTime: countDownTimerEndTime,
    countDownPauseTime: businessEndTime,
  };
};

export const mergeOrderResponse = (response = [], data = []) => {
  if (!data.length) return response;

  const ordersList = cloneDeep(data);
  const updatedListObj = {};
  ordersList.map((order) => {
    const { orderItemId } = order;
    updatedListObj[orderItemId] = order;
  });

  response.map((order) => {
    //If an order exist with orderItemId, it will replace. else will add.
    updatedListObj[order.orderItemId] = order;
  });

  const newOrdersList = Object.keys(updatedListObj).map(
    (key) => updatedListObj[key]
  );
  return newOrdersList;
};

const getBusinessTimings = (timeZone, timings, defaultHour) => {
  return timeZone
    ? moment()
        .tz(timeZone)
        .set({
          hour: parseInt(timings?.split(":")[0]) || defaultHour,
          minute: parseInt(timings?.split(":")[1]) || 0,
          second: parseInt(timings?.split(":")[2]) || 0,
        })
    : moment().set({
        hour: parseInt(timings?.split(":")[0]) || defaultHour,
        minute: parseInt(timings?.split(":")[1]) || 0,
        second: parseInt(timings?.split(":")[2]) || 0,
      });
};

export const isOrderEligibleforDeliveryDashboard = (
  order,
  statusCheck = false,
  routeScan = false
) => {
  const {
    orderSource,
    deliveryMethod,
    storePickupDateTime,
    latestDSRequestedStatus,
    direction,
    status,
  } = order;

  const eligibleStatusListForDeliveryDashboard = statusCheck
    ? [
        "ACKNOWLEDGED",
        "ACKNOWLEDGE_PRINT",
        "PRINTED",
        "DESIGN",
        "DESIGNED",
        "OUT_FOR_DELIVERY",
        ...(routeScan ? [] : ["DELIVERED"]),
      ].includes(status)
    : true;

  const isPickupOrder =
    ((orderSource === "DoorDash" || orderSource === "UberEats") &&
      !isEmpty(storePickupDateTime)) ||
    (orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP") ||
    deliveryMethod === "STORE_PICKUP";
  const isWalkInOrder = deliveryMethod === "WALK_IN";

  return (
    eligibleStatusListForDeliveryDashboard &&
    !isDSorder(latestDSRequestedStatus, status) &&
    !isPickupOrder &&
    !isWalkInOrder &&
    direction === "INBOUND"
  );
};
export const getDSEligibilityfailureReason = (order) => {
  const {
    orderSource,
    deliveryMethod,
    storePickupDateTime,
    direction,
    latestDSRequestedStatus,
    status,
  } = order;

  const isWalkInOrder = deliveryMethod === "WALK_IN";
  const isMOLorder =
    orderSource === "MOL" && deliveryMethod === "MOL_CUSTOMER_PICKUP";
  const isPickUpOrder = deliveryMethod === "STORE_PICKUP";

  const isDoordashOrder =
    orderSource === "DoorDash" && !isEmpty(storePickupDateTime);

  const isUbereatsOrder =
    orderSource === "UberEats" && !isEmpty(storePickupDateTime);

  if (isWalkInOrder) {
    return "WalkIn orders cannot be added to the route.";
  } else if (
    ![
      "ACKNOWLEDGED",
      "ACKNOWLEDGE_PRINT",
      "PRINTED",
      "DESIGN",
      "DESIGNED",
      "OUT_FOR_DELIVERY",
    ].includes(status)
  ) {
    return `${startCase(toLower(status))} orders cannot be added to the route`;
  } else if (isDSorder(latestDSRequestedStatus, status)) {
    return "Delivery Service submitted orders cannot be added to the route.";
  } else if (isUbereatsOrder) {
    return "UberEats orders cannot be added to the route.";
  } else if (isDoordashOrder) {
    return "DoorDash orders cannot be added to the route.";
  } else if (isMOLorder) {
    return "Mol orders cannot be added to the route.";
  } else if (isPickUpOrder) {
    return "PickUp orders cannot be added to the route.";
  } else if (direction !== "INBOUND") {
    return "Outbound orders cannot be added to the route.";
  }
  return "You can't add this order to Route. Order is not allowed by route.";
};

export const isActiveOrder = (status) => {
  return !["CANCELLED", "REJECTED", "FORFEITED", "ERROR", "FORWARDED"].includes(
    status
  );
};

export const orderDsRequestRerouting = ({ latestDSRequestedStatus }) => {
  return latestDSRequestedStatus === "REROUTING";
};

const orderDSRequestInProgress = (latestDSRequestedStatus) => {
  return ["SUCCESS", "REROUTING"].includes(latestDSRequestedStatus);
};
const orderDSDelivered = (latestDSRequestedStatus, orderStatus) => {
  return orderStatus === "DELIVERED" && latestDSRequestedStatus === "DELIVERED";
};
export const isDSorder = (latestDSRequestedStatus, orderStatus) => {
  return (
    orderDSRequestInProgress(latestDSRequestedStatus) ||
    orderDSDelivered(latestDSRequestedStatus, orderStatus)
  );
};

export const isLocalOrder = (dm) => {
  return ["FLORIST_DELIVERED", "STORE_PICKUP", "WALK_IN", "PHONE_OUT"].includes(
    dm
  );
};

export const replaceDeliverPhrase = (text) => {
  return text?.replace("Deliver by", "By") || "";
};
