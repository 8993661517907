export const IMAGES = {
  dashboard: require("./dashboard.png"),
  settings: require("./settings.png"),
  "order-calendar": require("./order-calendar.png"),
  "create-order": require("./create-order.png"),
  "create-new-order": require("./create-new-order.png"),
  orders: require("./current-orders.png"),
  "orders-new": require("./orders-new.png"),
  "staff-management": require("./staff-management.png"),
  "ftd-member-settings": require("./ftd-black.png"),
  "ftd-user-settings": require("./staff-management.png"),
  "ftd-mol-settings": require("./mercury-online.png"),
  "ftd-profile": require("./profile.png"),
  "ftd-logout": require("./logout.png"),
  profile: require("./profile.png"),
  "shop-settings": require("./shop-settings.png"),
  logout: require("./logout.png"),
  dollar: require("./dollar.png"),
  person: require("./person.png"),
  alert: require("./alert.png"),
  exclamation: require("./exclamation.png"),
  "mercury-online": require("./mercury-online.png"),
  "product-catalog": require("./product-catalog.png"),
  "product-codified": require("./product-codified.png"),
  "product-codified-white": require("./product-codified-white.png"),
  "product-drop-ship-delivered": require("./shipping-box-delivered.png"),
  "product-drop-ship-white": require("./shipping-box-white.png"),
  "product-drop-ship-grey": require("./shipping-box-dark-grey.png"),
  "product-drop-ship-progress": require("./shipping-box-green.png"),
  "product-drop-ship-cancelled": require("./shipping-box-light-grey.png"),
  "customer-directory": require("./customer-directory.png"),
  "gift-cards": require("./gift-cards.png"),
  "dollar-rounded": require("./dollar-rounded.png"),
  "quick-sale-enable": require("./quick-sale-on-filled.png"),
  "quick-sale-disable": require("./quick-sale-off-outline.png"),
  assigned: require("./assigned.png"),
  unassigned: require("./design-blue.png"),
  delete: require("./trash-can.png"),
  "open-merge": require("./merge-icon.png"),
  "close-merge": require("./merge-icon-white.png"),
  create: require("./create.png"),
  "settings-new": require("./gear.png"),
  "arrow-drop-down": require("./down-arrow.png"),
  "arrow-drop-down-blue": require("./down-arrow-blue.png"),
  "subscription-enable": require("./subscription-on-filled.png"),

  "approve-requests": require("./approve.png"),
  chat: require("./chat.png"),
  price: require("./price.png"),
  calendar: require("./calendar.png"),
  events: require("./calendar.png"),
  cancel: require("./cancelled.png"),
  "completed-chat": require("./completed-chat.png"),
  "expiring-chat": require("./expiring-chat.png"),
  "product-details": require("./product-details.png"),
  "delivery-black": require("./delivery-black.png"),
  deliveryDashboard: require("./deliveryDashboard.png"),
  "chat-black": require("./chat-black.png"),
  "view-responses": require("./view-responses.png"),
  search: require("./search.png"),
  payment: require("./payment.png"),
  "new-pickups": require("./pickup-bag.png"),
  "incoming-pickup": require("./pickup-bag-new.png"),
  "accepted-pickup": require("./pickup-bag-new.png"),
  "design-pickup": require("./pickup-bag-design.png"),
  "designed-pickup": require("./pickup-bag-designed.png"),
  "delivery-pickup": require("./pickup-bag.png"),
  "completed-pickup": require("./pickup-bag-completed.png"),
  "cancelled-pickup": require("./pickup-bag-cancelled.png"),

  "pay-later-new-pickups": require("./pickup-bag-pay-later.png"),
  "pay-later-incoming-pickup": require("./pickup-bag-pay-later-incoming-accepted.png"),
  "pay-later-accepted-pickup": require("./pickup-bag-pay-later-incoming-accepted.png"),
  "pay-later-design-pickup": require("./pickup-bag-pay-later-in-designed.png"),
  "pay-later-designed-pickup": require("./pickup-bag-pay-later-designed.png"),
  "pay-later-delivery-pickup": require("./pickup-bag-pay-later-delivery.png"),
  "pay-later-completed-pickup": require("./pickup-bag-pay-later-completed.png"),
  "pay-later-cancelled-pickup": require("./pickup-bag-pay-later-cancelled.png"),
  "pay-later-past-time": require("./pickup-bag-pay-later-past-time.png"),
  "payment-pending": require("./exclamation-alert.png"),

  "calendar-rounded": require("./calendar-rounded.png"),
  "submitted-clock-green": require("./submitted-clock-green.png"),

  incoming: require("./incoming.png"),
  outgoing: require("./outgoing.png"),
  expiring: require("./expiring.png"),
  expired: require("./expiring.png"),
  accepted: require("./accepted.png"),
  "accepted-event": require("./accepted.png"),
  design: require("./design.png"),
  designed: require("./designed-green.png"),
  delivery: require("./delivery.png"),
  completed: require("./completed.png"),
  cancelled: require("./cancelled.png"),
  rejected: require("./cancelled.png"),
  forfeited: require("./cancelled.png"),
  error: require("./cancelled.png"),
  "delivery-returned": require("./delivery-returned.png"),
  "delivery-not-started": require("./design-status-not-started.png"),

  ftdBg: require("./FTD_bg.png"),
  ftdRoundedLogo: require("./florist-os-rounded-logo.png"),
  ftdLogo: require("./florist-os-logo.png"),

  "master-florist": require("./master-florist.png"),
  "premier-florist": require("./premier-florist.png"),
  "florist-ad": require("./florist-ad.png"),
  print: require("./print.png"),
  printAgain: require("./print-again.png"),
  terminal: require("./terminal.png"),
  "beta-label": require("./beta-label.png"),

  "buttercup-chat-profile": require("./buttercup-chat-profile.png"),
  "map-delivery-location": require("./map-delivery-location.png"),
  "map-delivery-device-location": require("./map-delivery-device-location.png"),
  "delivery-service": require("./delivery-service.png"),
  "address-not-verified-exclamation": require("./address-not-verified-exclamation.png"),
  "exclamation-alert-white-border": require("./exclamation-alert-white-border.png"),
  "replace-filler": require("./outgoing-fulfillment-error.png"),
  "payment-error": require("./payment-error.png"),
  "inactive-user": require("./error-flower.png"),
  mapFolded: require("./map-folded.png"),

  "florist-shop": require("./florist-shop.png"),
  "ftd-admin-group": require("./ftd-admin-group.png"),
  "ftd-admin-shop": require("./ftd-admin-shop.png"),
  "ftd-admin-view": require("./ftd-admin-view.png"),
  help: require("./help.png"),
  "calendar-delivery": require("./calendar-delivery.png"),
  "average-order-value": require("./average-order-value.png"),
  reminders: require("./reminders.png"),
  edit: require("./edit.png"),
  "edit-zone": require("./edit-zone.png"),
  "edit-dark": require("./edit-dark.png"),
  "edit-rounded-square": require("./edit-rounded-square.png"),

  send: require("./send.png"),
  "chat-profile1": require("./chat-profile1.png"),
  "chat-profile2": require("./chat-profile2.png"),
  "delivery-status": require("./delivery-status.png"),
  "collapsed-section": require("./collapsed-section.png"),
  "expanded-section": require("./expanded-section.png"),
  fulfillment: require("./fulfillment.png"),
  "accept-deadline": require("./accept-deadline.png"),
  "accept-deadline-event": require("./accept-deadline.png"),
  "accept-deadline-white": require("./accept-deadline-white.png"),
  "date-change": require("./date-change.png"),
  "time-picker": require("./time-picker.png"),
  close: require("./cancelled.png"),
  confirm: require("./confirm.png"),
  "user-profile": require("./profile-white.png"),
  "swipe-right": require("./swipe-right.png"),
  "swipe-left": require("./swipe-left.png"),
  "chat-incomplete": require("./chat-incomplete.png"),
  "left-arrow": require("./left-arrow.png"),
  date: require("./date.png"),
  "forfeited-price": require("./forfeited-price.png"),
  "forfeited-incoming": require("./forfeited-incoming.png"),
  "forfeited-date": require("./forfeited-date.png"),
  "approve-request-denied": require("./approve-request-denied.png"),
  "approved-price": require("./approved-price.png"),
  "approved-date": require("./approved-date.png"),
  "delivery-error": require("./delivery-error.png"),
  synced: require("./synced.png"),
  notSynced: require("./notSynced.png"),
  "order-summary": require("./order-summary.png"),
  "preparing-order-vase": require("./preparing-order-vase.gif"),
  "quick-pick": require("./quick-pick.png"),
  home: require("./dashboard.png"),
  filter: require("./filter.png"),
  sort: require("./sort.png"),
  "three-dots": require("./three-dots.png"),
  "three-white-dots": require("./three-white-dots.png"),
  "delivery-service-accepted": require("./delivery-service-accepted.png"),
  "delivery-service-cancelled": require("./delivery-service-cancelled.png"),
  "delivery-service-design": require("./delivery-service-design.png"),
  "delivery-service-in design": require("./delivery-service-design.png"),
  "delivery-service-completed": require("./delivery-service-completed.png"),
  "delivery-service-incoming": require("./delivery-service-incoming.png"),
  "delivery-service-delivery": require("./delivery-service-delivery.png"),
  "delivery-service-rejected": require("./delivery-service-cancelled.png"),
  "delivery-service-red-hurry": require("./delivery-service-red-hurry.png"),
  "map-shop": require("./map-shop.png"),
  "pickup-bag-black": require("./pickup-bag-black.png"),
  doordash: require("./doordash.png"),
  "chat-grey": require("./chat-grey.png"),
  "checkbox-checked": require("./checkbox-checked-active.png"),
  "checkbox-unchecked-dark": require("./checkbox-uncheck-active.png"),
  "checkbox-unchecked": require("./checkbox-unchecked-inactive.png"),
  "incoming-chat": require("./chat-incoming.png"),
  "outgoing-chat": require("./chat-outgoing.png"),
  ubereats: require("./uber-eats-logo.png"),
  transfer: require("./transfer.png"),
  lock: require("./lock.png"),
  subscription: require("./subscription.png"),
  "delivery-service-error": require("./delivery-service-error.png"),
  "suspend-alert": require("./suspend-alert.png"),
  fingerprint: require("./fingerprint.png"),
  incomplete: require("./inComplete.png"),
  incompleted: require("./incompleted.png"),
  "select-shop": require("./select-shop.png"),
  "route-pin": require("./route-pin.png"),
  "map-pin": require("./map-pin.png"),
  export: require("./export.png"),
  reports: require("./reports.png"),
  routes: require("./delivery-black.png"),
  "confirm-delivery": require("./completed-orders-black.png"),
  "mercury-online-refunds": require("./mercury-online-refunds.png"),
  "mercury-online-refunds-black": require("./mercury-online-refunds-black.png"),
  "processing-refund": require("static/assets/incoming.png"),
  "drag-drop-icon": require("static/assets/drag-drop-icon.png"),
  "design-black": require("./design-black.png"),
  download: require("./download.png"),
  back_left_nav_arrow: require("./back-left-nav-arrow.png"),
  back_right_nav_arrow: require("./back-right-nav-arrow.png"),
  qr_code_scan: require("./qr-code-scan.png"),
  active_scanner: require("./active-scanner.png"),
  kebab_menu_icon: require("./kebab-three-dots-vertical-blue.png"),
  "customised-notification": require("./completed-orders.png"),
  "default-notification": require("./notifications-disabled.png"),
  "draft-orders": require("./draft-orders.png"),

  "timed-delivery-delivered": require("./timed-delivery-delivered.png"),
  "timed-delivery-progress": require("./timed-delivery-progress.png"),
  "timed-delivery-cancelled": require("./timed-delivery-cancelled.png"),
  "designed-blue": require("./designed-blue.png"),
  "copy-content": require("./copy-content.png"),
  "zoom-in": require("./zoom-in.png"),
  "map-pin-circle": require("./map-pin-circle.png"),
  "check-mark-green-fill-color": require("./address-verification-check-mark-fill-color.png"),
  "check-mark-filled-in-green": require("./check-mark-filled-in-green.png"),
  "grey-filled-in-circle": require("./grey-filled-in-circle.png"),

  "expand-circle": require("./expand-circle.png"),
  "collapse-circle": require("./collapse-circle.png"),
  "rejected-back": require("./rejected-back.png"),
  "followup-flag-off": require("./followup-flag-off.png"),
  "followup-flag-on": require("./followup-flag-on.png"),
  message: require("./message.png"),

  help1: require("./help/Picture1.png"),
  help2: require("./help/Picture2.png"),
  help3: require("./help/Picture3.png"),
  help4: require("./help/Picture4.png"),
  help5: require("./help/Picture5.png"),
  help6: require("./help/Picture6.png"),
  help7: require("./help/Picture7.png"),
  help8: require("./help/Picture8.png"),
  help9: require("./help/Picture9.png"),
  help10: require("./help/Picture10.png"),
  help11: require("./help/Picture11.png"),
  help12: require("./help/Picture12.png"),
  help13: require("./help/Picture13.png"),
  orderSummaryReport: require("./help/releases/order_summary.png"),
  paymentsRefund: require("./help/releases/payments_refund.png"),
  permissions: require("./help/releases/permissions.png"),
  editRecipientAddress: require("./help/releases/edit_recipient_address.png"),
  editRecipientName: require("./help/releases/edit_recipient_name.png"),
  newNav: require("./help/releases/new_nav.png"),
  orderDetailPrint: require("./help/releases/order_detail_print.png"),
  orderMap: require("./help/releases/order_map.png"),
  ordersCollapsed: require("./help/releases/orders_collapsed.png"),
  undeliverableAction: require("./help/releases/undeliverable_action.png"),
  "map-draw": require("static/assets/map-draw.png"),
  layerIcon: require("./layer.png"),

  interactiveMap: require("./help/releases/interactiveMap.png"),
  drawnMap: require("./help/releases/drawn_map.png"),
  floristCoveredZips: require("./help/releases/florist_covered_zips.png"),
  deliveryFeeException: require("./help/releases/delivery_fee_exception.png"),
  enableHouseAccount: require("./help/releases/enable_house_account.png"),
  invoicePayment: require("./help/releases/invoice_payment.png"),
  currentBalance: require("./help/releases/current_balance.png"),
  integrationSettings: require("./help/releases/integration_settings.png"),
  orderTypeFilter: require("./help/releases/order_type_filter.png"),
  nonMHQOrderPrint: require("./help/releases/non_mhq_order_print.png"),
  legalSizeInvoicePrint: require("./help/releases/legal_size_invoice_print.png"),
  copySale: require("./help/releases/copy_sale.png"),
  avsCanadaSupport: require("./help/releases/avs_canada_support.png"),
  defaultLocation: require("./help/releases/default_location.png"),
  reportsHouseAccStat: require("./help/releases/reports_houseaccount_statements.png"),
  customerHouseAccStat: require("./help/releases/customer_houseaccount_statements.png"),
  editInteractiveMap: require("./help/releases/edit_interactiveMap.png"),
  driverAssist: require("./help/releases/driver_assist.png"),

  COInvoiceStatement: require("./help/releases/co_invoice_statement.png"),
  COOrderDetailReport: require("./help/releases/co_order_detail_report.png"),
  CORetailDeliveryFee: require("./help/releases/co_retail_delivery_fee.png"),
  salesTaxReport: require("./help/releases/sales_tax_report.png"),

  CreateSubscription: require("./help/releases/create_subscription.png"),
  CustomerDirSubscription: require("./help/releases/customer_dir_subscription.png"),
  OccasionReminder: require("./help/releases/occasion_reminder.png"),
  SubscriptionOrders: require("./help/releases/subscription_orders.png"),
  SalesTax: require("./help/releases/sales_tax.png"),
  DeliveryDashboard: require("./help/releases/delivery_dashboard.png"),
  SubscriptionIcon: require("./help/releases/subscription_icon.png"),

  auto_route_settings: require("./help/releases/auto_route_settings.png"),
  auto_routes: require("./help/releases/auto_routes.png"),
  Shop_Settings_Mercury_Network: require("./help/releases/Shop_Settings_Mercury_Network.png"),
  Auto_Print_Settings: require("./help/releases/Auto_Print_Settings.png"),
  delivery_service_tasks: require("./help/releases/delivery_service_tasks.png"),
  multiple_products_order: require("./help/releases/multiple_products_order.png"),
  create_order_redesign: require("./help/releases/create_order_redesign.png"),
  order_journey_redesign: require("./help/releases/order_journey_redesign.png"),
  order_summary_redesign: require("./help/releases/order_summary_redesign.png"),
  order_details_product_redesign: require("./help/releases/order_details_product_redesign.png"),
  order_details_delivery_redesign: require("./help/releases/order_details_delivery_redesign.png"),
  order_listing_redesign: require("./help/releases/order_listing_redesign.png"),
  cash_drawer_create_order: require("./help/releases/cash_drawer_create_order.png"),
  cash_drawer_balance: require("./help/releases/cash_drawer_balance.png"),
  cash_drawer_session: require("./help/releases/cash_drawer_session.png"),
  cash_drawer_end_session: require("./help/releases/cash_drawer_end_session.png"),
  cancel_order: require("./help/releases/cancel_order.png"),
  refund_cancel_order: require("./help/releases/refund_cancel_order.png"),
  quick_sale: require("./help/releases/quick_sale.png"),
  location_search: require("./help/releases/location_search.png"),
  delivery_dashboard_driver: require("./help/releases/delivery_dashboard_driver.png"),
  delivery_dashboard_driver_notification: require("./help/releases/delivery_dashboard_driver_notification.png"),
  pickup_manifest: require("./help/releases/pickup_manifest.png"),
  customer_profile_emails: require("./help/releases/customer_profile_emails.png"),
  customer_profile_activity: require("./help/releases/customer_profile_activity.png"),
  delivery_dashboard_ds: require("./help/releases/delivery_dashboard_ds.png"),
  delivery_dashboard_routes: require("./help/releases/delivery_dashboard_routes.png"),
  payment_error_handling: require("./help/releases/payment_error_handling.png"),
  promo_code_tracking: require("./help/releases/promo_code_tracking.png"),
  relay_fee_setting: require("./help/releases/relay_fee_setting.png"),
  zones_filters: require("./help/releases/zones_filters.png"),
  co_page_breadcrumb: require("./help/releases/co_page_breadcrumb.png"),
  invoice_with_design_notes: require("./help/releases/invoice_with_design_notes.png"),
  co_product_catalog_filters_1: require("./help/releases/co_product_catalog_filters_1.png"),
  co_product_catalog_filters_2: require("./help/releases/co_product_catalog_filters_2.png"),
  customer_insights: require("./help/releases/customer_insights.png"),
  resell_optimization_1: require("./help/releases/resell_optimization_1.png"),
  resell_optimization_2: require("./help/releases/resell_optimization_2.png"),
  df_incoming_wire_orders: require("./help/releases/df_incoming_wire_orders.png"),
  delivery_service_reporting: require("./help/releases/delivery_service_reporting.png"),
  multi_order_checkout_1: require("./help/releases/multi_order_checkout_1.png"),
  multi_order_checkout_2: require("./help/releases/multi_order_checkout_2.png"),
  tax_rules: require("./help/releases/tax_rules.png"),
  work_sheet_1: require("./help/releases/work_sheet_1.png"),
  work_sheet_2: require("./help/releases/work_sheet_2.png"),
  sms_order_notifications_1: require("./help/releases/sms_order_notifications_1.png"),
  sms_order_notifications_2: require("./help/releases/sms_order_notifications_2.png"),
  rush_delivery_upcharge_1: require("./help/releases/rush_delivery_upcharge_1.png"),
  rush_delivery_upcharge_2: require("./help/releases/rush_delivery_upcharge_2.png"),
  rush_delivery_upcharge_3: require("./help/releases/rush_delivery_upcharge_3.png"),
  rush_delivery_upcharge_4: require("./help/releases/rush_delivery_upcharge_4.png"),
  rush_timed_delivery_routing_1: require("./help/releases/rush_timed_delivery_routing_1.png"),
  rush_timed_delivery_routing_2: require("./help/releases/rush_timed_delivery_routing_2.png"),
  ad_hoc_stops_1: require("./help/releases/ad_hoc_stops_1.png"),
  ad_hoc_stops_2: require("./help/releases/ad_hoc_stops_2.png"),
  ad_hoc_stops_3: require("./help/releases/ad_hoc_stops_3.png"),
  ad_hoc_stops_4: require("./help/releases/ad_hoc_stops_4.png"),
  ad_hoc_stops_5: require("./help/releases/ad_hoc_stops_5.png"),
  quick_books_integration: require("./help/releases/quick_books_integration.png"),
  house_accounts_open_item_1: require("./help/releases/house_accounts_open_item_1.png"),
  house_accounts_open_item_2: require("./help/releases/house_accounts_open_item_2.png"),
  house_accounts_credit_debit_memo_1: require("./help/releases/house_accounts_credit_debit_memo_1.png"),
  house_accounts_credit_debit_memo_2: require("./help/releases/house_accounts_credit_debit_memo_2.png"),
  house_accounts_credit_debit_memo_3: require("./help/releases/house_accounts_credit_debit_memo_3.png"),
  inDesign_and_designed_statueses_1: require("./help/releases/inDesign_and_designed_statueses_1.png"),
  inDesign_and_designed_statueses_2: require("./help/releases/inDesign_and_designed_statueses_2.png"),
  inDesign_and_designed_statueses_3: require("./help/releases/inDesign_and_designed_statueses_3.png"),
  design_center_1: require("./help/releases/design_center_1.png"),
  design_center_2: require("./help/releases/design_center_2.png"),
  design_center_3: require("./help/releases/design_center_3.png"),
  design_center_4: require("./help/releases/design_center_4.png"),
  design_center_5: require("./help/releases/design_center_5.png"),
  design_center_6: require("./help/releases/design_center_6.png"),
  design_center_7: require("./help/releases/design_center_7.png"),
  design_center_8: require("./help/releases/design_center_8.png"),
  design_center_9: require("./help/releases/design_center_9.png"),
  design_center_10: require("./help/releases/design_center_10.png"),
  design_center_11: require("./help/releases/design_center_11.png"),
  customer_lifetime_value_in_reports: require("./help/releases/customer_lifetime_value_in_reports.png"),
  product_sales_in_reports: require("./help/releases/product_sales_in_reports.png"),
  product_comparison_in_reports: require("./help/releases/product_comparison_in_reports.png"),
  sales_summary_in_reports: require("./help/releases/sales_summary_in_reports.png"),
  fulfillment_dashboard_1: require("./help/releases/fulfillment_dashboard_1.png"),
  fulfillment_dashboard_2: require("./help/releases/fulfillment_dashboard_2.png"),
  ad_hoc_tri_fold_enclosure_card_1: require("./help/releases/ad_hoc_tri_fold_enclosure_card_1.png"),
  ad_hoc_tri_fold_enclosure_card_2: require("./help/releases/ad_hoc_tri_fold_enclosure_card_2.png"),
  ad_hoc_tri_fold_enclosure_card_3: require("./help/releases/ad_hoc_tri_fold_enclosure_card_3.png"),
  order_summary_report_enhancements: require("./help/releases/order_summary_report_enhancements.png"),
  email_and_sms_customizations_1: require("./help/releases/email_and_sms_customizations_1.png"),
  email_and_sms_customizations_2: require("./help/releases/email_and_sms_customizations_2.png"),
  gift_cards_1: require("./help/releases/gift_cards_1.png"),
  gift_cards_2: require("./help/releases/gift_cards_2.png"),
  gift_cards_3: require("./help/releases/gift_cards_3.png"),
  multi_location_routes_1: require("./help/releases/multi_location_routes_1.png"),
  multi_location_routes_2: require("./help/releases/multi_location_routes_2.png"),
  multi_location_routes_3: require("./help/releases/multi_location_routes_3.png"),
  order_detail_sidecar_new: require("./help/releases/order_detail_sidecar_new.png"),
  order_detail_sidecar_old: require("./help/releases/order_detail_sidecar_old.png"),
  order_status_page_1: require("./help/releases/order_status_page_1.png"),
  order_status_page_2: require("./help/releases/order_status_page_2.png"),
  pay_later_orders_1: require("./help/releases/pay_later_orders_1.png"),
  pay_later_orders_2: require("./help/releases/pay_later_orders_2.png"),
  phone_out_order: require("./help/releases/phone_out_order.png"),
  push_notifications_customizations: require("./help/releases/push_notifications_customizations.png"),
  qr_codes_1: require("./help/releases/qr_codes_1.png"),
  qr_codes_2: require("./help/releases/qr_codes_2.png"),
  qr_codes_3: require("./help/releases/qr_codes_3.png"),
  qr_codes_4: require("./help/releases/qr_codes_4.png"),
  qr_codes_5: require("./help/releases/qr_codes_5.png"),
  qr_codes_6: require("./help/releases/qr_codes_6.png"),
  qr_codes_7: require("./help/releases/qr_codes_7.png"),
  tax_jurisdiction_settings: require("./help/releases/tax_jurisdiction_settings.png"),
  sales_tax_jurisdictional_breakdowns: require("./help/releases/sales_tax_jurisdictional_breakdowns.png"),
  faq_one: require("./help/training-materials/faq/data-migration-for-third-party-integration/one.png"),
  faq_two: require("./help/training-materials/faq/data-migration-for-third-party-integration/two.png"),
  faq_three: require("./help/training-materials/faq/data-migration-for-third-party-integration/three.png"),
  automatic_browser_update: require("./help/releases/automatic_browser_update.png"),
  invoice_without_special_instruction: require("./help/releases/invoice_without_special_instruction.png"),
  invoice_with_special_instruction: require("./help/releases/invoice_with_special_instruction.png"),
  order_status_link_email: require("./help/releases/order_status_link_email.png"),
  standard_entitlement_with_reports: require("./help/releases/standard_entitlement_with_reports.png"),
  enable_email_and_sms: require("./help/releases/enable_email_and_sms.png"),
  order_copy_logging: require("./help/releases/order_copy_logging.png"),
  save_route_draft: require("./help/releases/save_route_draft.png"),
  help_page_old_1: require("./help/releases/help_page_old_1.png"),
  help_page_old_2: require("./help/releases/help_page_old_2.png"),
  help_page_new_1: require("./help/releases/help_page_new_1.png"),
  help_page_new_2: require("./help/releases/help_page_new_2.png"),
  help_page_new_3: require("./help/releases/help_page_new_3.png"),
  verint_feedback_old: require("./help/releases/verint_feedback_old.png"),
  verint_feedback_new_1: require("./help/releases/verint_feedback_new_1.png"),
  verint_feedback_new_2: require("./help/releases/verint_feedback_new_2.png"),
  create_order_icon_old: require("./help/releases/create_order_old.png"),
  create_order_icon_new: require("./help/releases/create_order_new.png"),
  auto_print_old: require("./help/releases/auto_print_old.png"),
  auto_print_new: require("./help/releases/auto_print_new.png"),
  duplicate_product: require("./help/releases/duplicate_product.png"),
  order_reorder_warning: require("./help/releases/order_reorder_warning.png"),
  FTD_DS_TC: require("./help/releases/FTD_DS_TC.png"),
  FTD_DS_create_order_request: require("./help/releases/FTD_DS_create_order_request.png"),
  FTD_DS_create_request: require("./help/releases/FTD_DS_create_request.png"),
  approve_date_price_request: require("./help/releases/approve_date_price_request.png"),
  outgoing_date_price_request: require("./help/releases/outgoing_date_price_request.png"),
  date_price_response: require("./help/releases/date_price_response.png"),
  route_multi_select_orders: require("./help/releases/route_multi_select_orders.png"),
  search_date_filters_route: require("./help/releases/search_date_filters_route.png"),
  zip_15mile_route: require("./help/releases/zip_15mile_route.png"),
  trifold_new_look: require("./help/releases/trifold_new_look.png"),
  order_invoices: require("./help/releases/order_invoices.png"),
  invoices_section34: require("./help/releases/invoices_section34.png"),
  invoices_section34_customise: require("./help/releases/invoices_section34_customise.png"),
  add_multiple_contacts_create_order: require("./help/releases/add_multiple_contacts_create_order.png"),
  add_multiple_contacts_customer: require("./help/releases/add_multiple_contacts_customer.png"),
  contact_order_details: require("./help/releases/contact_order_details.png"),
  email_invoice_help: require("./help/releases/email_invoice_help.png"),
  qr_shop_settings: require("./help/releases/qr_shop_settings.png"),
  qr_code_page: require("./help/releases/qr_code_page.png"),
  auto_print_option: require("./help/releases/auto_print_option.png"),
  order_printed: require("./help/releases/order_printed.png"),
  font_card_message: require("./help/releases/font_card_message.png"),
  mark_as_read: require("./help/releases/mark_as_read.png"),
  filter_orders_customer_name: require("./help/releases/filter_orders_customer_name.png"),
  debit_memo_transaction: require("./help/releases/debit_memo_transaction.png"),
  house_account_statement: require("./help/releases/house_account_statement.png"),
  attempted_delivery_sidecar: require("./help/releases/attempted_delivery_sidecar.png"),
  attempted_delivery_meatballs: require("./help/releases/attempted_delivery_meatballs.png"),
  route_zip_outline: require("./help/releases/route_zip_outline.png"),
  route_dnd_help: require("./help/releases/route_dnd_help.png"),
  route_map_filters: require("./help/releases/route_map_filters.png"),
  delivery_UI_enhancements: require("./help/releases/delivery_UI_enhancements.png"),
  FTD_DS_legal: require("./help/releases/FTD_DS_legal.png"),
  customer_merge_purge1: require("./help/releases/customer_merge_purge1.png"),
  customer_merge_purge2: require("./help/releases/customer_merge_purge2.png"),
  "apple-pay": require("./apple-pay.png"),
  customer_entry_error1: require("./help/releases/customer_entry_error1.png"),
  customer_entry_error2: require("./help/releases/customer_entry_error2.png"),
  enhanced_customer_search1: require("./help/releases/enhanced_customer_search1.png"),
  enhanced_customer_search2: require("./help/releases/enhanced_customer_search2.png"),
  sales_tax_report_enhancement: require("./help/releases/sales_tax_report_enhancement.png"),
  delete_card_popup1: require("./help/releases/delete_card_popup1.png"),
  delete_card_popup2: require("./help/releases/delete_card_popup2.png"),
  order_details_header: require("./help/releases/order_details_header.png"),
  recalculate_tax_popup: require("./help/releases/recalculate_tax_popup.png"),
  tax_rules1: require("./help/releases/tax_rules1.png"),
  tax_rules2: require("./help/releases/tax_rules2.png"),
  save_cvv_checkout: require("./help/releases/save_cvv_checkout.png"),
  add_contact_email: require("./help/releases/add_contact_email.png"),
  hover_desc_1: require("./help/releases/hover_desc_1.png"),
  hover_desc_2: require("./help/releases/hover_desc_2.png"),
  hover_desc_3: require("./help/releases/hover_desc_3.png"),
  email_invoice_1: require("./help/releases/email_invoice_1.png"),
  email_invoice_2: require("./help/releases/email_invoice_2.png"),
  fix_facility_1: require("./help/releases/fix_facility_1.png"),
  fix_facility_2: require("./help/releases/fix_facility_2.png"),

  aged_analysis_report_help_1: require("./help/releases/aged_analysis_report_help_1.png"),
  aged_analysis_report_help_2: require("./help/releases/aged_analysis_report_help_2.png"),
  aged_analysis_report_help_3: require("./help/releases/aged_analysis_report_help_3.png"),
  aged_analysis_report_help_4: require("./help/releases/aged_analysis_report_help_4.png"),
  alacarte_billing_help: require("./help/releases/alacarte_billing_help.png"),
  CC_settle_help_1: require("./help/releases/CC_settle_help_1.png"),
  CC_settle_help_2: require("./help/releases/CC_settle_help_2.png"),
  CC_settle_help_3: require("./help/releases/CC_settle_help_3.png"),
  CC_settle_help_4: require("./help/releases/CC_settle_help_4.png"),
  design_center_help: require("./help/releases/design_center_help.png"),
  draft_order_help_1: require("./help/releases/draft_order_help_1.png"),
  draft_order_help_2: require("./help/releases/draft_order_help_2.png"),
  funeral_log_help_1: require("./help/releases/funeral_log_help_1.png"),
  funeral_log_help_2: require("./help/releases/funeral_log_help_2.png"),
  pay_later_help: require("./help/releases/pay_later_help.png"),
  phone_out_help: require("./help/releases/phone_out_help.png"),
  price_date_change_help: require("./help/releases/price_date_change_help.png"),
  redelivery_help: require("./help/releases/redelivery_help.png"),
  rerouting_help: require("./help/releases/rerouting_help.png"),
  split_payment_help_1: require("./help/releases/split_payment_help_1.png"),
  split_payment_help_2: require("./help/releases/split_payment_help_2.png"),
  timed_delivery_help_1: require("./help/releases/timed_delivery_help_1.png"),
  timed_delivery_help_2: require("./help/releases/timed_delivery_help_2.png"),
  upfront_delivery_charges_help_1: require("./help/releases/upfront_delivery_charges_help_1.png"),
  upfront_delivery_charges_help_2: require("./help/releases/upfront_delivery_charges_help_2.png"),
  print_enhancement: require("./help/releases/print_enhancement.png"),
  mileage_create_order: require("./help/releases/mileage_create_order.png"),
  delivery_info_on_trifold_1: require("./help/releases/delivery_info_on_trifold_1.png"),
  delivery_info_on_trifold_2: require("./help/releases/delivery_info_on_trifold_2.png"),
  enhanced_delivery_instruction: require("./help/releases/enhanced_delivery_instruction.png"),
  sidecar_retention: require("./help/releases/sidecar_retention.png"),
  recipe_addition_and_selection: require("./help/releases/recipe_addition_and_selection.png"),
  addon_help: require("./help/releases/addon_help.png"),
  multi_shop_route: require("./help/releases/multi_shop_route.png"),
  order_listing_sort: require("./help/releases/order_listing_sort.png"),
  route_enhancements: require("./help/releases/route_enhancements.png"),
  split_product_help1: require("./help/releases/split_product_help1.png"),
  split_product_help2: require("./help/releases/split_product_help2.png"),
  zone_name: require("./help/releases/zone_name.png"),
  product_flower: require("./orders-new/product-flower.jpeg"),
  pivot_menu: require("./orders-new/pivot-menu.png"),
  accepted_new: require("./orders-new/accepted.png"),
  completed_new: require("./orders-new/completed.png"),
  design_new: require("./orders-new/design.png"),
  designed_new: require("./orders-new/designed.png"),
  delivery_new: require("./orders-new/delivery.png"),
  vector_new: require("./orders-new/vector_new.png"),
  meatballs_new: require("./orders-new/meatballs_new.png"),
  reset: require("./orders-new/reset.png"),
  approve_request_new: require("./orders-new/approve_request_new.png"),
  column_filter: require("./orders-new/column_filter.png"),
  subscription_template_create_order: require("./help/releases/subscription_template_create_order.png"),
  subscription_custom_end_date: require("./help/releases/subscription_custom_end_date.png"),
  pause_subscription: require("./help/releases/pause_subscription.png"),
  terminal_without_ip: require("./help/releases/terminal_without_ip.png"),
  complete_route: require("./help/releases/complete_route.png"),
  end_route: require("./help/releases/end_route.png"),
  end_route_menu: require("./help/releases/end_route_menu.png"),
  driver_dashboard_noedit: require("./help/releases/driver_dashboard_noedit.png"),
  driver_dashboard_edit: require("./help/releases/driver_dashboard_edit.png"),
  add_to_route_dropdown: require("./help/releases/add_to_route_dropdown.png"),
  remote_printing: require("./help/releases/remote_printing.png"),
  contact_house_ac_statement: require("./help/releases/contact_house_ac_statement.png"),
  email_house_account_invoice: require("./help/releases/email_house_account_invoice.png"),
  enabled_setting_customer_directory: require("./help/releases/enabled_setting_customer_directory.png"),
  disabled_setting_customer_directory: require("./help/releases/disabled_setting_customer_directory.png"),
  subscription_sidecar: require("./help/releases/subscription_sidecar.png"),
  date_format_standardization: require("./help/releases/date_format_standardization.png"),
};

export const DIMENSIONS = {
  delivery: {
    imageWidth: 30,
    imageHeight: 20,
  },
  design: {
    imageWidth: 30,
    imageHeight: 30,
  },
  designed: {
    imageWidth: 30,
    imageHeight: 30,
  },
  chat: {
    imageWidth: 22,
    imageHeight: 20,
  },
  "completed-chat": {
    imageWidth: 24,
    imageHeight: 22,
  },
  "florist-shop": {
    imageWidth: 26,
    imageHeight: 23,
  },
  price: {
    imageWidth: 25,
    imageHeight: 25,
  },
  "expiring-chat": {
    imageWidth: 24,
    imageHeight: 22,
  },
  incoming: {
    imageWidth: 23,
    imageHeight: 23,
  },
  expiring: {
    imageWidth: 25,
    imageHeight: 25,
  },
  date: {
    imageWidth: 25,
    imageHeight: 25,
  },
  "accepted-event": {
    imageWidth: 25,
    imageHeight: 25,
  },
  completed: {
    imageWidth: 27,
    imageHeight: 28,
  },
  cancel: {
    imageWidth: 21,
    imageHeight: 21,
  },
  rejected: {
    imageWidth: 21,
    imageHeight: 21,
  },
  dashboard: {
    imageWidth: 35,
    imageHeight: 31,
  },
  "new-pickups": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "incoming-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "accepted-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "delivery-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "completed-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "cancelled-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "design-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "designed-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "pay-later-new-pickups": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "pay-later-incoming-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "pay-later-accepted-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "pay-later-delivery-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "pay-later-completed-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "pay-later-cancelled-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "pay-later-design-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "pay-later-designed-pickup": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "pay-later-past-time": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "pay-later-payment-pending": {
    imageWidth: 25,
    imageHeight: 25,
  },
  incomplete: {
    imageWidth: 30,
    imageHeight: 20,
  },
  deliveryDashboard: {
    imageWidth: 30,
    imageHeight: 36,
  },
  settings: {
    imageHeight: 25,
    imageWidth: 25,
  },

  "product-drop-ship-delivered": {
    imageWidth: 35,
    imageHeight: 30,
  },
  "product-drop-ship-white": {
    imageWidth: 35,
    imageHeight: 30,
  },
  "product-drop-ship-grey": {
    imageWidth: 35,
    imageHeight: 30,
  },
  "product-drop-ship-cancelled": {
    imageWidth: 35,
    imageHeight: 30,
  },
  "product-drop-ship-progress": {
    imageWidth: 35,
    imageHeight: 30,
  },
  "timed-delivery-delivered": {
    imageWidth: 35,
    imageHeight: 30,
  },
  "timed-delivery-progress": {
    imageWidth: 35,
    imageHeight: 30,
  },
  "timed-delivery-cancelled": {
    imageWidth: 35,
    imageHeight: 30,
  },
  "map-pin": {
    imageWidth: 30,
    imageHeight: 30,
  },
  kebab_menu_icon: {
    imageWidth: 11,
    imageHeight: 28,
  },
  "rejected-back": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "accept-deadline-event": {
    imageWidth: 25,
    imageHeight: 25,
  },
  "submitted-clock-green": {
    imageWidth: 25,
    imageHeight: 25,
  },
};

export default IMAGES;
