import { isEnabledForMember } from "library/utils/featureAvailability";

export const getMHQUIConfig = (isDesktop = true) => [
  {
    title: "FAQ",
    showDivider: true,
    componentName: "CommonQuestions",
    subAccordians: [
      {
        key: "dataMigration",
        subTitle: "Data Migration for Third Party Integration",
        fileName: "Data Migration for Third Party Integration.pdf",
        content: [
          {
            image: "faq_one",
            imgWidth: isDesktop ? 762 : 254,
            imgHeight: isDesktop ? 1081 : 360,
          },
          {
            image: "faq_two",
            imgWidth: isDesktop ? 768 : 256,
            imgHeight: isDesktop ? 1079 : 360,
          },
          {
            image: "faq_three",
            imgWidth: isDesktop ? 752 : 251,
            imgHeight: isDesktop ? 1067 : 356,
          },
        ],
      },
      {
        key: "loginlogout",
        subTitle: "Login & Logout",
        fileName: "Login & Logout.pdf",
        needEncoding: true,
      },
      {
        key: "pushNotifications",
        subTitle: "Push Notifications",
        fileName: "Push Notifications.pdf",
      },
      {
        key: "phoneinorder",
        subTitle: "Reporting a Phoned In order (Report of Orders Filled)",
        fileName: "Reporting a Phoned In order (Report of Orders Filled).pdf",
      },
      {
        key: "suspendResume",
        subTitle: "Suspend & Resume",
        fileName: "Suspend & Resume.pdf",
        needEncoding: true,
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Introduction & Onboarding",
    componentName: {},
    subAccordians: [
      {
        key: "introtoMHQ",
        subTitle: "Introduction to Mercury HQ",
        fileName: "Introduction to Mercury HQ.pdf",
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Dashboard",
    componentName: {},
    subAccordians: [
      // {
      //   subTitle: "Chats Request and Responses",
      //   fileName: "Part 6 Chats Request and Responses.pdf",
      // },
      {
        key: "dashboard",
        subTitle: "Home Dashboard",
        fileName: "Home Dashboard.pdf",
      },
      // {
      //   subTitle: "Working Orders Todays and Future",
      //   fileName: "Part 7 Working Orders Todays and Future.pdf",
      // },
    ],
    defaultOpen: false,
  },
  {
    title: "Create Order",
    componentName: {},
    subAccordians: [
      {
        key: "createOrder",
        subTitle: "Create an Order",
        fileName: "Create an Order.pdf",
      },
      {
        key: "customerInsights",
        subTitle: "Customer Insights & Recipient Search",
        fileName: "Customer Insights & Recipient Search.pdf",
        needEncoding: true,
      },
      {
        key: "draftOrders",
        subTitle: "Draft Orders",
        fileName: "Draft Orders.pdf",
        needEncoding: true,
      },
      {
        key: "emailnotifications",
        subTitle: "Email Notifications",
        fileName: "Email Notifications.pdf",
      },
      {
        key: "emailhouseaccountnotification",
        subTitle: "Email House Account Invoice",
        fileName: "Email House Account Invoice.pdf",
      },
      {
        key: "funeralLog",
        subTitle: "Funeral Log",
        fileName: "Funeral Log.pdf",
      },
      {
        key: "internationalAndWireOrders",
        subTitle: "International & Wire Orders",
        fileName: "International & Wire Orders.pdf",
        needEncoding: true,
      },
      {
        key: "localOrders",
        subTitle: "Local Orders",
        fileName: "Local Orders.pdf",
      },
      {
        key: "multiOrderCheckout",
        subTitle: "Multi Order Checkout",
        fileName: "Multi Order Checkout.pdf",
      },
      {
        key: "payLater",
        subTitle: "Pay Later Orders",
        fileName: "Pay Later Orders.pdf",
      },
      {
        key: "phoneOut",
        subTitle: "Phone Out Orders",
        fileName: "Phone Out Orders.pdf",
      },
      {
        key: "quickSale",
        subTitle: "Quick Sale",
        fileName: "Quick Sale.pdf",
      },
      {
        key: "rushDelivery",
        subTitle: "Rush & Timed Delivery",
        fileName: "Rush & Timed Delivery.pdf",
        needEncoding: true,
      },
      {
        key: "splitPayment",
        subTitle: "Split Payment",
        fileName: "Split Payment.pdf",
      },
      {
        key: "smsnotifications",
        subTitle: "SMS Notifications",
        fileName: "SMS Notifications.pdf",
      },
      {
        key: "triFoldCard",
        subTitle: "Tri-Fold Card",
        fileName: "Tri-Fold Card.pdf",
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Orders",
    componentName: {},
    subAccordians: [
      {
        key: "copyOrder",
        subTitle: "Copy Order",
        fileName: "Copy Order.pdf",
      },
      {
        key: "deliveryDateOverride",
        subTitle: "Delivery Date Override",
        fileName: "Delivery Date Override.pdf",
      },
      {
        key: "workflowEnhanced",
        subTitle: "Design Center",
        fileName: "Design Center.pdf",
      },
      {
        key: "emailInvoice",
        subTitle: "Email Invoice",
        fileName: "Email Invoice.pdf",
      },
      {
        key: "flagOrdersForFollowup",
        subTitle: "Flag Orders for Followup",
        fileName: "Flag Orders for Followup.pdf",
      },
      {
        key: "invoices",
        subTitle: "Invoices",
        fileName: "Invoices.pdf",
      },
      {
        key: "orders",
        subTitle: "Orders",
        fileName: "Orders.pdf",
      },
      {
        key: "orderNotes",
        subTitle: "Order Notes",
        fileName: "Order Notes.pdf",
      },
      {
        key: "orderStatus",
        subTitle: "Order Status Page",
        fileName: "Order Status Page.pdf",
      },
      {
        key: "workingOrders",
        subTitle: "Working Orders Accepting & Rejecting",
        fileName: "Working Orders Accepting and Rejecting.pdf",
      },
      {
        key: "worksheet",
        subTitle: "Worksheet Printout",
        fileName: "Worksheet Printout.pdf",
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Delivery",
    componentName: {},
    subAccordians: [
      {
        key: "adhocStops",
        subTitle: "Ad-Hoc Stops",
        fileName: "Ad-Hoc Stops.pdf",
      },
      {
        key: "advancedRouteOptimizationManual",
        subTitle: "Advanced Route Optimization",
        fileName: "Advanced Route Optimization.pdf",
      },
      {
        key: "canadaDelivery",
        subTitle: "Canada Delivery",
        fileName: "Canada Delivery.pdf",
      },
      {
        key: "deliveryDashboard",
        subTitle: "Delivery Dashboard",
        fileName: "Delivery Dashboard.pdf",
      },
      {
        key: "driverDeliveryApp",
        subTitle: "Driver Delivery App",
        fileName: "Driver Delivery App.pdf",
      },
      {
        key: "interactiveRoute",
        subTitle: "Interactive Route Map",
        fileName: "Interactive Route Map.pdf",
      },
      {
        key: "redeliveryOfDeliveryServiceOrders",
        subTitle: "Redelivery of Delivery Service Orders",
        fileName: "Redelivery of Delivery Service Orders.pdf",
      },
      {
        key: "reroutingDelivery",
        subTitle: "Rerouting Delivery",
        fileName: "Rerouting Delivery.pdf",
      },
      {
        key: "createRoute",
        subTitle: "Route Management",
        fileName: "Route Management.pdf",
      },
      {
        key: "multipleLocRoutes",
        subTitle: "Route with Multiple Location & Multiple Shops",
        fileName: "Route with Multiple Location & Multiple Shops.pdf",
        needEncoding: true,
      },
      {
        key: "routeScanner",
        subTitle: "Route QR Code Scanner",
        fileName: "Route QR Code Scanner.pdf",
      },
      {
        key: "timedOrderRoute",
        subTitle: "Timed Order Route Planning",
        fileName: "Timed Order Route Planning.pdf",
      },
      {
        key: "upfrontEstimatedChargeforDeliveryOrders",
        subTitle: "Upfront Estimated Charge for Delivery Orders",
        fileName: "Upfront Estimated Charge for Delivery Orders.pdf",
      },
      {
        key: "ftdDeliveryService",
        subTitle: "Using FTD Delivery Service in Mercury HQ",
        fileName: "Using FTD Delivery Service in Mercury HQ.pdf",
      },
      {
        key: "zoneDefinition",
        subTitle: "Zone Definition (Auto Routes)",
        fileName: "Zone Definition (Auto Routes).pdf",
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Customer Directory",
    componentName: {},
    subAccordians: [
      {
        key: "customerDirectory",
        subTitle: "Customer Directory",
        fileName: "Customer Directory.pdf",
      },
      {
        key: "customerMergePurge",
        subTitle: "Customer - Merge, Purge & Contacts",
        fileName: "Customer - Merge, Purge & Contacts.pdf",
        needEncoding: true,
      },
      // {
      //   key: "eventPlanner",
      //   subTitle: "Event Planner",
      //   fileName: "Event Planner.pdf",
      // },
      {
        key: "houseAcc",
        subTitle: "House Account",
        fileName: "House Accounts.pdf",
      },
      {
        key: "subscriptionOrders",
        subTitle: "Subscription Orders",
        fileName: "Subscription Orders.pdf",
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Reports",
    componentName: {},
    subAccordians: [
      {
        key: "agedAnalysis",
        subTitle: "Aged Analysis Report",
        fileName: "Aged Analysis Report.pdf",
      },
      {
        key: "businessSnapshotReport",
        subTitle: "Business Snapshot Report",
        fileName: "Business Snapshot Report.pdf",
      },
      {
        key: "creditCardSettlementReport",
        subTitle: "Credit Card Settlement Report",
        fileName: "Credit Card Settlement Report.pdf",
      },
      {
        key: "customerReport",
        subTitle: "Customer Report",
        fileName: "Customer Report.pdf",
      },
      {
        key: "haStatements",
        subTitle: "House Account Statement",
        fileName: "House Account Statement.pdf",
      },
      {
        key: "orderDetailReport",
        subTitle: "Order Detail Report",
        fileName: "Order Detail Report.pdf",
      },
      {
        key: "orderSummaryReport",
        subTitle: "Order Summary Report",
        fileName: "Order Summary Report.pdf",
      },
      // {
      //   subTitle: "Occasion Reminder Report",
      //   fileName: "",
      // },
      // {
      //   subTitle: "Order Summary Report",
      //   fileName: "",
      // },
      {
        key: "productComparison",
        subTitle: "Product Comparison Report",
        fileName: "Product Comparison Report.pdf",
      },
      {
        key: "productSales",
        subTitle: "Product Sales Report",
        fileName: "Product Sales Report.pdf",
      },
      {
        key: "salesByPromoCode",
        subTitle: "Sales By Promo Code Report",
        fileName: "Sales By Promo Code Report.pdf",
      },
      {
        key: "salesSummary",
        subTitle: "Sales Summary Report",
        fileName: "Sales Summary Report.pdf",
      },
      {
        key: "salesTax",
        subTitle: "Sales Tax Report",
        fileName: "Sales Tax Report.pdf",
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Product Catalog",
    componentName: {},
    subAccordians: [
      {
        key: "productCatalog",
        subTitle: "Product Catalog",
        fileName: "Product Catalog.pdf",
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Shop Settings",
    componentName: {},
    subAccordians: [
      {
        key: "addonFeatures",
        subTitle: "Add-on Features",
        fileName: "Add-on Features.pdf",
      },
      {
        key: "autoPrintOrderMessages",
        subTitle: "Auto Print Order Related Messages",
        fileName: "Auto Print Order Related Messages.pdf",
      },
      {
        key: "bulkPrint",
        subTitle: "Bulk Print",
        fileName: "Bulk Print.pdf",
      },
      {
        key: "cityZipCode",
        subTitle: "City & Zip Code Coverage",
        fileName: "City & Zip Code Coverage.pdf",
        needEncoding: true,
      },
      {
        key: "deliveryFees",
        subTitle:
          "Delivery Fees - reimagined with interactive zip code coverage",
        fileName:
          "Delivery Fees - reimagined with interactive zip code coverage.pdf",
      },
      {
        key: "deliverySettings",
        subTitle: "Delivery Settings",
        fileName: "Delivery Settings.pdf",
      },
      {
        key: "designScanner",
        subTitle: "Design QR Code Scanner",
        fileName: "Design QR Code Scanner.pdf",
      },
      {
        key: "digitalPaymentHardware",
        subTitle: "Digital Payment Hardware",
        fileName: "Digital Payment Hardware.pdf",
      },
      {
        key: "emailOrderIntegration",
        subTitle: "Email Order Integration",
        fileName: "Email Order Integration.pdf",
      },
      {
        key: "customisedNotifications",
        subTitle: "Email & SMS Notifications Customization",
        fileName: "Email & SMS Notifications Customization.pdf",
        needEncoding: true,
      },
      {
        key: "externalOrder",
        subTitle: "External Order Ingestion & Integration Settings",
        fileName: "External Order Ingestion & Integration Settings.pdf",
        needEncoding: true,
      },
      ...(isEnabledForMember("ENABLE_OCCASION_REMINDER", "ALL")
        ? [
            {
              key: "occasionReminder",
              subTitle: "Occasion Reminder",
              fileName: "Occasion Reminder.pdf",
            },
          ]
        : []),
      {
        key: "taxRules",
        subTitle: "Origin & Destination Tax Rules",
        fileName: "Origin & Destination Tax Rules.pdf",
        needEncoding: true,
      },
      {
        key: "paymentMethods",
        subTitle: "Payment Methods",
        fileName: "Payment Methods.pdf",
      },
      {
        key: "PrintArtifacts",
        subTitle: "Print Artifacts & Statement Customization",
        fileName: "Print Artifacts & Statement Customization.pdf",
        needEncoding: true,
      },
      {
        key: "autoPrint",
        subTitle: "Print Management & Auto Print",
        fileName: "Print Management & Auto Print.pdf",
        needEncoding: true,
      },
      {
        key: "promoCodes",
        subTitle: "Promo Codes",
        fileName: "Promo Codes.pdf",
      },
      {
        key: "quickBooks",
        subTitle: "QuickBooks Integration",
        fileName: "QuickBooks Integration.pdf",
      },
      {
        key: "remotePrinting",
        subTitle: "Remote Printing",
        fileName: "Remote Printing.pdf",
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Gift Cards",
    componentName: {},
    subAccordians: [
      {
        key: "giftCards",
        subTitle: "Gift Cards",
        fileName: "Gift Cards.pdf",
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Staff Management",
    componentName: {},
    subAccordians: [
      {
        key: "staffManagement",
        subTitle: "Staff Management",
        fileName: "Staff Management.pdf",
        needEncoding: true,
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Legal",
    componentName: {},
    subAccordians: [
      {
        key: "terms_conditions",
        subTitle: "Terms & Conditions",
        content: "terms",
      },
      {
        key: "DSTerms_conditions",
        subTitle: "FTD Delivery Service Terms & Conditions",
        content: "DSTerms",
      },
      {
        key: "privacy_policy",
        subTitle: "Privacy Policy",
        content: "privacy",
      },
    ],
    defaultOpen: false,
  },
];

export const getMOLUIConfig = () => [
  {
    title: "Product & Add-on Quantity",
    componentName: {},
    subAccordians: [
      {
        key: "productQuantity",
        subTitle: "Products & Add-ons Quantity",
        fileName: "Products & Add-ons Quantity.pdf",
        needEncoding: true,
      },
    ],
    defaultOpen: false,
  },
  {
    title: "Dropship Product",
    componentName: {},
    subAccordians: [
      {
        key: "dropShipProduct",
        subTitle: "Drop Ship Products",
        fileName: "Drop Ship Products.pdf",
      },
    ],
    defaultOpen: false,
  },
];
