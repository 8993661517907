import React, { useEffect, useContext, useMemo } from "react";
import { Platform, View, Text } from "react-native";
import { connect, useSelector } from "react-redux";
import { useFormikContext } from "formik";

import {
  FormField,
  FormFieldDatePicker,
  FormFieldPicker,
  FormFieldSwitch,
} from "components/elements/forms";
import { Distance } from "components/elements/distance";

import tw from "tailwind-rn";
import moment from "moment";
import get from "lodash/get";

import { fonts, shapes, backgroundColors } from "styles/theme";

import I18NContext from "library/contexts/i18N";

import Environment from "library/utils/environment";

import {
  isWireOrder,
  isPhoneOutOrder,
} from "../../order-details/delivery-info/helper";
import UserProfileStorage from "library/storage/userProfile";
import {
  selectIsEditOrder,
  selectIsSubscription,
  selectRushDeliveryFee,
  selectIsEditSubscription,
} from "library/sagas/views/home/drawer/create-order/selector";
import { selectAddressVerificationInfo } from "library/sagas/ongoing/global-data/selector";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import { isEligibleForRush } from "library/utils/createOrder";
import {
  digitalGiftCardProducts,
  physicalGiftCardProducts,
} from "library/utils/giftCardProducts";
import { ToasterHandler, Tooltip, DeliveryZone } from "components/elements";
import AppSettingsContext from "library/contexts/appSettings";
import {
  getDeliveryTimeMinAndMaxTime,
  roundUpTime,
  frequency,
  subscriptionEnds,
} from "./helper";
import {
  isTimedDeliveryAvailable as hasTimedDelivery,
  hasDeliveryZone,
} from "library/utils/featureAvailability";
import RecurrenceText from "components/views/drawer/customer-directory/upsert-screen/customer/subscriptions/recurrence-text";

//eslint-disable-next-line
export const DeliveryInfo = React.memo(
  ({
    isSmallScreen,
    index,
    title,
    isAutoFill,
    eligibleDeliveryMethods,
    sendingMemberCode,
    canModifyOrder = true,
    isMultiOrderEligible,
    actualDeliveryMethod = "",
  }) => {
    const { values, setFieldValue, initialValues } = useFormikContext();
    const { messages, Localise } = useContext(I18NContext);
    const isEditOrder = useSelector(selectIsEditOrder);
    const isEditSubscription = useSelector(selectIsEditSubscription);
    const rushDeliveryFee = useSelector(selectRushDeliveryFee);
    const addressVerificationInfo = useSelector(selectAddressVerificationInfo);
    const isSubscription = useSelector(selectIsSubscription);
    const orderDetailsResponse = useSelector(selectApiResponse);

    const selectedShopPreferences =
      UserProfileStorage.getShopPreferences(sendingMemberCode);
    const { permissions = {} } = useContext(AppSettingsContext);
    const isTimedDeliveryAvailable = hasTimedDelivery(
      permissions,
      sendingMemberCode
    );

    const orderItem = get(values, `orderItems.${index}`, {});
    const {
      deliveryInfo: {
        deliveryMethod = "",
        deliveryDate,
        pickUpDateTime = "",
        orderDeliveryTime = "",
      },
      recipientInfo: { addressLine1 = "" },
      lineItems: [lineItemsInfo = {}],
      isRushOrder = false,
      isDeliveryFeeOverridden,
    } = orderItem;

    const {
      isAvsPerformed = "",
      isAvsSuggestedAddress = "",
      avsConfidence = "",
    } = addressVerificationInfo[index] || {};
    const isAvsVerified =
      isAvsPerformed === "Y" &&
      isAvsSuggestedAddress === "Y" &&
      avsConfidence === "HIGH";

    const { productFirstChoiceCode = "" } = lineItemsInfo;

    const isDigitalGiftCard = digitalGiftCardProducts.includes(
      productFirstChoiceCode
    );
    const isPhysicalGiftCard = physicalGiftCardProducts.includes(
      productFirstChoiceCode
    );
    const isEmailOrder = orderItem.orderSource === "Email Order";

    const digitalGcDeliveryMethod = [{ label: "Digital", value: "WALK_IN" }];
    const physicalGcDeliveryMethod = [{ label: "Walk-In", value: "WALK_IN" }];

    const deliveryMethods =
      isEditOrder && canModifyOrder
        ? eligibleDeliveryMethods.filter(
            (each) => each.value !== "FLORIST_PARTNER"
          )
        : isSubscription
        ? eligibleDeliveryMethods.filter(
            (each) => each.value === "FLORIST_DELIVERED"
          )
        : eligibleDeliveryMethods;

    if (isEditOrder && !canModifyOrder) {
      deliveryMethod === "MOL_FLORIST_DELIVERED" &&
        deliveryMethods.push({
          label: "MOL Florist Delivered",
          value: "MOL_FLORIST_DELIVERED",
        });
    }

    const path = `orderItems.${index}.deliveryInfo`;
    const parentPath = `orderItems.${index}`;
    const { direction } = get(orderDetailsResponse, parentPath, {});

    const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
    const isWalkInOrder = deliveryMethod === "WALK_IN";
    const isFloristDelivered = deliveryMethod === "FLORIST_DELIVERED";

    const shopOpenHours = UserProfileStorage.getShopHours(sendingMemberCode);

    const rushFeeEnabled =
      selectedShopPreferences?.rush_fee_enabled?.toLowerCase() ?? "false";

    const rushSubmitBy = get(selectedShopPreferences, "rush_submitted_by", "");

    const rushDeliverBy = get(selectedShopPreferences, "rush_delivered_by", "");

    const defaultIsRushOrder = get(
      initialValues,
      `${parentPath}.isRushOrder`,
      false
    );

    const defaultDeliveryInstructions = get(
      initialValues,
      `${parentPath}.deliveryInfo.deliveryInstructions`,
      ""
    );

    const hasMaxLimit =
      !defaultDeliveryInstructions || defaultDeliveryInstructions.length <= 100;

    const other = hasMaxLimit
      ? { maxLength: 100, showRemainingChars: true }
      : {};

    const defaultDeliveryDate = get(
      initialValues,
      `${parentPath}.deliveryInfo.deliveryDate`,
      false
    );

    const showRushOrder =
      (isFloristDelivered &&
        (isEligibleForRush(deliveryDate, rushSubmitBy) ||
          (isEditOrder &&
            defaultIsRushOrder &&
            deliveryDate === defaultDeliveryDate))) ||
      isEditSubscription;

    const showDeliveryZone = useMemo(
      () =>
        hasDeliveryZone({
          shopCode: sendingMemberCode,
          deliveryMethod,
          direction,
          addressLine1,
        }),
      [sendingMemberCode, deliveryMethod, direction, addressLine1]
    );

    const { ends, endDate } = get(values, "subscriptionInfo", {});
    const isCustomDateSelected = isSubscription
      ? ends === "CUSTOM_DATE"
      : false;

    const formatAndSetDateTime = (fieldName, fieldValue) => {
      const time = moment(fieldValue, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss");
      const formattedDateTime = `${moment(deliveryDate).format(
        "YYYY-MM-DD"
      )}T${time}`;

      const prevOrderDeliveryTime = get(
        initialValues,
        `${path}.orderDeliveryTime`,
        ""
      );

      if (moment().isBefore(formattedDateTime) || isEditSubscription) {
        setFieldValue(`${path}.${fieldName}`, formattedDateTime);
      } else {
        // Retaining the orderDeliveryTime while modifying an order with a past delivery date.
        if (isEditOrder && prevOrderDeliveryTime === formattedDateTime) return;
        setFieldValue(`${path}.${fieldName}`, "");
      }
    };

    const getShopHoursForSelectedDate = (date) => {
      let dayOfWeek = moment(date).format("dddd").toUpperCase();

      let shopHours = shopOpenHours.filter(
        (dayHours) => dayHours.day.toUpperCase() === dayOfWeek
      );

      return shopHours?.length ?? 0 > 0
        ? shopHours[0]
        : {
            openTime: "00:00:00",
            closeTime: "24:00:00",
          };
    };

    const updateShopDayTimings = (date, isInitialLoading = true) => {
      if (!isStorePickupOrder) return;
      let shopHours = getShopHoursForSelectedDate(date);

      let shopOpenTime = moment(date)
        .set({
          hour: parseInt(shopHours?.openTime?.split(":")[0]) || 0,
          minute: parseInt(shopHours?.openTime?.split(":")[1]) || 0,
          second: parseInt(shopHours?.openTime?.split(":")[2]) || 0,
        })
        .toDate();

      let shopCloseTime = moment(date)
        .set({
          hour: parseInt(shopHours?.closeTime?.split(":")[0]) || 0,
          minute: parseInt(shopHours?.closeTime?.split(":")[1]) || 0,
          second: parseInt(shopHours?.closeTime?.split(":")[2]) || 0,
        })
        .toDate();

      let shopTimings = {
        open: shopOpenTime,
        close: shopCloseTime,
      };

      let isToday = moment(date).isSame(moment(), "day");

      if (isToday) {
        if (moment().isAfter(shopOpenTime)) {
          if (moment().isBefore(shopCloseTime)) {
            shopTimings.open = moment(
              roundUpTime(15 * 60 * 1000, new Date())
            ).toDate();
          } else {
            shopTimings.open = moment().startOf("day").toDate();

            shopTimings.close = moment().startOf("day").toDate();
          }
        }
      }

      if (!isInitialLoading) {
        // Updating shop timings in Formik in order to access them while performing Yup validations
        setFieldValue(`${path}.shopDayTimings`, shopTimings);

        if (pickUpDateTime) {
          let pickUpTimeOnly = moment(
            pickUpDateTime,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("HH:mm:ss");

          let formattedPickupTime = `${moment(date).format(
            "YYYY-MM-DD"
          )}T${pickUpTimeOnly}`;

          setFieldValue(`${path}.pickUpDateTime`, formattedPickupTime);
        }
      }

      return shopTimings;
    };

    useEffect(() => {
      // Need to revisit this setTimeOut in future. Keeping it because of orderType & deliveryMethods not updating properly.
      const isDeliveryMethodNotChanged =
        actualDeliveryMethod === deliveryMethod;
      setTimeout(() => {
        setFieldValue(`${parentPath}.deliveryMethod`, deliveryMethod);
        setFieldValue(
          `${parentPath}.recipientInfo.deliveryMethod`,
          deliveryMethod
        );

        setFieldValue(
          `${parentPath}.relayFee`,
          Environment.get("SHOW_RELAY_FEE", false)
            ? isWireOrder(deliveryMethod)
              ? get(selectedShopPreferences, "relay_fee", "0.00")
              : isPhoneOutOrder(deliveryMethod)
              ? isEditOrder && isDeliveryMethodNotChanged
                ? get(orderItem, "oldValues.relayFee", "0.00")
                : get(selectedShopPreferences, "relay_fee", "0.00")
              : isEmailOrder && isEditOrder
              ? get(orderItem, "oldValues.relayFee", "0.00")
              : 0
            : 0
        );
      }, 500);

      //Reset isRushOrder to false on Delivery Method change
      if (!isFloristDelivered && isRushOrder)
        setFieldValue(`${parentPath}.isRushOrder`, false);
    }, [deliveryMethod]);

    useEffect(() => {
      if (deliveryDate) {
        updateShopDayTimings(deliveryDate, false);

        //Don't Reset isRushOrder on Edit order page load.
        if (isEditOrder) {
          if (
            defaultDeliveryDate === deliveryDate &&
            defaultIsRushOrder === isRushOrder
          )
            return;
        }

        if (orderDeliveryTime && deliveryDate) {
          formatAndSetDateTime("orderDeliveryTime", orderDeliveryTime);
        }

        //Reset isRushOrder to false on date change and crossed the submit time
        if (
          isFloristDelivered &&
          isRushOrder &&
          !isEligibleForRush(deliveryDate, rushSubmitBy)
        )
          setFieldValue(`${parentPath}.isRushOrder`, false);

        //Reset End Date If it is before deliveryDate
        if (isSubscription && moment(endDate).isBefore(deliveryDate)) {
          setFieldValue(`subscriptionInfo.endDate`, "");
        }
      }
    }, [deliveryDate]);

    useEffect(() => {
      if (pickUpDateTime && deliveryDate) {
        // Updating Pickup Time with selected delivery date in Formik in order to access it while performing Yup validations
        const pickUpTimeOnly = moment(
          pickUpDateTime,
          "YYYY-MM-DDTHH:mm:ss"
        ).format("HH:mm:ss");

        const formattedPickupTime = `${moment(deliveryDate).format(
          "YYYY-MM-DD"
        )}T${pickUpTimeOnly}`;

        setFieldValue(`${path}.pickUpDateTime`, formattedPickupTime);
      }
    }, [pickUpDateTime]);

    useEffect(() => {
      if (deliveryDate) {
        if (orderDeliveryTime) {
          formatAndSetDateTime("orderDeliveryTime", orderDeliveryTime);
        } else if (isRushOrder) {
          formatAndSetDateTime("orderDeliveryTime", rushDeliverBy);
        }
      }
    }, [orderDeliveryTime]);

    useEffect(() => {
      if (isRushOrder && deliveryDate) {
        if (!orderDeliveryTime) {
          formatAndSetDateTime("orderDeliveryTime", rushDeliverBy);
        }
      }
    }, [isRushOrder]);

    const { minTime, maxTime } = getDeliveryTimeMinAndMaxTime({
      isStorePickupOrder,
      deliveryDate,
    });

    const isTimePickerDisabled =
      isEditOrder && moment(deliveryDate).isBefore(moment(), "day");

    return (
      <View
        style={[
          {
            marginTop: 15,
          },
          !isMultiOrderEligible && { ...shapes.sectionBorder },
        ]}
      >
        <View style={[tw("flex flex-row pb-3"), { paddingHorizontal: 5 }]}>
          <Text style={[fonts.sectionHeading]}>
            {Localise(messages, title)}
          </Text>
        </View>

        <View
          style={[
            tw("flex flex-row flex-wrap"),
            { zIndex: isAutoFill ? 0 : 3, opacity: canModifyOrder ? 1 : 0.7 },
          ]}
          pointerEvents={canModifyOrder ? "auto" : "none"}
        >
          <FormFieldPicker
            containerStyle={{
              width: isSmallScreen ? "100%" : isWalkInOrder ? "40%" : "30%",
            }}
            placeholder={{
              label: "Select Delivery Method",
              value: "",
            }}
            data={
              isDigitalGiftCard
                ? digitalGcDeliveryMethod
                : isPhysicalGiftCard
                ? physicalGcDeliveryMethod
                : deliveryMethods
            }
            name="deliveryMethod"
            label={"Delivery Method"}
            labelStyle={{ fontWeight: "normal" }}
            path={path}
            parentPath={parentPath}
            disabledFieldTouch={true}
          />

          {!isWalkInOrder && (
            <>
              <FormFieldDatePicker
                dateValueFormat="YYYY-MM-DD"
                name="deliveryDate"
                label={
                  isStorePickupOrder
                    ? "Pickup Date"
                    : !isSubscription
                    ? "Delivery Date"
                    : "First Delivery Date"
                }
                labelStyle={{ fontWeight: "normal" }}
                placeholder="MM / DD / YYYY"
                containerStyle={{
                  width: isSmallScreen ? "100%" : "25%",
                  zIndex: isAutoFill ? 0 : 15,
                }}
                path={path}
              />
              {(isStorePickupOrder || isTimedDeliveryAvailable) && (
                <FormFieldDatePicker
                  name={
                    isStorePickupOrder ? "pickUpDateTime" : "orderDeliveryTime"
                  }
                  label={isStorePickupOrder ? "Pickup Time" : "Delivery Time"}
                  labelStyle={{ fontWeight: "normal" }}
                  placeholder="Time"
                  containerStyle={{
                    width: isSmallScreen ? "100%" : "20%",
                    zIndex: isAutoFill ? 0 : 10,
                  }}
                  path={path}
                  timeIntervals={isStorePickupOrder ? 15 : 30}
                  iconName="clock"
                  alignIconRight={true}
                  showTimeSelect={true}
                  showTimeSelectOnly={true}
                  minTime={minTime}
                  maxTime={maxTime}
                  dateFormat="h:mm a"
                  allowClear={true}
                  {...(Platform.OS !== "web" && {
                    dateValueFormat: "YYYY-MM-DDTHH:mm:ss",
                  })}
                  // Disabling the time picker while modifying an order with a past delivery date.
                  {...(!isStorePickupOrder && {
                    disabled: isTimePickerDisabled,
                    editable: !isTimePickerDisabled,
                    ...(isTimePickerDisabled && {
                      inputContainerStyle: {
                        backgroundColor: backgroundColors.greyColor,
                      },
                    }),
                  })}
                />
              )}

              {showRushOrder && (
                <View
                  style={[
                    tw("flex flex-row flex-wrap px-1 mb-3"),
                    !isSmallScreen && { marginTop: 24, width: "25%" },
                  ]}
                >
                  <View style={tw("flex justify-center")}>
                    <FormFieldSwitch
                      disabled={!(rushFeeEnabled === "true")}
                      value={!!isRushOrder}
                      onValueChange={(val) => {
                        setFieldValue(`${parentPath}.isRushOrder`, val);
                        // clear the isDeliveryFeeOverridden flag
                        isSubscription &&
                          setFieldValue(
                            `orderItems.${index}.isDeliveryFeeOverridden`,
                            false
                          );

                        const message = val
                          ? isSubscription && isDeliveryFeeOverridden
                            ? "A rush order fee has been applied to this order and delivery fee has been reset."
                            : "A rush order fee has been applied to this order."
                          : isSubscription && isDeliveryFeeOverridden
                          ? "A rush order fee has been removed from this order and delivery fee has been reset."
                          : null;

                        if (message) {
                          ToasterHandler("hooray", Localise(messages, message));
                        }
                      }}
                      testID="Rush_Order"
                    />
                  </View>
                  <View style={tw("flex justify-center")}>
                    <Tooltip
                      text={`Adds $${rushDeliveryFee} delivery fee upcharge to rushed orders.`}
                      isSmallScreen={isSmallScreen}
                      height={80}
                    >
                      <Text
                        style={[
                          fonts.default,
                          tw("flex flex-wrap items-center justify-center px-1"),
                        ]}
                      >
                        {Localise(messages, "Rush Order")}
                      </Text>
                    </Tooltip>
                  </View>
                </View>
              )}

              {isStorePickupOrder && (
                <FormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  name="pickUpBy"
                  label={"Pickup By"}
                  labelStyle={{ fontWeight: "normal" }}
                  placeholder={"Enter Pickup By"}
                  containerStyle={{
                    width: isSmallScreen ? "100%" : "25%",
                  }}
                  path={path}
                  editable={isAutoFill ? false : true}
                />
              )}
            </>
          )}
        </View>

        <View style={[tw("flex flex-row flex-wrap items-center my-1")]}>
          <Distance
            memberCode={sendingMemberCode}
            recipientInfo={orderItem?.recipientInfo}
            isAvsVerified={isAvsVerified}
            customStyles={{ fontSize: 11 }}
            customContainerStyles={{
              marginTop: 0,
              marginLeft: 5,
              marginBottom: 8,
            }}
            calculateDistance={isFloristDelivered}
            deliveryMethod={deliveryMethod}
          />

          {/* Delivery Zone Name */}
          {showDeliveryZone ? (
            <DeliveryZone {...{ memberCode: sendingMemberCode, index }} />
          ) : null}
        </View>
        {isSubscription && (
          <>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                zIndex: 2,
              }}
              pointerEvents={isAutoFill ? "none" : "auto"}
            >
              <FormFieldPicker
                placeholder={{
                  label: "Select Frequency",
                  value: null,
                }}
                containerStyle={{
                  width: isSmallScreen ? "100%" : "30%",
                  zIndex: isAutoFill ? 0 : 10,
                }}
                data={frequency}
                name="frequency"
                label={"Frequency"}
                labelStyle={{ fontWeight: "normal" }}
                path={`subscriptionInfo`}
              />
              <FormFieldPicker
                placeholder={{
                  label: "Never",
                  value: "NEVER",
                }}
                containerStyle={{
                  width: isSmallScreen ? "100%" : "25%",
                  zIndex: isAutoFill ? 0 : 10,
                }}
                data={subscriptionEnds}
                name="ends"
                label={"Ends"}
                labelStyle={{ fontWeight: "normal" }}
                path={`subscriptionInfo`}
              />
              {isCustomDateSelected ? (
                <FormFieldDatePicker
                  dateValueFormat="YYYY-MM-DD"
                  name="endDate"
                  label={"End Date"}
                  labelStyle={{ fontWeight: "normal" }}
                  placeholder="MM / DD / YYYY"
                  containerStyle={{
                    width: isSmallScreen ? "100%" : "20%",
                    zIndex: isAutoFill ? 0 : 15,
                  }}
                  minDate={moment(deliveryDate).toDate()}
                  path={"subscriptionInfo"}
                />
              ) : (
                <></>
              )}
            </View>
            <View style={[tw("flex flex-row flex-wrap items-center my-1")]}>
              <RecurrenceText
                {...values?.subscriptionInfo}
                firstDeliveryDate={deliveryDate}
                containerStyle={{ paddingLeft: 5, paddingBottom: 5 }}
              />
            </View>
          </>
        )}

        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          name="deliveryInstructions"
          label={Localise(
            messages,
            isStorePickupOrder
              ? "Pickup Notes"
              : isWalkInOrder
              ? "Notes"
              : "Delivery Instructions"
          )}
          labelStyle={{ fontWeight: "normal" }}
          placeholder={
            isStorePickupOrder
              ? "Pickup Notes"
              : isWalkInOrder
              ? "Notes"
              : "Add Instructions"
          }
          containerStyle={{
            width: "100%",
            paddingHorizontal: 5,
            zIndex: 1,
          }}
          outerContainerStyle={{}}
          path={path}
          editable={isAutoFill ? false : true}
          multiline={true}
          numberOfLines={3}
          maxNumberOfLines={5}
          {...other}
        />
      </View>
    );
  }
);

const mapStateToProps = (state = {}) => {
  return {
    isAutoFill: state.mhq.ongoing.global.isAutoFill,
  };
};

export default connect(mapStateToProps, null)(DeliveryInfo);
