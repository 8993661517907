import * as Yup from "yup";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import has from "lodash/has";
import omit from "lodash/omit";

import {
  DELIVER_BY_REGEX,
  extractTimeFromDI,
  formatLineItems,
  getBillingInfo,
  getDetailsFromOrder,
} from "./helper";
import {
  getPaymentValidationSchema,
  getCardType,
  ccdRegex,
  ccdErrors,
  isOrderTypeWired,
} from "library/utils/payment-options";
import UserProfileStorage from "library/storage/userProfile";
import {
  formatPrice,
  phoneNumberFormatter,
  cardMessageFormatter,
} from "library/utils/formatter";
import {
  giftCardProducts,
  digitalGiftCardProducts,
  physicalGiftCardProducts,
} from "library/utils/giftCardProducts";
import {
  Entitlements,
  isMHQNonCoreMember,
  isCoreMember,
  isCoreConnectMember,
} from "library/utils/entitlements";
import Environment from "library/utils/environment";

Yup.addMethod(Yup.string, "invalidPickupTime", function (errorMessage) {
  return this.test(`invalid-pickup-time`, errorMessage, function (value) {
    const { path, createError, parent } = this;
    const { pickUpDateTime, shopDayTimings } = parent;

    const paymentStatus = get(
      this,
      "from.2.value.paymentDetails.paymentStatus",
      ""
    );

    if (!pickUpDateTime) return true;

    if (paymentStatus?.toLowerCase() === "pending") return true;

    let pickUpTimeWithDate = moment(pickUpDateTime);
    let openingTime = moment(shopDayTimings.open);
    let closingTime = moment(shopDayTimings.close);

    return (
      (openingTime.isSameOrBefore(pickUpTimeWithDate) &&
        closingTime.isSameOrAfter(pickUpTimeWithDate)) ||
      createError({ path, message: errorMessage })
    );
  });
});

// To remove deliveryMethod in the recipient info object... we can make use of below commented code
Yup.addMethod(
  Yup.string,
  "deliveryMethodRulesOnRecipient",
  function (errorMessage) {
    return this.test(`recipient-on-dm`, errorMessage, function (value = "") {
      const deliveryMethod = get(
        this,
        "from.1.value.deliveryInfo.deliveryMethod"
      );

      const firstName = this.parent?.firstName;
      const lastName = this.parent?.lastName;
      const phone = this.parent?.phone;
      const addressLine1 = this.parent?.addressLine1;
      const state = this.parent?.state;
      const city = this.parent?.city;
      const locationType = this.parent?.locationType;
      const locationName = this.parent?.locationName;
      const email = !this.parent?.email ? false : true;
      const giftCardId = !this.parent?.giftCardId ? false : true;
      const zip = this.parent?.zip;
      const suite = this.parent?.suite;

      const isRecipientInfoEntered =
        !firstName &&
        !lastName &&
        !phone &&
        !addressLine1 &&
        !state &&
        !city &&
        !locationType &&
        !locationName &&
        !zip &&
        !suite
          ? false
          : true;

      if (
        deliveryMethod &&
        (isWalkIn(deliveryMethod) || isStorePickup(deliveryMethod))
      ) {
        return this.parent.hasRecipientInfo
          ? email
            ? isRecipientInfoEntered
              ? !value
                ? false
                : true
              : true
            : giftCardId
            ? isRecipientInfoEntered
              ? !value
                ? false
                : true
              : true
            : !value
            ? false
            : true
          : true;
      } else {
        return !value ? false : true;
      }
    });
  }
);

// To apply validation for recipient email when DIGITAL gift card is selected as product
Yup.addMethod(
  Yup.string,
  "giftCardRulesOnRecipientEmail",
  function (errorMessage) {
    return this.test(
      `is-gift-card-selected`,
      errorMessage,
      function (value = "") {
        const productFirstChoiceCode = get(
          this,
          "from.1.value.lineItems.0.productFirstChoiceCode"
        );
        const isDigitalGiftCard = digitalGiftCardProducts.includes(
          productFirstChoiceCode
        );
        if (isDigitalGiftCard) {
          return this.parent.email ? (!value ? false : true) : false;
        } else {
          return true;
        }
      }
    );
  }
);

// To apply validation for gift card number when physical gift card is selected as product
Yup.addMethod(Yup.string, "physicalGiftCardRules", function (errorMessage) {
  return this.test(
    `is-gift-card-selected`,
    errorMessage,
    function (value = "") {
      const productFirstChoiceCode = get(
        this,
        "from.1.value.lineItems.0.productFirstChoiceCode"
      );
      if (isPhysicalGiftCard(productFirstChoiceCode)) {
        return this.parent.giftCardId ? (!value ? false : true) : false;
      } else {
        return true;
      }
    }
  );
});

// To apply validation for gift card number when physical gift card is selected as product
Yup.addMethod(
  Yup.string,
  "redeemGiftCardRules",
  function (errorMessage, redeemedGiftCardsData, values, errorMessage2) {
    return this.test(
      `is-gift-card-redeemed`,
      errorMessage,
      function (giftCodeId = "") {
        const { path, createError } = this;

        const isProductGc =
          values?.orderItems.find(
            (eachOrder) =>
              eachOrder?.lineItems?.length &&
              giftCardProducts.includes(
                eachOrder.lineItems[0].productFirstChoiceCode
              )
          ) || [];

        const giftCard = giftCodeId;
        if (isGcRedeemed(redeemedGiftCardsData, giftCard)) {
          return false;
        } else if (giftCard && isProductGc?.lineItems?.length > 0) {
          return createError({
            path,
            message: errorMessage2,
          });
        } else {
          return true;
        }
      }
    );
  }
);

// To apply validation when Gift card amount is zero
Yup.addMethod(Yup.string, "isGcAmountZero", function (errorMessage) {
  return this.test(
    `is-gift-card-redeemed`,
    errorMessage,
    function (value = "") {
      const orderItems = get(this, "from.2.value.orderItems");
      const isProductGc =
        orderItems.find(
          (eachOrder) =>
            eachOrder?.lineItems?.length &&
            giftCardProducts.includes(
              eachOrder.lineItems[0].productFirstChoiceCode
            )
        ) || [];

      return !(isProductGc?.lineItems?.length > 0 && value === "0.00");
    }
  );
});

// To apply validation for zip code when zip code length is 10
Yup.addMethod(
  Yup.string,
  "zipCodeValidation",
  function (isEditOrder, errorMessage) {
    return this.test("zip-code-validation", errorMessage, function (value) {
      const { zip, deliveryMethod } = this.parent;
      // Skip zip code validation for store pickup or walk-in delivery methods
      if (isWalkIn(deliveryMethod) || isStorePickup(deliveryMethod)) {
        return true; // No zip code validation needed
      }
      const ignoreZipValidation = isEditOrder && zip?.length === 10;

      // Allow 5 or 9 digits if it's a POS/OG order
      if (ignoreZipValidation) {
        return /^[0-9]{5}(-[0-9]{4})?$/.test(value);
      }

      // Enforce 5 digits if the user modified the zip code
      return /^[0-9]{5}$/.test(value);
    });
  }
);

const contactValidation = (messages, Localise) => {
  return Yup.object().shape({
    firstName: Yup.string().required(
      Localise(messages, "Please enter First Name")
    ),
    email: Yup.string()
      .email(Localise(messages, "Enter valid Email"))
      .matches(
        /^\w+[+-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
        "Invalid Email address"
      ),
    phone: Yup.string().matches(
      new RegExp(
        "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
      ),
      Localise(messages, "Enter valid Phone")
    ),
  });
};

export const validationSchema = (
  messages,
  Localise,
  redeemedGiftCardsData,
  enableSplitPayment = false,
  customerDetails,
  houseAccountCreditLimit,
  isEditOrder,
  action = ""
) => {
  const refundTransactionSchema = Yup.object().shape({
    refundAmount: Yup.number()
      .typeError("Invalid Amount")
      .min(0, "Invalid Amount"),
  });
  Yup.addMethod(Yup.string, "isValidCard", function (errorMessage) {
    return this.test(`is-valid-card`, errorMessage, function (value = "") {
      const { actualCardNumber = "" } = this.parent;
      if (!actualCardNumber) return true;
      const trimmedValue = actualCardNumber.split(" ").join("");
      if (trimmedValue.substring(0, 3) === "***") return true;
      if (isNaN(trimmedValue)) return false;
      var cardType = getCardType(trimmedValue);
      if (!cardType) return false;
      const { path, createError } = this;
      var regEx = new RegExp(ccdRegex[cardType]);

      return (
        regEx.test(trimmedValue) ||
        createError({
          path,
          message:
            Localise(messages, ccdErrors[cardType]) ||
            Localise(messages, "Invalid Card Number"),
        })
      );
    });
  });
  Yup.addMethod(Yup.string, "isValidAmount", function (errorMessage) {
    return this.test(`is-valid-amount`, errorMessage, function (value) {
      const { tenderedAmount, orderTotal } = this.parent;
      const paymentAmount = get(this, "from.1.value.amount", orderTotal);
      return !(
        isNaN(tenderedAmount) ||
        parseFloat(tenderedAmount) < (paymentAmount || orderTotal)
      );
    });
  });
  return Yup.object({
    buyerName: Yup.string()
      .label("buyerName")
      .min(
        2,
        Localise(messages, "Customer Name must have atleast 2 characters")
      ),
    sendingMemberField: Yup.string()
      .label("selectedShop")
      .when("hasMultipleShops", {
        is: (value) => value === true,
        then: Yup.string().required(Localise(messages, "Select a Shop")),
      }),
    subscriptionInfo: Yup.object().when("isSubscription", {
      is: (isSubscription) => isSubscription === true,
      then: Yup.object().shape({
        frequency: Yup.string()
          .label("Frequency")
          .required(Localise(messages, "Please select subscription frequency")),
        endDate: Yup.date()
          .when("ends", {
            is: "CUSTOM_DATE",
            then: Yup.date().required("Please select an end date"),
            otherwise: Yup.date().notRequired(),
          })
          .nullable(),
      }),
    }),
    customerInfo: Yup.object().when("savePayment", {
      is: (savePayment) => !!savePayment,
      then: Yup.object().shape(
        {
          firstName: Yup.string().when("customerType", {
            is: (value) => value === "Individual",
            then: Yup.string().required(
              Localise(messages, "Please enter First Name")
            ),
          }),
          lastName: Yup.string().when("customerType", {
            is: (value) => value === "Individual",
            then: Yup.string().required(
              Localise(messages, "Please enter Last Name")
            ),
          }),
          businessName: Yup.string().when("customerType", {
            is: (value) => value === "Business",
            then: Yup.string().required(
              Localise(messages, "Please enter Business Name")
            ),
          }),
          email: Yup.string()
            .email(Localise(messages, "Enter valid Email"))
            .matches(
              /^\w+[+-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
              "Invalid Email address"
            )
            .required(Localise(messages, "Please enter Customer Email")),
          phone: Yup.string()
            .matches(
              new RegExp(
                "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
              ),
              Localise(messages, "Enter valid Phone")
            )
            .when("email", {
              is: (email) => !email || email.length === 0,
              then: Yup.string().required(
                Localise(messages, "Please enter Customer Phone")
              ),
            }),
          customerType: Yup.string()
            .label("customerType")
            .required(Localise(messages, "Please select Customer Type")),
          selectedContact: Yup.object().when("selectContact", {
            is: (value) => !!value,
            then: contactValidation(messages, Localise),
          }),
        },
        [["email", "phone"]]
      ),
      otherwise: Yup.object().when("hasCustomerInfo", {
        is: (hasCustomerInfo) => !!hasCustomerInfo,
        then: Yup.object().shape(
          {
            firstName: Yup.string().when("customerType", {
              is: (value) => value === "Individual",
              then: Yup.string().required(
                Localise(messages, "Please enter First Name")
              ),
            }),
            lastName: Yup.string().when("customerType", {
              is: (value) => value === "Individual",
              then: Yup.string().required(
                Localise(messages, "Please enter Last Name")
              ),
            }),
            businessName: Yup.string().when("customerType", {
              is: (value) => value === "Business",
              then: Yup.string().required(
                Localise(messages, "Please enter Business Name")
              ),
            }),
            email: Yup.string()
              .email(Localise(messages, "Enter valid Email"))
              .matches(
                /^\w+[+-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
                "Invalid Email address"
              )
              .when("phone", {
                is: (phone) => !phone || phone.length === 0,
                then: Yup.string().required(
                  Localise(messages, "Please enter Customer Email")
                ),
                otherwise: Yup.string().notRequired(),
              }),
            phone: Yup.string()
              .matches(
                new RegExp(
                  "^(\\+\\d{1,2}\\s)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
                ),
                Localise(messages, "Enter valid Phone")
              )
              .when("email", {
                is: (email) => !email || email.length === 0,
                then: Yup.string().required(
                  Localise(messages, "Please enter Customer Phone")
                ),
              }),
            customerType: Yup.string()
              .label("customerType")
              .required(Localise(messages, "Please select Customer Type")),
            selectedContact: Yup.object().when("selectContact", {
              is: (value) => !!value,
              then: contactValidation(messages, Localise),
            }),
          },
          [["email", "phone"]]
        ),
      }),
    }),
    paymentDetails: getPaymentValidationSchema(
      redeemedGiftCardsData,
      enableSplitPayment,
      customerDetails,
      houseAccountCreditLimit
    ),
    orderItems: Yup.array().of(
      Yup.object().shape({
        productInfo: Yup.string()
          .label("productInfo")
          .when("lineItems", {
            is: (value) => value && value.length === 0,
            then: Yup.string()
              .test(
                "is-valid-product",
                Localise(messages, "Please select a valid product"),
                function () {
                  const { isProductSearched } = get(
                    this,
                    "from.1.value",
                    false
                  );
                  const { lineItems } = this.parent;
                  if (isProductSearched && lineItems?.length === 0) {
                    return false;
                  } else {
                    return true;
                  }
                }
              )
              .required(
                Localise(
                  messages,
                  "Select product from the dropdown list or from Product Catalog"
                )
              ),
          }),
        lineItems: Yup.array().of(
          Yup.object().shape({
            productFirstChoiceDescription: Yup.string()
              .label("productFirstChoiceDescription")
              .required(Localise(messages, "Product Description Required")),
          })
        ),
        price: Yup.array().of(
          Yup.object().shape({
            value: Yup.string()
              .label("price")
              .required(Localise(messages, "Invalid Price"))
              .matches(/^\d+\.?\d*$/, Localise(messages, "Invalid Price"))
              .isGcAmountZero(Localise(messages, "Invalid Price")),
            newPrice: Yup.string().matches(
              /^\d+\.?\d*$/,
              Localise(messages, "Invalid Price")
            ),
            discount: Yup.string()
              .label("discount")
              .when("discountType", {
                is: (value) => !!value,
                then: Yup.string().matches(
                  /^\d+\.?\d*$/,
                  Localise(messages, "Invalid Discount")
                ),
              }),
          })
        ),
        recipientInfo: Yup.object().shape({
          email: Yup.string()
            .giftCardRulesOnRecipientEmail(
              Localise(messages, "Please enter recipient email")
            )
            .matches(
              /^\w+[+-.\w]*@(?!(?:donotsend)\.com$)\w[-.\w]*\.\w{2,4}$/,
              "Invalid Email address"
            ),
          giftCardId: Yup.string().physicalGiftCardRules(
            Localise(messages, "Please enter valid gift card number")
          ),
          firstName: Yup.string()
            .label("Recipient First Name")
            .min(
              2,
              Localise(
                messages,
                "Recipient First Name must have at least 2 characters"
              )
            )
            .deliveryMethodRulesOnRecipient(
              Localise(messages, "Enter Recipient First Name")
            ),
          // .when("deliveryMethod", {
          //               is: (value) => (value && isWalkIn(value)) || isStorePickup(value),
          //               then: Yup.string().when("hasRecipientInfo", {
          //                 is: (value) => value,
          //                 then: Yup.string().required(
          //                   Localise(messages, "Enter Recipient First Name")
          //                 ),
          //               }),
          //               otherwise: Yup.string().required(
          //                 Localise(messages, "Enter Recipient First Name")
          //               ),
          //             }),
          lastName: Yup.string()
            .label("Recipient Last Name")
            .min(
              2,
              Localise(
                messages,
                "Recipient Last Name must have at least 2 characters"
              )
            )
            .deliveryMethodRulesOnRecipient(
              Localise(messages, "Enter Recipient Last Name")
            ),
          // .when("deliveryMethod", {
          //   is: (value) => (value && isWalkIn(value)) || isStorePickup(value),
          //   then: Yup.string().when("hasRecipientInfo", {
          //     is: (value) => value,
          //     then: Yup.string().required(
          //       Localise(messages, "Enter Recipient Last Name")
          //     ),
          //   }),
          //   otherwise: Yup.string().required(
          //     Localise(messages, "Enter Recipient Last Name")
          //   ),
          // }),
          addressLine1: Yup.string()
            .label("addressLine1")
            .min(2, Localise(messages, "Invalid Street Address"))
            .max(1000, Localise(messages, "Invalid Street Address"))
            .deliveryMethodRulesOnRecipient(
              Localise(messages, "Enter Street Address")
            ),

          // .when("deliveryMethod", {
          //   is: (value) => (value && isWalkIn(value)) || isStorePickup(value),
          //   then: Yup.string().when("hasRecipientInfo", {
          //     is: (value) => value,
          //     then: Yup.string().required(
          //       Localise(messages, "Enter Street Address")
          //     ),
          //   }),
          //   otherwise: Yup.string().required(
          //     Localise(messages, "Enter Street Address")
          //   ),
          // }),
          suite: Yup.string()
            .label("suite")
            .min(1, Localise(messages, "Invalid suite")),
          city: Yup.string()
            .label("city")
            .min(2, Localise(messages, "Invalid City Name"))
            .max(256, Localise(messages, "Invalid City Name"))
            .deliveryMethodRulesOnRecipient(
              Localise(messages, "Enter City name")
            ),

          // .when("deliveryMethod", {
          //   is: (value) => (value && isWalkIn(value)) || isStorePickup(value),
          //   then: Yup.string().when("hasRecipientInfo", {
          //     is: (value) => value,
          //     then: Yup.string().required(
          //       Localise(messages, "Enter City name")
          //     ),
          //   }),
          //   otherwise: Yup.string().required(
          //     Localise(messages, "Enter City name")
          //   ),
          // }),
          country: Yup.string().required(
            Localise(messages, "Country Required")
          ),
          state: Yup.string()
            .label("state")
            .when("country", {
              is: (value) => (value && value === "US") || value === "CA",
              then: Yup.string().matches(
                new RegExp("^[a-zA-Z]{2}$"),
                Localise(messages, "Invalid State")
              ),
            })
            .deliveryMethodRulesOnRecipient(
              Localise(messages, "State required")
            ),

          // .when("deliveryMethod", {
          //   is: (value) => value && (isStorePickup(value) || isWalkIn(value)),
          //   then: Yup.string().when("hasRecipientInfo", {
          //     is: (value) => value,
          //     then: Yup.string().required(
          //       Localise(messages, "State required")
          //     ),
          //   }),
          //   otherwise: Yup.string().required(
          //     Localise(messages, "State required")
          //   ),
          // }),
          zip: Yup.string()
            .label("zip")
            .when("country", {
              is: (value) => value && value === "US",
              then: Yup.string().zipCodeValidation(
                isEditOrder,
                Localise(messages, "Invalid Postal Code")
              ),
              otherwise: Yup.string().when("country", {
                is: (value) => value && value === "CA",
                then: Yup.string().matches(
                  new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?(\\d[A-Za-z]\\d)?\\s*$"),
                  Localise(messages, "Invalid Postal Code")
                ),
              }),
            })
            .deliveryMethodRulesOnRecipient(
              Localise(messages, "Postal Code required")
            ),
          // .when("deliveryMethod", {
          //   is: (value) => value && (isStorePickup(value) || isWalkIn(value)),
          //   then: Yup.string().when("hasRecipientInfo", {
          //     is: (value) => value,
          //     then: Yup.string().required(
          //       Localise(messages, "Postal Code required")
          //     ),
          //   }),
          //   otherwise: Yup.string().required(
          //     Localise(messages, "Postal Code required")
          //   ),
          // }),
          // locationType: Yup.string()
          //   .label("locationType")
          //   .when("deliveryMethod", {
          //     is: (value) => value && isLocal(value),
          //     then: Yup.string().required(
          //       "Please select a valid Location Type"
          //     ),
          //   }),
          locationName: Yup.string()
            .label("locationName")
            .min(
              3,
              Localise(
                messages,
                "Location Name must have at least 3 characters"
              )
            )
            .when("deliveryMethod", {
              is: (value) => value && !isStorePickup(value) && !isWalkIn(value),
              then: Yup.string().when(["deliveryMethod", "locationType"], {
                is: (deliveryMethod, locationType) =>
                  deliveryMethod &&
                  locationType !== undefined &&
                  locationType !== "Residence" &&
                  locationType !== "Other",
                then: Yup.string().required(
                  Localise(messages, "Enter Location Name")
                ),
              }),
            }),
          phone: Yup.string()
            .label("phone")
            .when(["deliveryMethod", "country"], {
              is: (deliveryMethod, country) => deliveryMethod && country,
              then: Yup.string()
                //.required("Please enter a Phone Number")
                .when("country", {
                  is: (country) => country === "US" || country === "CA",
                  then: Yup.string().matches(
                    new RegExp(
                      "^((\\+)?\\d{1,2}(\\s)?)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?\\d{4}$"
                    ),
                    Localise(messages, "Please enter a valid Phone Number")
                  ),
                  otherwise: Yup.string().matches(
                    new RegExp(
                      "^((\\+)?\\d{1,2}(\\s)?)?((\\(\\d{3}\\))|(\\d{3}))[\\s.-]?\\d{3}[\\s.-]?(\\d{3}[\\s.-])?\\d{4,9}$"
                    ),
                    Localise(messages, "Please enter a valid Phone Number")
                  ),
                }),
            }),
        }),
        deliveryInfo: Yup.object().shape({
          cardMessage: Yup.string()
            .label("cardMessage")
            .min(
              2,
              Localise(messages, "Card Message must have at least 2 characters")
            )
            .when(["deliveryMethod", "orderSource"], {
              is: (deliveryMethod, orderSource) =>
                deliveryMethod &&
                ((isWireOut(deliveryMethod) && orderSource !== "FTD") ||
                  isTransfer(deliveryMethod) ||
                  isFloristDelivered(deliveryMethod) ||
                  isPhoneOut(deliveryMethod)),
              then: Yup.string().required(
                Localise(messages, "Enter Card Message")
              ),
            }),
          deliveryDate: Yup.date()
            .label("deliveryDate")
            .when("deliveryMethod", {
              is: (value) => value && isStorePickup(value),
              then: Yup.date()
                .required(Localise(messages, "Please enter a Pickup Date"))
                .nullable(),
              otherwise: Yup.date().when("deliveryMethod", {
                is: (value) => value && !isWalkIn(value),
                then: Yup.date()
                  .required(Localise(messages, "Enter Delivery Date"))
                  .nullable(),
              }),
            }),
          pickUpDateTime: Yup.string().when("deliveryMethod", {
            is: (value) => value && isStorePickup(value),
            then: Yup.string().required(
              Localise(messages, "Please enter a Pickup Time")
            ),
            // .invalidPickupTime(
            //   Localise(messages, "Please enter a valid Pickup Time")
            // ),
          }),
          pickUpBy: Yup.string().when("deliveryMethod", {
            is: (value) => value && isStorePickup(value),
            then: Yup.string().required(
              Localise(messages, "Please enter Pickup By")
            ),
          }),
          deliveryMethod: Yup.string()
            .label("deliveryMethod")
            .required(Localise(messages, "Select Delivery method")),
          deliveryInstructions: Yup.string()
            .label("deliveryInstructions")
            .when("deliveryMethod", {
              is: (value) => value && isWireOut(value),
              then: Yup.string().max(
                100,
                Localise(
                  messages,
                  "Delivery instructions cannot exceed 100 characters"
                )
              ),
            }),
          occasion: Yup.string()
            .label("occasion")
            .required(Localise(messages, "Select an Occasion")),

          floristInfo: Yup.string()
            .when("deliveryMethod", {
              is: (value) => value && isWireOut(value),
              then: Yup.string().required(
                Localise(messages, "Select a Florist Partner")
              ),
            })
            .when("deliveryMethod", {
              is: (value) => value && isTransfer(value),
              then: Yup.string().required(
                Localise(messages, "Select a Shop to transfer")
              ),
            }),
        }),
        promoCode: Yup.string().when("selectedPromoCodeInfo", {
          is: (info) =>
            ["copy", "draft"].includes(action) &&
            !isEmpty(info) &&
            info?.isUnavailable,
          then: Yup.string().test(
            "is-invalid",
            Localise(messages, "Promo code is no longer valid"),
            () => false
          ),
          otherwise: Yup.string().notRequired(),
        }),
        deliveryFee: Yup.string()
          .label("deliveryFee")
          .matches(
            /^\d{1,4}\.?\d*$/,
            Localise(messages, "Invalid Delivery Fee")
          ),
        relayFee: Yup.string()
          .label("relayFee")
          .matches(/^\d{1,4}\.?\d*$/, Localise(messages, "Invalid Relay Fee")),
        externalPartnerBusinessName: Yup.string()
          .label("externalPartnerBusinessName")
          .when("deliveryMethod", {
            is: (value) => value && isPhoneOut(value),
            then: Yup.string().required(
              Localise(messages, "Enter Florist Partner Name")
            ),
          }),
      })
    ),
    refundTransactions: Yup.array()
      .of(refundTransactionSchema)
      .test("maxCheck", "Invalid Price", function (value) {
        if (value && value.length > 0) {
          value.forEach((transaction) => {
            const { refundAmount = 0, availableAmount } = transaction;
            if (refundAmount > availableAmount) {
              throw this.createError({
                path: `refundTransactions[${value.indexOf(
                  transaction
                )}].refundAmount`,
                message: `Invalid Amount`,
              });
            }
          });
        }

        return true;
      }),
  });
};

export const redeemGcValidationSchema = (
  messages,
  Localise,
  redeemedGiftCardsData,
  values
) => {
  return Yup.object({
    giftCard: Yup.string().redeemGiftCardRules(
      Localise(messages, "Gift Card already redeemed"),
      redeemedGiftCardsData,
      values,
      Localise(messages, "Gift Card cannot be redeemed on this order")
    ),
  });
};

Yup.addMethod(Yup.string, "isValidNotes", function (errorMessage) {
  return this.test(`is-valid-notes`, errorMessage, function (value) {
    const isPaymentTypeInvoice =
      get(
        this,
        "from.1.value.paymentDetails.paymentMethod.0.paymentMethodType",
        ""
      ) === "INVOICE";
    if (!isPaymentTypeInvoice) return true;
    const {
      houseAccountInfo: {
        creditLimit = 500,
        orderTotal = 0,
        houseAccountBalance: { totalBalance = 0 } = {},
      } = {},
    } = this.parent;
    return orderTotal <= creditLimit - totalBalance;
  });
});

Yup.addMethod(Yup.string, "isValidExpDate", function (errorMessage) {
  return this.test("is-valid-expDate", errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: errorMessage });
    }
    const [expMonth, expYear] = value.split("/") || [];
    const month = parseInt(expMonth, 10);
    const year =
      expYear?.length === 2
        ? 2000 + parseInt(expYear, 10)
        : parseInt(expYear, 10);
    if (
      !expMonth ||
      !expYear ||
      isNaN(month) ||
      month < 1 ||
      month > 12 ||
      isNaN(year)
    ) {
      return createError({ path, message: errorMessage });
    }
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return createError({ path, message: errorMessage });
    }
    return true;
  });
});

export const basicOrderInfo = {
  lineItems: [],
  price: [],
  recipientInfo: {
    firstName: "",
    lastName: "",
    email: "",
    giftCardId: "",
    addressLine1: "",
    suite: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    locationType: "",
    locationName: "",
    phone: "",
    deliveryMethod: "",
    hasRecipientInfo: false,
    county: "",
  },
  addressVerificationInfo: {
    isAvsPerformed: "N",
    avsConfidence: "",
    isAvsSuggestedAddress: "N",
  },
  deliveryInfo: {
    cardMessage: "",
    deliveryDate: "",
    pickUpDateTime: "",
    deliveryMethod: "",
    pickUpBy: "",
    deliveryInstructions: "",
    occasion: "",
    floristInfo: "",
    shopDayTimings: {},
    deliveryNotes: "",
    deliveryTime: "",
    deliveredDate: "",
    operator: "",
    deliveryZoneId: "",
  },
  isRushOrder: false,
  noAutoForward: false,
  deliveryFee: 0.0,
  retailDeliveryFee: 0.0,
  relayFee: 0.0,
  externalPartnerBusinessName: "",
};

export const basicCustomerInfo = {
  customerId: "",
  name: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  customerType: "",
  customerNotes: "",
  businessName: "",
  taxExemptCode: "",
};

export const locationList = [
  { label: "Residence", value: "Residence" },
  { label: "Office", value: "Office" },
  { label: "Funeral Home", value: "Funeral" },
  { label: "Other", value: "Other" },
];

export const basicPaymentInfo = {
  currencyCode: "USD",
  paymentMethod: [
    {
      paymentMethodType: "",
      enablePayment: false,
      savePayment: false,
      amount: formatPrice(0),
      billingInformation: {
        addressLine1: "",
        suite: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
        email: "",
        emailOptIn: false,
      },
      paymentMethodDetails: {
        name: "",
        cardNumber: "",
        cvv: "",
        expDate: "",
        tenderedAmount: "",
        changeDueAmount: "",
        amount: "",
        note: "",
        gcNumber: "",
        gcAmount: "",
      },
    },
  ],
};

const getUpdatedOrderDeliveryTime = (
  orderDeliveryTime,
  deliveryInstructions,
  deliveryDate,
  isRushOrder
) => {
  let updatedOrderDeliveryTime = "";
  let formattedTime = orderDeliveryTime;
  //For old rush orders before timed delivery feature
  if (isRushOrder && !orderDeliveryTime) {
    formattedTime = extractTimeFromDI(deliveryInstructions);
  }
  if (formattedTime) {
    updatedOrderDeliveryTime = `${deliveryDate}T${moment(
      formattedTime,
      "h:mm A"
    ).format("HH:mm:ss")}`;
  }
  return updatedOrderDeliveryTime;
};

export const preloadInitialVals = ({
  memberCodes = [],
  orderChannel,
  shopNames = [],
  preloadOrderDetails = {},
  global,
  action,
  permissions,
  sendingCode,
}) => {
  if (isEmpty(preloadOrderDetails)) return null;
  const isEditOrder = action === "edit";
  const isCopyOrder = action === "copy";
  const isDraftOrder = action === "draft";
  const isEditOrDraft = ["edit", "draft"].includes(action);
  const shopsList = memberCodes.map((code) => {
    return { label: code, value: code };
  });
  const {
    customerInfo = {},
    orderTotalPrice,
    orderAmounts = [],
    refundAvailable = "0.00",
    orderItems = [],
    orderType = "",
  } = preloadOrderDetails;

  const paymentStatus = get(
    preloadOrderDetails,
    "paymentDetails.paymentMethod.0.paymentStatus",
    ""
  );

  const isLocalOrder = orderType === "Local";
  const isWiredOrder = isOrderTypeWired(orderType);
  const feeRefundedAmount =
    orderAmounts?.find((obj) => obj.name === "feeRefundedAmount")?.value ||
    `0.00`;

  const taxRefundedAmount =
    orderAmounts?.find((obj) => obj.name === "taxRefundedAmount")?.value ||
    `0.00`;

  let shopCode,
    shopName = "",
    isCoreUser,
    isCoreConnectUser;

  let paymentDetails = {};

  const processedOrderItems = orderItems.map((item) => {
    const {
      copiedFromOrderItemId = "", //If we copy an order and then saved it as a draft
      sendingMember,
      receivingMember,
      deliveryInfo = {},
      lineItems = [],
      recipientInfo = {},
      orderSource,
      orderItemId,
      autoForward,
      direction,
      addressVerificationInfo,
      hasSettlementError = false,
      pickupInfo = {},
      multiProductEligible = false,
      isRushOrder = false,
      externalPartnerBusinessName,
      erosOrderNumber = "",
      promoCode = "",
      promoValue = "",
      totalDiscountAmount = 0,
      notes = "",
    } = item || {};

    shopCode =
      sendingCode ||
      (direction === "INBOUND"
        ? receivingMember?.memberCode
        : sendingMember?.memberCode);

    shopName = shopNames[shopCode] || "";
    isCoreUser = isCoreMember(shopCode);
    isCoreConnectUser = isCoreConnectMember(shopCode);
    const isNonCoreUser = isMHQNonCoreMember(shopCode);

    let applyTaxOnDF = false,
      relayFee = "0.00";

    if (isNonCoreUser) {
      const shopPreferences = UserProfileStorage.getShopPreferences(shopCode);

      applyTaxOnDF = get(shopPreferences, "tax_delivery_fee", "false");

      relayFee = Environment.get("SHOW_RELAY_FEE", false)
        ? get(shopPreferences, "relay_fee", "0.00")
        : "0.00";
    }

    const formatItems = formatLineItems(
      lineItems,
      global,
      false,
      true, // We are updating discounts in Formik in order prefill them in edit order screen
      isCoreUser,
      shopCode,
      isEditOrder,
      multiProductEligible,
      isWiredOrder,
      customerInfo
    );

    const {
      deliveryMethod,
      deliveryDate,
      deliveryZoneId,
      orderDeliveryTime,
      deliveryInstructions,
    } = deliveryInfo;
    const isPastDate = moment(deliveryDate).isBefore(moment().add(-1, "days"));

    const orderOldTotal = formatPrice(orderTotalPrice || 0);

    paymentDetails = {
      ...(isEditOrder && {
        amountDue:
          hasSettlementError || paymentStatus?.toLowerCase() === "pending"
            ? orderOldTotal
            : "0.00",
        newTotalAmount:
          hasSettlementError || paymentStatus?.toLowerCase() === "pending"
            ? orderOldTotal
            : "0.00",
        paymentStatus: paymentStatus,
      }),
    };

    const feeSurchargeTaxAndTotalsInfo = getDetailsFromOrder(item);

    const defaultDeliveryFee = isEditOrDraft
      ? formatPrice(feeSurchargeTaxAndTotalsInfo?.totalDeliveryFee)
      : 0.0;

    const isGcProduct = giftCardProducts.includes(
      formatItems?.updatedLineItems?.[0]?.productFirstChoiceCode
    );

    let updatedDeliveryInstructions = "";
    if (deliveryInstructions) {
      updatedDeliveryInstructions = deliveryInstructions?.replace(
        DELIVER_BY_REGEX,
        ""
      );
    }

    let updatedOrderDeliveryTime = "";
    if (isEditOrDraft && deliveryDate) {
      updatedOrderDeliveryTime = getUpdatedOrderDeliveryTime(
        orderDeliveryTime,
        deliveryInstructions,
        deliveryDate,
        isRushOrder
      );
    }

    return {
      ...(isCopyOrder && {
        copiedFromOrderItemId:
          isDraftOrder && copiedFromOrderItemId
            ? copiedFromOrderItemId
            : isLocalOrder
            ? orderItemId
            : erosOrderNumber,
      }),
      lineItems: formatItems.updatedLineItems,
      price: formatItems.updatedLineItemAmounts,
      recipientInfo: {
        firstName: recipientInfo?.firstName,
        lastName: recipientInfo?.lastName,
        addressLine1: recipientInfo?.addressLine1,
        suite: recipientInfo?.addressLine2 || "",
        city: recipientInfo?.city,
        state: recipientInfo?.state,
        zip: recipientInfo?.zip,
        country: (isEditOrDraft && recipientInfo.country) || "US",
        locationType: recipientInfo?.locationType || "",
        locationName: recipientInfo?.locationName || "",
        phone: phoneNumberFormatter(recipientInfo?.phone) || "",
        deliveryMethod:
          ((isEditOrDraft || isGcProduct) && deliveryMethod) || "",
        hasRecipientInfo: true,
        ...(isGcProduct && { email: recipientInfo.email || "" }),
        county: recipientInfo?.county || "",
      },
      addressVerificationInfo: {
        isAvsPerformed: isEditOrDraft
          ? addressVerificationInfo?.isAvsPerformed
          : "N",
        avsConfidence: isEditOrDraft
          ? addressVerificationInfo?.avsConfidence
          : "",
        isAvsSuggestedAddress: isEditOrDraft
          ? addressVerificationInfo?.isAvsSuggestedAddress
          : "N",
      },
      deliveryInfo: {
        cardMessage: deliveryInfo?.cardMessage
          ? cardMessageFormatter(deliveryInfo.cardMessage)
          : "",
        orderSource,
        deliveryDate:
          isPastDate && !isEditOrder
            ? moment().utc().format("YYYY-MM-DD")
            : deliveryDate,
        pickUpDateTime: "",
        deliveryMethod:
          ((isEditOrDraft || isGcProduct) && deliveryMethod) || "",
        ...(updatedOrderDeliveryTime && {
          orderDeliveryTime: updatedOrderDeliveryTime,
        }),
        pickUpBy: "",
        deliveryInstructions: updatedDeliveryInstructions,
        occasion: deliveryInfo?.occasion,
        floristInfo: isEditOrder
          ? {
              name: receivingMember?.businessName,
              memberCode: receivingMember?.memberCode,
              phone: receivingMember?.phone,
            }
          : "",
        shopDayTimings: {},
        ...(deliveryMethod === "STORE_PICKUP" && {
          pickUpBy: deliveryInfo?.pickUpBy,
          pickUpDateTime: moment(pickupInfo?.storePickupDateTime),
        }),
        deliveryZoneId,
        operator: deliveryInfo?.operator || "",
        deliveredDate: deliveryInfo?.deliveredDate || "",
        deliveryTime: deliveryInfo?.deliveryTime || "",
        deliveryNotes: deliveryInfo?.deliveryNotes || "",
      },
      isRushOrder: isEditOrDraft ? isRushOrder : false,
      noAutoForward: isDraftOrder && autoForward === "N" ? true : false,
      defaultDeliveryFee,
      deliveryFee: defaultDeliveryFee,
      // deliveryFee: isEditOrder
      // ? formatPrice(
      //     feeSurchargeTaxAndTotalsInfo?.totalDeliveryFee - feeRefundedAmount
      //   )
      // : 0.0,
      retailDeliveryFee: feeSurchargeTaxAndTotalsInfo?.retailDeliveryFee,
      relayFee: isEditOrDraft
        ? feeSurchargeTaxAndTotalsInfo.relayFee
        : relayFee,
      serviceFee: isEditOrDraft ? feeSurchargeTaxAndTotalsInfo.serviceFee : 0.0,
      retransFee: isEditOrDraft ? feeSurchargeTaxAndTotalsInfo.retransFee : 0.0,
      receivingMember,
      orderSource,
      ...(isEditOrder && {
        oldValues: feeSurchargeTaxAndTotalsInfo,
      }),
      promoCode: promoCode,
      promoValue:
        promoCode && !promoValue
          ? `$${formatPrice(Math.max(0, totalDiscountAmount))}`
          : promoValue,
      ...(!isEditOrder &&
        isNonCoreUser && { applyTaxOnDF: applyTaxOnDF === "true" }),
      externalPartnerBusinessName,
      notes,
    };
  });

  const customerInfoExists =
    !isCoreUser && !isCoreConnectUser && !isEmpty(customerInfo);

  const selectedContact =
    customerInfoExists && !isEmpty(customerInfo?.customerContacts)
      ? customerInfo?.customerContacts?.find((contact) => contact.isSelected)
      : "";

  const selectedShopPermissions = get(permissions, shopCode, {});

  const isPaymentEntitlementEnabled = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION);

  let basicPaymentObj = cloneDeep(basicPaymentInfo);

  if (!isEditOrder) {
    set(
      basicPaymentObj,
      "paymentMethod.0.enablePayment",
      isPaymentEntitlementEnabled
    );
  }

  paymentDetails = {
    ...basicPaymentObj,
    ...paymentDetails,
  };

  return {
    customerInfo: {
      name: "",
      firstName: customerInfoExists ? customerInfo?.firstName || "" : "",
      lastName: customerInfoExists ? customerInfo?.lastName || "" : "",
      phone: customerInfoExists
        ? (!isEmpty(customerInfo?.phones) &&
            phoneNumberFormatter(customerInfo?.phones[0])) ||
          ""
        : "",
      email: customerInfoExists ? customerInfo?.email || "" : "",
      customerType: customerInfoExists
        ? customerInfo?.isBusinessProfile
          ? "Business"
          : "Individual"
        : "",
      businessName: customerInfoExists
        ? (customerInfo?.isBusinessProfile && customerInfo?.businessName) || ""
        : "",
      customerId: customerInfoExists ? customerInfo?.customerId || "" : "",
      initialCustomerId: customerInfoExists
        ? customerInfo?.customerId || ""
        : "",
      storeOrigin: customerInfoExists ? shopCode : "",
      totalAverageSpend: customerInfoExists
        ? customerInfo?.totalAverageSpend
        : 0,
      addresses: customerInfoExists ? customerInfo?.addresses : [],
      taxExemptCode: customerInfoExists
        ? customerInfo?.taxExemptCode || ""
        : "",
      // To pre-populate the selected contact (if any) while modifying/copying the order.
      ...(selectedContact
        ? {
            selectContact: JSON.stringify({
              ...selectedContact,
              isSelected: false,
              ...(!has(selectedContact, "contactId") &&
                isDraftOrder && { isPartial: true }),
            }),
          }
        : {}),
      ...(isDraftOrder &&
        customerInfoExists && {
          customerNotes: customerInfo?.customerNotes || "",
          smsProviderOptIn: customerInfo?.smsProviderOptIn || false,
          smsOptIn: customerInfo?.smsOptIn || false,
        }),
    },
    hasMultipleShops: shopsList.length > 1,
    hasCustomerInfo: customerInfoExists,
    sendingMember: shopCode,
    sendingMemberField: shopCode !== "all" ? `${shopCode} ${shopName}` : "",
    orderChannel,
    orderType: isEditOrDraft ? orderType : "",
    orderOrigin: "MHQ",
    isEditOrder,
    isCopyOrder,
    orderItems: processedOrderItems,
    paymentDetails,
    savePayment: false,
    createHouseAccount: "",
    nameOnCard: "",
    cardNumber: "",
    expDate: "",
    securityCode: "",
    billingZip: "",
    coupon: "",
    isBillingAndCutomerAdddressSame: "",
    refundAvailable,
    feeRefundedAmount,
    taxRefundedAmount,
    isSubscription: false,
  };
};

export const initialVals = ({
  memberCodes = [],
  shopCode,
  orderChannel,
  shopNames = [],
}) => {
  const shopsList = memberCodes.map((code) => {
    return { label: code, value: code };
  });
  const shopName = shopNames[shopCode] || "";
  const sendingMember =
    shopsList.length > 1
      ? shopCode !== "all"
        ? shopCode
        : ""
      : memberCodes[0];

  const isNonCoreUser = isMHQNonCoreMember(sendingMember);

  let applyTaxOnDF = false;

  if (isNonCoreUser) {
    const shopPreferences =
      UserProfileStorage.getShopPreferences(sendingMember);

    applyTaxOnDF = get(shopPreferences, "tax_delivery_fee", "false");
  }

  return {
    customerInfo: basicCustomerInfo,
    hasMultipleShops: shopsList.length > 1,
    sendingMember: sendingMember,
    sendingMemberField: shopCode !== "all" ? `${shopCode} ${shopName}` : "",
    orderChannel,
    orderType: "",
    orderOrigin: "MHQ",
    orderItems: [
      {
        ...basicOrderInfo,
        ...(isNonCoreUser && { applyTaxOnDF: applyTaxOnDF === "true" }),
      },
    ],
    paymentDetails: basicPaymentInfo,
    savePayment: false,
    createHouseAccount: "",
    nameOnCard: "",
    cardNumber: "",
    expDate: "",
    securityCode: "",
    billingZip: "",
    coupon: "",
    isBillingAndCutomerAdddressSame: "",
    isQuickSaleEnabled: false,
    isSubscription: false,
  };
};

export const orderOrSubscriptionInitialVals = ({
  memberCodes = [],
  shopCode,
  orderChannel,
  shopNames = [],
  permissions,
  customerDetails = {},
  subscriptionDetails = {},
  global,
  type,
}) => {
  const shopsList = memberCodes.map((code) => {
    return { label: code, value: code };
  });

  const shopName = shopNames[shopCode] || "";
  const isSubscription = type === "subscription";

  let customerInfo = { ...basicCustomerInfo };
  const selectedShopPermissions = get(permissions, shopCode, {});

  const isPaymentEntitlementEnabled = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION);

  if (!isEmpty(customerDetails)) {
    const hasPaymentInfo =
      Object.values(
        omit(customerDetails.paymentInfo, [
          "billingAddress",
          "sameAsMailingAddress",
        ])
      ).filter((val) => !!val).length > 0;

    const billingAddress = get(customerDetails, "address", {});
    let updatedBillingInfo = {};

    if (!isEmpty(billingAddress)) {
      updatedBillingInfo = getBillingInfo(billingAddress);
    }
    const customerContacts =
      (!isEmpty(subscriptionDetails) &&
        subscriptionDetails?.customerInfo?.customerContacts) ||
      [];

    const selectedContact = !isEmpty(customerContacts)
      ? customerContacts?.find((contact) => contact.isSelected)
      : "";

    // addresses are not coming as part of the customer details
    const hasAddresses =
      !isEmpty(subscriptionDetails) &&
      subscriptionDetails?.customerInfo?.addresses &&
      subscriptionDetails.customerInfo.addresses.length > 0;

    customerInfo = {
      ...customerDetails,
      customerType:
        customerDetails.customerType === "true" ? "Business" : "Individual",
      firstPaymentInfo: hasPaymentInfo ? customerDetails.paymentInfo || {} : {},
      billingInformation: updatedBillingInfo,
      houseAccountInfo: {
        orderTotal: customerDetails?.houseAccountInfo
          ? get(customerDetails, "houseAccountInfo.orderTotal", 0)
          : 0,
        ...customerDetails?.houseAccountInfo,
      },
      // To pre-populate the selected contact (if any) while modifying/copying the order.
      ...(selectedContact
        ? {
            selectContact: JSON.stringify({
              ...selectedContact,
            }),
            customerContacts,
            selectedContact: {
              ...selectedContact,
              phone: phoneNumberFormatter(selectedContact.phone) || "",
              isSelected: true,
            },
          }
        : {}),
      ...(hasAddresses
        ? { addresses: subscriptionDetails?.customerInfo?.addresses }
        : {}),
    };
  }

  const basicPaymentObj = cloneDeep(basicPaymentInfo);

  set(
    basicPaymentObj,
    "paymentMethod.0.enablePayment",
    isPaymentEntitlementEnabled
  );

  const initialObj = {
    actualCustomerInfo: customerInfo,
    customerInfo,
    hasCustomerInfo: true,
    hasMultipleShops: shopsList.length > 1,
    sendingMember:
      shopsList.length > 1
        ? shopCode !== "all"
          ? shopCode
          : ""
        : memberCodes[0],
    sendingMemberField: shopCode !== "all" ? `${shopCode} ${shopName}` : "",
    orderChannel,
    orderType: "Local",
    orderOrigin: "MHQ",
    savePayment: true,
    paymentDetails: basicPaymentObj,
    createHouseAccount: "",
    nameOnCard: "",
    cardNumber: "",
    expDate: "",
    securityCode: "",
    billingZip: "",
    coupon: "",
    isBillingAndCutomerAdddressSame: "",
    isSubscription,
    isDeliveryFeeOverridden: false,
  };

  let initialValues = {};

  if (isEmpty(subscriptionDetails)) {
    initialValues = {
      ...initialObj,
      isModify: false,
      orderItems: [
        {
          ...basicOrderInfo,
        },
      ],
      subscriptionInfo: isSubscription
        ? {
            firstDeliveryDate: "",
            frequency: "",
            ends: "NEVER",
            endDate: "",
            deliveryInstructions: "",
          }
        : undefined,
    };
  } else {
    const orderItems = get(subscriptionDetails, "orderTemplate.orderItems", [
      {
        ...basicOrderInfo,
      },
    ]);

    const sendingMember = get(orderItems, "0.sendingMember.memberCode", "");
    const subscriptionInfo = get(subscriptionDetails, "schedule.repeat.0");

    const updatedOrderItems = cloneDeep(orderItems);

    if (updatedOrderItems && updatedOrderItems.length) {
      updatedOrderItems.map((item, index) => {
        const {
          deliveryInfo: {
            cardMessage = "",
            deliveryDate,
            deliveryInstructions = "",
            orderDeliveryTime,
          },
          isRushOrder,
          deliveryFee,
        } = item;
        const formattedLineItems = formatLineItems(
          orderItems[index]?.lineItems,
          global,
          true,
          subscriptionDetails.isModify,
          false,
          sendingMember // reusing sending member code here as subscription orders are local orders i.e. sender & receiver are same
        );

        const { updatedLineItems = [], updatedLineItemAmounts = [] } =
          formattedLineItems;

        set(updatedOrderItems, `${index}.lineItems`, updatedLineItems);
        set(updatedOrderItems, `${index}.price`, updatedLineItemAmounts);

        if (cardMessage === "N/A") {
          set(item, "deliveryInfo.cardMessage", "");
        }

        if (!subscriptionDetails.isModify) {
          //setting deliveryDate as empty incase of copy
          set(item, "deliveryInfo.deliveryDate", "");
          set(item, "isRushOrder", false);

          item.price.map((priceVal, idx) => {
            set(
              priceVal,
              `discount`,
              customerDetails.discountPercentage
                ? formatPrice(customerDetails.discountPercentage)
                : 0
            );

            set(
              priceVal,
              `discountType`,
              customerDetails.discountPercentage ? "Percentage" : null
            );
          });
        }

        let updatedDeliveryInstructions = "";
        if (deliveryInstructions) {
          updatedDeliveryInstructions = deliveryInstructions?.replace(
            DELIVER_BY_REGEX,
            ""
          );
        }

        let updatedOrderDeliveryTime = "";

        if (deliveryDate) {
          updatedOrderDeliveryTime = getUpdatedOrderDeliveryTime(
            orderDeliveryTime,
            deliveryInstructions,
            deliveryDate,
            isRushOrder
          );
        }
        set(
          item,
          "deliveryInfo.orderDeliveryTime",
          !subscriptionDetails.isModify ? "" : updatedOrderDeliveryTime
        );

        set(
          item,
          "deliveryInfo.deliveryInstructions",
          updatedDeliveryInstructions
        );
        set(item, "deliveryFee", formatPrice(deliveryFee));
      });
    }

    const defaultDeliveryFee = formatPrice(get(orderItems, "0.deliveryFee", 0));

    initialValues = {
      ...initialObj,
      sendingMember,
      sendingMemberField: `${sendingMember} ${shopNames[sendingMember] || ""}`,
      isModify: subscriptionDetails.isModify,
      orderItems: updatedOrderItems,
      subscriptionInfo: {
        firstDeliveryDate: subscriptionDetails.isModify
          ? subscriptionInfo?.firstDeliveryDate
          : "",
        frequency: subscriptionInfo?.frequency,
        ends: subscriptionDetails.isModify
          ? subscriptionInfo?.ends ??
            (subscriptionInfo?.endDate ? "CUSTOM_DATE" : "")
          : "",
        endDate: subscriptionDetails.isModify
          ? subscriptionInfo?.endDate ?? ""
          : "",
        deliveryInstructions: subscriptionInfo?.deliveryInstructions ?? "",
      },
      defaultDeliveryFee,
      deliveryFee: defaultDeliveryFee,
    };
  }

  return initialValues;
};

const isWireOut = (value) => value === "FLORIST_PARTNER";
const isFloristDelivered = (value) => value === "FLORIST_DELIVERED";
const isTransfer = (value) => value === "transfer";
const isStorePickup = (value) => value === "STORE_PICKUP";
const isWalkIn = (value) => value === "WALK_IN";
const isPhoneOut = (value) => value === "PHONE_OUT";
const isPhysicalGiftCard = (value) => physicalGiftCardProducts.includes(value);
const isGcRedeemed = (data, giftCard) => {
  const redeemedGiftCards =
    (data?.length > 0 &&
      data.find((ele) => ele.giftCodeId === giftCard)?.giftCodeId) ||
    "";
  return redeemedGiftCards?.length > 0;
};

// const isDigitalInvoice = (value) => value === "DigitalInvoice";
// const isMainInvoice = (value) => value === "MailInvoice";
// const isPaypal = (value) => value === "Paypal";
